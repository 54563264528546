<template>
  <div>
    <!--<header-page />-->
    <v-container grid-list-md text-xs-center px-0>
      <v-layout row wrap>
        <v-flex xs2></v-flex>
        <v-flex xs8>
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>Configurações</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-container grid-list-md px-0 py-0>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field v-model="name" label="Nome" required></v-text-field>
                  <v-text-field v-model="email" :rules="emailRules" label="Email" required></v-text-field>
                  <v-text-field v-model="password" label="Senha*" type="password" required></v-text-field>
                </v-form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" dark class="mb-2" :disabled="!valid" @click="submit">GRAVAR</v-btn>
                </v-card-actions>
              </v-container>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      valid: true,
      user_id: USER.id,
      name: USER.name,
      email: USER.email,
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ],
      password: USER.password
    };
  },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        // Native form submission is not yet supported
        axios
          .put(`/users/${this.user_id}`, {
            name: this.name,
            email: this.email,
            password: this.password
          })
          .then(resp => {
            this.$notify({
              title: `Salvo com sucesso!`,
              type: "success"
            });
          })
          .catch(err => {
            this.$reqError(`Erro ao salvar Plantão!`, err, this);
          });
      }
    },
    clear() {
      this.$refs.form.reset();
    }
  }
};
</script>
