<template>
<div>
  <!--<header-page />-->
	<v-container grid-list-md text-xs-center px-0>
		<notifications position="bottom center" />
		<v-layout row wrap>
			<v-flex xs12>

				<v-toolbar flat color="white" class="elevation-1">
					<v-toolbar-title>Requerimentos</v-toolbar-title>
					<v-divider class="mx-2" inset vertical></v-divider>
					<v-spacer></v-spacer>
					<v-flex xs3>
						<v-text-field
							v-model.lazy="search"
							append-icon="fa-search"
							label="Buscar"
							single-line
							hide-details
							class="hidden-sm-and-down"
              @click:append="getList()"
              v-on:keyup.enter="getList"
						></v-text-field>
					</v-flex>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="requirementFormOpen({})">Novo</v-btn>
				</v-toolbar>

				<v-data-table
					:headers="columns"
					:items="items"
      		:pagination.sync="pagination"
					:total-items="pagination.totalItems"
      		:loading="loading"
					hide-actions
					class="elevation-1"
				>
					<template slot="items" slot-scope="props">
						<td>{{ props.item.id }}</td>
						<td>{{ props.item.title }}</td>
						<td>{{ props.item.description }}</td>
						<td>{{ props.item.status }}</td>
						<td>
							<span v-for="(item, i) in props.item.files" :key="i">
								<a :href="item.url" target="_blank">Arquivo {{i + 1}}</a>;
							</span>
						</td>
						<td>{{ props.item.date | formatDate}}</td>
					</template>

					<template slot="no-data">
						Desculpe, nenhum dado para ser exibido!
					</template>
				</v-data-table>
				<div class="text-xs-center pt-2">
					<v-pagination v-model="pagination.page" :length="pages"></v-pagination>
				</div>

			</v-flex>
		</v-layout>
  </v-container>

  <div v-if="requirementFormActive.open">
		<requirement-form
			:active="requirementFormActive.open"
			:item="requirementFormActive.item"
			:finish="requirementFormClose"
		></requirement-form>
	</div>
</div>
</template>

<script>
import _ from 'lodash';
export default {

  data() {
		return {
			dialog: false,
			search: '',
      pagination: {
				totalItems: 0,
				rowsPerPage: 10,
  			descending: true,
  			page: 1,
  			sortBy: 'id'
			},
			items: [],
			columns: [
				{ value: 'id', text: 'ID' },
				{ value: 'title', text: 'Título', },
				{ value: 'description', text: 'Descrição', },
				{ value: 'status', text: 'Status' },
				{ value: 'files', text: 'Arquivos', sortable: false },
				{ value: 'date', text: 'Data' }
      ],
      requirementFormActive: { open: false, item: {} },
			loading: false
		}
	},
	computed: {
		pages () {
			if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) return 0

			return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
		}
	},
	watch: {
		dialog (val) {
			val || this.close()
		},
		"pagination.page": {
			handler () {
				this.getList();
			},
			deep: true
		},
	},
	created () {
		this.initialize();
	},
	methods: {
		initialize () {
		},
		getList() {
			this.loading = true
			return new Promise((resolve, reject) => {
				const params = {
					...this.pagination,
					totalItems: undefined,
					search: this.search
				};

				const axiosCrossDomain = this.$http;
				delete axiosCrossDomain.defaults.headers.common["X-CSRF-TOKEN"];
				const options = {
					params,
					auth: {username: USER.unit.username, password: USER.unit.username}
				};

				axiosCrossDomain.get(`${IDGTADMAPI}/requirements`, options)
				.then(response => response.data).then(json => {
					setTimeout(() => {
            this.loading = false;

						this.items = json.items;
						this.pagination.totalItems = json.total;
          }, 1000);
				}).catch(() => this.loading = false);
			});
    },
    requirementFormOpen (item) {
			this.requirementFormActive.open = true;
			this.requirementFormActive.item = item;
		},
		requirementFormClose () {
			this.requirementFormActive.open = false;
			this.getList();
		},
		debounceInput: _.debounce(function () {
      setTimeout(() => {this.getList();}, 200);
		}, 400)
	}
}
</script>
