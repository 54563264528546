<template>
  <div>
    <v-container grid-list-md text-xs-center px-0>
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Visitas - Relatório</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
              class="hidden-md-and-down"
              @click:append="getList()"
              v-on:keyup.enter="getList"
            ></v-text-field>
            <v-spacer></v-spacer>
            <div>
              {{formatDate(filtro.start)}} - {{formatDate(filtro.end)}}
              <v-icon class="mr-2" @click="filtro.modal = true">edit</v-icon>
            </div>
            <v-btn color="primary" flat @click="goReport()">IMPRIMIR</v-btn>
          </v-toolbar>
        </v-flex>

        <v-flex xs12 v-for="(tb, i) in items" :key="i">
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>{{tb[0].created_at | formatDate}}</v-toolbar-title>
            <v-spacer></v-spacer>
            Total: {{tb.length}}
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="tb"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.created_at | formatDateTimeShort }}</td>
                <td>{{ props.item.patient && props.item.patient.name}}</td>
                <td>{{ props.item.patient && props.item.patient.rh}}</td>
                <td>{{ props.item.name}}</td>
                <td>{{ props.item.rg}}</td>
                <td>{{ props.item.clinic && props.item.clinic.name }}</td>
                <td>{{ props.item.bed && props.item.bed.code }}</td>
                <td>{{ props.item.user && props.item.user.name }}</td>
              </tr>
            </template>

            <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
          </v-data-table>
        </v-flex>
      </v-layout>

      <v-dialog v-model="filtro.modal" scrollable max-width="350px">
        <v-card>
          <v-card-title>
            <span class="headline">Período</span>
          </v-card-title>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12>
                <v-menu
                  ref="menuStart"
                  :close-on-content-click="false"
                  v-model="filtro.menuStart"
                  :nudge-right="40"
                  :return-value.sync="filtro.start"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="computedStart"
                    label="Data inicial"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker v-model="filtro.start" no-title scrollable locale="pt-br">
                    <v-spacer></v-spacer>
                    <v-btn flat color="grey" @click="filtro.menuStart = false">Fechar</v-btn>
                    <v-btn color="primary" @click="$refs.menuStart.save(filtro.start)">OK</v-btn>
                  </v-date-picker>
                </v-menu>

                <v-menu
                  ref="menuEnd"
                  :close-on-content-click="false"
                  v-model="filtro.menuEnd"
                  :nudge-right="40"
                  :return-value.sync="filtro.end"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="computedEnd"
                    label="Data Final"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker v-model="filtro.end" no-title scrollable locale="pt-br">
                    <v-spacer></v-spacer>
                    <v-btn flat color="grey" @click="filtro.menuEnd = false">Fechar</v-btn>
                    <v-btn color="primary" @click="$refs.menuEnd.save(filtro.end)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" flat @click="filtro.modal = false">Fechar</v-btn>
            <v-btn color="primary" @click="filtro.modal = false; getList()">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
export default {
  data() {
    return {
      valid: false,
      search: "",
      items: [],
      filtro: {
        start: moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment()
          .endOf("month")
          .format("YYYY-MM-DD"),
        modal: false
      },
      columns: [
       { value: "created_at", text: "Data e Hora", sortable: false },
        { value: "patient.name", text: "Paciente", sortable: false },
        { value: "patient.rh", text: "RH", sortable: false },
        { value: "name", text: "Visitante", sortable: false },
        { value: "rg", text: "Vis. RG", sortable: false },
        { value: "clinic", text: "Clinica", sortable: false },
        { value: "bed", text: "Leito", sortable: false },
        { value: "user", text: "Operador", sortable: false },
      ],
      menu1: false,
      loading: false
    };
  },
  computed: {
    computedStart() {
      return this.formatDate(this.filtro.start);
    },
    computedEnd() {
      return this.formatDate(this.filtro.end);
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getList();
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          search: this.search,
          ...this.filtro,
          modal: undefined
        };
        this.$http
          .get(`/visitations/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = _.toArray(
                _.groupBy(json.items, obj => this.formatDate(obj.created_at))
              );
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    formatDate: date => (date ? moment(date).format("DD/MM/YYYY") : ""),
    goReport() {
      this.filtro.menu = false;
      let url = `/visitations/reports/?start=${this.filtro.start}&end=${this.filtro.end}`;
      window.open(url, "_blank");
    },
  }
};
</script>
