<template>
  <div>
    <!--<header-page />-->
    <v-container grid-list-md text-xs-center px-0>
      <notifications position="bottom center" />
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Setores</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
              class="hidden-md-and-down"
              @click:append="getList()"
              v-on:keyup.enter="getList"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" v-if="$route.params.office === 1" dark class="mb-2" @click="openSetoreForm({office: $route.params.office})">Novo</v-btn>
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.name }}</td>

                <td class="text-xs-center px-0">
                  <router-link :to="`/sectors_products/${props.item.id}`">
                    <v-btn icon small>
                      <v-icon small>fas fa-capsules</v-icon>
                    </v-btn>
                  </router-link>
                  <v-btn icon small @click="openSetoreForm(props.item)">
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <!--<v-icon small @click="del(props.item.id)">
									delete
                  </v-icon>-->
                </td>
              </tr>
            </template>

            <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
          </div>
        </v-flex>
      </v-layout>

      <div v-if="setoreFormActive.open">
        <setore-form
          :active="setoreFormActive.open"
          :item="setoreFormActive.item"
          :finish="onCloseSetoreForm"
        ></setore-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      valid: false,
      search: "",
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "id"
      },
      items: [],
      columns: [
        { value: "id", text: "ID" },
        { value: "name", text: "Nome" },
        { text: "", value: "", sortable: false }
      ],
      setoreFormActive: {
        open: false,
        item: {}
      },
      setoreViewActive: { open: false },
      loading: false
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          search: this.search,
          office: this.$route.params.office
        };
        this.$http
          .get(`/sectors/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    del(id) {
      if (confirm("Deseja realmente excluir este setor?")) {
        this.$http.delete(`/sectors/${id}`).then(response => {
          this.$notify({
            title: `Setor Excluido!`,
            type: "success"
          });
          this.getList();
        });
      }
    },
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),

    openSetoreForm(item) {
      this.setoreFormActive.open = true;
      this.setoreFormActive.item = item;
    },
    onCloseSetoreForm() {
      this.setoreFormActive.open = false;
      this.getList();
    }
  }
};
</script>
