<template>
  <v-container grid-list-md px-0>
    <v-card>
      <v-card-title>
        <span class="headline">GERAR RELATÓRIOS DE FECHAMENTO MENSAL</span>
      </v-card-title>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 sm4>
              <v-menu
                ref="menuStart"
                :close-on-content-click="false"
                v-model="range.menuStart"
                :nudge-right="40"
                :return-value.sync="range.start"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="computedStart"
                  label="Data"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker
                  v-model="range.start"
                  no-title
                  scrollable
                  locale="pt-br"
                  type="month"
                >
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="range.menuStart = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    flat
                    color="primary"
                    @click="$refs.menuStart.save(range.start)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout row wrap mt-5>
            <v-flex xs12>
              <v-btn outline color="blue darken-4" @click="goReport('/retiradas/position_monthly')"
                >Posição de Estoque</v-btn
              >
            </v-flex>
            <v-flex xs12>
              <v-btn outline color="green darken-4" @click="goReport('/retiradas/consumption')"
                >Consumo de Estoque</v-btn
              >
            </v-flex>
            <v-flex xs12>
              <v-btn outline color="black darken-4" @click="goReport('/purchases/input_report')"
                >Entrada de Estoque</v-btn
              >
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
    <dialog-loader :active="loading" />
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      range: {
        start: moment().startOf("month").format("YYYY-MM-DD"),
        end: moment().endOf("month").format("YYYY-MM-DD"),
      },
      editedItem: {},
      loading: false,
    };
  },
  computed: {
    computedStart() {
      return this.formatDate(this.range.start);
    },
    computedEnd() {
      return this.formatDate(this.range.end);
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    formatDate(date) {
      if (!date) return null;
      console.log(date);

      const [year, month, day] = date.split("-");
      return `${month}/${year}`;
    },
    goReport(action) {
      if (!this.range.start) {
        this.$notify({
          title: `Escolha a data!`,
          type: "error",
        });
        return;
      }

      this.range.start = moment(this.range.start)
        .startOf("month")
        .format("YYYY-MM-DD");
      this.range.end = moment(this.range.start)
        .endOf("month")
        .format("YYYY-MM-DD");

      let url = `${action}/${this.$route.params.office}?start=${this.range.start}`;
      window.open(url, "_blank");
    },
  },
};
</script>
