<template>
  <v-container grid-list-md text-xs-center px-0>
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar flat color="white" class="elevation-1">
          <v-toolbar-title>Cotações</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model.lazy="search"
            append-icon="fa-search"
            label="Buscar"
            single-line
            hide-details
            class="hidden-sm-and-down"
            @click:append="getList()"
              v-on:keyup.enter="getList"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            @click="quotationFormOpen({office: $route.params.office})"
            color="primary"
            dark
            class="mb-2"
          >Novo</v-btn>
        </v-toolbar>

        <v-data-table
          :headers="columns"
          :items="items"
          :pagination.sync="pagination"
          :total-items="pagination.totalItems"
          :loading="loading"
          hide-actions
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.created_at | formatDate }}</td>
            <td>{{ props.item.user && props.item.user.name }}</td>
            <td>{{ props.item.quotation_products_count > 0 ? props.item.quotation_products_count : '-' }}</td>
            <td>{{ props.item.due_date | formatDate }}</td>
            <td>{{ getCat(props.item.bionexo_cat) }}</td>
            <td class="text-xs-center px-0">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon small>fas fa-ellipsis-v</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-tile
                    @click="goExternal(`/quotations/${props.item.id}/print`)"
                  >
                    <v-list-tile-avatar>
                      <v-icon>fa-print</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>Imprimir</v-list-tile-title>
                  </v-list-tile>

                  <!-- <v-list-tile v-if="!props.item.active" @click="quotationFormOpen(props.item)">
                    <v-list-tile-avatar>
                      <v-icon>fa-edit</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>Editar</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile v-if="!props.item.active" small icon @click="del(props.item.id)">
                    <v-list-tile-avatar>
                      <v-icon>delete</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>Excluir</v-list-tile-title>
                  </v-list-tile>-->
                </v-list>
              </v-menu>
            </td>
          </template>

          <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
        </v-data-table>
        <div class="text-xs-center pt-2">
          <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
        </div>
      </v-flex>
    </v-layout>
    <div v-if="quotationFormActive.open">
      <quotation-form
        :active="quotationFormActive.open"
        :item="quotationFormActive.item"
        :finish="quotationFormClose"
      />
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import { BIONEXO_CATEGORIES } from '../../constants';
export default {
  data() {
    return {
      valid: false,
      dialog: false,
      search: "",
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: true,
        page: 1,
        sortBy: "id",
      },
      items: [],
      columns: [
        { value: "id", text: "ID" },
        { value: "created_at", text: "Adicionado em" },
        { value: "user.name", text: "Adicionado por", sortable: false },
        { value: "quotation_products_count", text: "QTD Produtos", sortable: false },
        { value: "due_date", text: "Vencimento" },
        { value: "bionexo_cat", text: "Categoria", sortable: false },
        { text: "", value: "", sortable: false },
      ],
      quotationFormActive: { open: false, item: {} },
      loading: false,
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          search: this.search,
          office: this.$route.params.office,
        };
        this.$http
          .get(`/quotations/list`, { params })
          .then((response) => response.data)
          .then((json) => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    getCat(id){
      const c = BIONEXO_CATEGORIES.find(o => o.value == id);
      return c ? c.text : '';
    },
    goExternal(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    quotationFormOpen(item) {
      this.quotationFormActive.open = true;
      this.quotationFormActive.item = item;
    },
    quotationFormClose() {
      this.quotationFormActive.open = false;
      this.getList();
    },
    del(id) {
      if (confirm("Deseja realmente excluir esta Cotação?")) {
        this.$http.delete(`/quotations/${id}`).then((response) => {
          this.$notify({ title: `Cotação Excluido!`, type: "success" });
          this.getList();
        });
      }
    },
    debounceInput: _.debounce(function () {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
  },
};
</script>
