export const TIMES = [
	'07:00', '07:05', '07:10', '07:15', '07:20', '07:25', '07:30', '07:35', '07:40', '07:45', '07:50', '07:55',
	'08:00', '08:05', '08:10', '08:15', '08:20', '08:25', '08:30', '08:35', '08:40', '08:45', '08:50', '08:55',
	'09:00', '09:05', '09:10', '09:15', '09:20', '09:25', '09:30', '09:35', '09:40', '09:45', '09:50', '09:55',
	'10:00', '10:05', '10:10', '10:15', '10:20', '10:25', '10:30', '10:35', '10:40', '10:45', '10:50', '10:55',
	'11:00', '11:05', '11:10', '11:15', '11:20', '11:25', '11:30', '11:35', '11:40', '11:45', '11:50', '11:55',
	'12:00', '12:05', '12:10', '12:15', '12:20', '12:25', '12:30', '12:35', '12:40', '12:45', '12:50', '12:55',
	'13:00', '13:05', '13:10', '13:15', '13:20', '13:25', '13:30', '13:35', '13:40', '13:45', '13:50', '13:55',
	'14:00', '14:05', '14:10', '14:15', '14:20', '14:25', '14:30', '14:35', '14:40', '14:45', '14:50', '14:55',
	'15:00', '15:05', '15:10', '15:15', '15:20', '15:25', '15:30', '15:35', '15:40', '15:45', '15:50', '15:55',
	'16:00', '16:05', '16:10', '16:15', '16:20', '16:25', '16:30', '16:35', '16:40', '16:45', '16:50', '16:55',
	'17:00', '17:05', '17:10', '17:15', '17:20', '17:25', '17:30', '17:35', '17:40', '17:45', '17:50', '17:55',
	'18:00', '18:05', '18:10', '18:15', '18:20', '18:25', '18:30', '18:35', '18:40', '18:45', '18:50', '18:55'
];

/*export const TIMES_RETURN = [
	'07:00', '07:15', '07:30', '07:45',
	'08:00', '08:15', '08:30', '08:45',
	'09:00', '09:15', '09:30', '09:45',
	'10:00', '10:15', '10:30', '10:45',
	'11:00', '11:15', '11:30', '11:45',
	'12:00', '12:15', '12:30', '12:45',
	'13:00', '13:15', '13:30', '13:45',
	'14:00', '14:15', '14:30', '14:45',
	'15:00', '15:15', '15:30', '15:45',
	'16:00', '16:15', '16:30', '16:45',
	'17:00', '17:15', '17:30', '17:45',
  '18:00', '18:15', '18:30', '18:45'
];*/

export const TIMES_RETURN = [
	'07:00', '07:05', '07:10', '07:15', '07:20', '07:25', '07:30', '07:35', '07:40', '07:45', '07:50', '07:55',
	'08:00', '08:05', '08:10', '08:15', '08:20', '08:25', '08:30', '08:35', '08:40', '08:45', '08:50', '08:55',
	'09:00', '09:05', '09:10', '09:15', '09:20', '09:25', '09:30', '09:35', '09:40', '09:45', '09:50', '09:55',
	'10:00', '10:05', '10:10', '10:15', '10:20', '10:25', '10:30', '10:35', '10:40', '10:45', '10:50', '10:55',
	'11:00', '11:05', '11:10', '11:15', '11:20', '11:25', '11:30', '11:35', '11:40', '11:45', '11:50', '11:55',
	'12:00', '12:05', '12:10', '12:15', '12:20', '12:25', '12:30', '12:35', '12:40', '12:45', '12:50', '12:55',
	'13:00', '13:05', '13:10', '13:15', '13:20', '13:25', '13:30', '13:35', '13:40', '13:45', '13:50', '13:55',
	'14:00', '14:05', '14:10', '14:15', '14:20', '14:25', '14:30', '14:35', '14:40', '14:45', '14:50', '14:55',
	'15:00', '15:05', '15:10', '15:15', '15:20', '15:25', '15:30', '15:35', '15:40', '15:45', '15:50', '15:55',
	'16:00', '16:05', '16:10', '16:15', '16:20', '16:25', '16:30', '16:35', '16:40', '16:45', '16:50', '16:55',
	'17:00', '17:05', '17:10', '17:15', '17:20', '17:25', '17:30', '17:35', '17:40', '17:45', '17:50', '17:55',
	'18:00', '18:05', '18:10', '18:15', '18:20', '18:25', '18:30', '18:35', '18:40', '18:45', '18:50', '18:55'
];

export const BIONEXO_CATEGORIES = [
	{"value":100, "text": "Medicamentos"},
	{"value":200, "text": "Materiais Médicos"},
	{"value":300, "text": "Equipamentos Médicos e Cirúrgicos"},
	{"value":400, "text": "Materiais de Escritório e Informática"},
	{"value":600, "text": "Gêneros Alimentícios"},
	{"value":500, "text": "Materiais de Limpeza / Descartáveis"},
	{"value":700, "text": "Insumos de Laboratório"},
	{"value":800, "text": "Formulários / Gráficos"},
	{"value":900, "text": "Manutenção Predial / Ornamentação"},
	{"value":1000, "text": "Rouparia / Tecidos"},
	{"value":1100, "text": "Materiais Odontológicos"},
	{"value":1200, "text": "Itens Fora de Estoque"},
	{"value":1400, "text": "Instrumental Médico Cirúrgico"},
	{"value":1500, "text": "Uniforme e Epis"},
	{"value":1600, "text": "Acessórios de Equipamento Médico"},
	{"value":1700, "text": "Combustível"},
	{"value":1800, "text": "Brindes"},
	{"value":1900, "text": "Gases Medicinais"},
	{"value":2000, "text": "Imobilizado/Ativo Fixo"},
	{"value":2100, "text": "Materiais Educacionais/Treinamento"},
	{"value":1300, "text": "Serviços"}
];

export const BIONEXO_PAYMENTS = [
  { value: 1644, text: "60 DIAS DA EMISSÃO DA NF C/ 4% DE DESCONTO" },
  { value: 1664, text: "35/49/63/84 DDL" },
  { value: 1665, text: "60 dias c/ 4% de Desconto Financeiro" },
  { value: 1685, text: "Ato/60 DDl" },
  { value: 1686, text: "Antecipado/30 DDL" },
  { value: 1693, text: "35/56/77" },
  { value: 1694, text: "42/49/56/63" },
  { value: 1695, text: "49/56/63" },
  { value: 1696, text: "49/56/63/70" },
  { value: 1697, text: "49/63" },
  { value: 1698, text: "56/77/91" },
  { value: 1725, text: "30/50/70/90/120 DLL" },
  { value: 1726, text: "30/45/70/90/105/120 DLL" },
];
