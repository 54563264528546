import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vuetify from "vuetify";
import axios from "axios";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/es5/locale/pt";
import Notifications from "vue-notification";
import "@fortawesome/fontawesome-free/css/all.css";
import VueChartkick from "vue-chartkick";
import Chart from "chart.js";
//import money from 'v-money';
//import VueWebcam from 'vue-webcam';
//import VueFrame from 'vue-frame'
//import bugsnag from '@bugsnag/js';
//import bugsnagVue from '@bugsnag/plugin-vue';

// FILTROS
import {
  FormatDate,
  FormatMoney,
  FormatDateTime,
  FormatDateTimeShort,
  FormatDateLong,
  FormatMonth,
  FormatPhone
} from "./filters";

axios.defaults.headers.common = {
  Accept: "application/json",
  "X-Requested-With": "XMLHttpRequest",
  "X-CSRF-TOKEN": document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content")
};
window.axios = axios;
Vue.prototype.$http = axios;

//console.log('USER', USER);
let theme = {
  primary: "#009688",
  secondary: "#004D40",
  accent: "#00BFA5",
  error: "#f44336",
  warning: "#ffeb3b",
  info: "#2196f3",
  success: "#4caf50"
};

Vue.use(VueChartkick, { adapter: Chart });
Vue.use(Vuetify, {
  theme,
  lang: {
    locales: { pt },
    current: "pt"
  },
  iconfont: "fa"
});
Vue.use(Notifications);
// Vue.use(VueWebcam);
// Vue.use(VueFrame);

//const bugsnagClient = bugsnag('fd10ef933520671163c4a52a6d9ed97d');
//bugsnagClient.use(bugsnagVue, Vue);
//bugsnagClient.user = USER;
const reqError = (title, err, INST) => {
  /*bugsnagClient.notify(err, {
    beforeSend: report => {
      report.metaData = {
        req_data: JSON.parse(err.response.config.data),
        res_data: err.response.data
      }
    }
  });*/
  let status = "";
  if (err.response) {
    status = `${err.response.status} ${err.response.statusText}`;
  }

  let msg = `Ocorreu um erro interno, favor entra em contato com a equipe de Suporte. ${status}`;
  if (err.response && err.response.data && err.response.data.message)
    msg = err.response.data.message;

  INST.$notify({
    duration: 15000,
    title: title,
    text: msg,
    type: "error"
  });

  INST.loading = false;
  INST.btnLoading = false;
};

Vue.prototype.$reqError = reqError;

Vue.component("header-page", () => import("./HeaderPageComponent.vue"));
Vue.component("select-custom", () =>
  import("./components/SelectCustomComponent.vue")
);
Vue.component("datetime-input", () =>
  import("./components/DateTimeInputComponent.vue")
);

Vue.component("dialog-loader", () =>
  import("./components/DialogLoaderComponent.vue")
);
Vue.component("call", () => import("./components/call/CallComponent.vue"));
Vue.component("call-print", () =>
  import("./components/call/CallPrintComponent.vue")
);
Vue.component("declare-print", () =>
  import("./components/call/DeclarePrintComponent.vue")
);
Vue.component("bo-print", () =>
  import("./components/call/BoPrintComponent.vue")
);
Vue.component("patient-form", () =>
  import("./components/PatientFormComponent.vue")
);
Vue.component("resp-form", () => import("./components/RespFormComponent.vue"));
Vue.component("patient-add-form", () =>
  import("./components/PatientAddFormComponent.vue")
);
Vue.component("patient-by-name", () =>
  import("./components/PatientByNameComponent.vue")
);
Vue.component("patient-view", () =>
  import("./components/PatientViewComponent.vue")
);
Vue.component("patient-union", () =>
  import("./components/PatientUnionComponent.vue")
);
Vue.component("input-upload", () =>
  import("./components/InputUploadComponent.vue")
);
Vue.component("input-upload-multi", () =>
  import("./components/InputUploadMultiComponent.vue")
);
Vue.component("evaluation", () =>
  import("./components/call/EvaluationComponent.vue")
);
Vue.component("prescription", () =>
  import("./components/call/PrescriptionComponent.vue")
);
Vue.component("change-unit", () =>
  import("./components/ChangeUnitComponent.vue")
);
Vue.component("hospitalization", () =>
  import("./components/HospitalizationComponent.vue")
);
Vue.component("hospitalization-details", () =>
  import("./components/HospitalizationDetailsComponent.vue")
);
Vue.component("hospitalization-done", () =>
  import("./components/HospitalizationDoneComponent.vue")
);
Vue.component("photo-webcam", () =>
  import("./components/PhotoWebcamComponent.vue")
);
Vue.component("operator-form", () =>
  import("./components/OperatorFormComponent.vue")
);
Vue.component("doctor-form", () =>
  import("./components/DoctorFormComponent.vue")
);
Vue.component("category-form", () =>
  import("./components/pharmacy/CategoryFormComponent.vue")
);
Vue.component("subcategory-form", () =>
  import("./components/pharmacy/SubcategoryFormComponent.vue")
);
Vue.component("destiny-form", () =>
  import("./components/pharmacy/DestinyFormComponent.vue")
);
Vue.component("loan-form", () =>
  import("./components/pharmacy/LoanFormComponent.vue")
);
Vue.component("purchase-form", () =>
  import("./components/pharmacy/PurchaseFormComponent.vue")
);
Vue.component("purchase-view", () =>
  import("./components/pharmacy/PurchaseViewComponent.vue")
);
Vue.component("purchase-checkup", () =>
  import("./components/pharmacy/PurchaseCheckupComponent.vue")
);
Vue.component("purchase-invoice", () =>
  import("./components/pharmacy/PurchaseInvoiceComponent.vue")
);
Vue.component("setore-form", () =>
  import("./components/pharmacy/SetoreFormComponent.vue")
);
Vue.component("setore-edit-products", () =>
  import("./components/pharmacy/SetoreEditProductsComponent.vue")
);
Vue.component("retirada-form", () =>
  import("./components/pharmacy/RetiradaFormComponent.vue")
);
Vue.component("retirada-view", () =>
  import("./components/pharmacy/RetiradaViewComponent.vue")
);
Vue.component("product-form", () =>
  import("./components/pharmacy/ProductFormComponent.vue")
);
Vue.component("product-view", () =>
  import("./components/pharmacy/ProductViewComponent.vue")
);
Vue.component("transfer-form", () =>
  import("./components/pharmacy/TransferFormComponent.vue")
);
Vue.component("transfer-view", () =>
  import("./components/pharmacy/TransferViewComponent.vue")
);
Vue.component("quotation-form", () =>
  import("./components/pharmacy/QuotationFormComponent.vue")
);
Vue.component("edit-due-products", () =>
  import("./components/pharmacy/EditVencimentoProductComponent.vue")
);

Vue.component("departments-form", () =>
  import("./components/DepartmentsFormComponent.vue")
);
Vue.component("support-form", () =>
  import("./components/SupportFormComponent.vue")
);
Vue.component("requirement-form", () =>
  import("./components/RequirementFormComponent.vue")
);
Vue.component("password-form", () =>
  import("./components/PasswordFormComponent.vue")
);
Vue.component("password-change", () =>
  import("./components/PasswordChangeComponent.vue")
);
Vue.component("doctors-absent", () =>
  import("./components/call/DoctorsAbsentComponent.vue")
);
Vue.component("doctor-change-date", () =>
  import("./components/DoctorChangeDateComponent.vue")
);
Vue.component("doctor-agenda", () =>
  import("./components/DoctorAgendaComponent.vue")
);

Vue.component("bank-form", () =>
  import("./components/financial/BankFormComponent.vue")
);
Vue.component("bill-type-form", () =>
  import("./components/financial/BillTypeFormComponent.vue")
);
Vue.component("bill-form", () =>
  import("./components/financial/BillFormComponent.vue")
);
Vue.component("provider-form", () =>
  import("./components/financial/ProviderFormComponent.vue")
);
Vue.component("list-bill-by-type", () =>
  import("./components/financial/ListBillByTypeComponent.vue")
);
Vue.component("contract-form", () =>
  import("./components/financial/ContractFormComponent.vue")
);

Vue.component("service-form", () =>
  import("./components/ServiceFormComponent.vue")
);
Vue.component("consultation-form", () =>
  import("./components/ConsultationFormComponent.vue")
);
Vue.component("consultation-print", () =>
  import("./components/call/ConsultationPrintComponent.vue")
);
Vue.component("consultation-history", () =>
  import("./components/ConsultationHistoryComponent.vue")
);
Vue.component("consultation-confirm", () =>
  import("./components/ConsultationConfirmComponent.vue")
);
Vue.component("consultationview-confirm", () =>
  import("./components/ConsultationConfirmViewComponent.vue")
);
Vue.component("partner-form", () =>
  import("./components/pharmacy/PartnerFormComponent.vue")
);
Vue.component("medical-procedures-form", () =>
  import("./components/MedicalProceduresFormComponent.vue")
);
Vue.component("input-single-product", () =>
  import("./components/pharmacy/InputSingleProductComponent.vue")
);
Vue.component("sector-product-config", () =>
  import("./components/pharmacy/SectorProductConfigComponent.vue")
);
Vue.component("transfer-pack-form", () =>
  import("./components/pharmacy/TransferPackFormComponent.vue")
);
Vue.component("transfer-pack-temp", () =>
  import("./components/pharmacy/TransferPackTempComponent.vue")
);
Vue.component("transfer-pack-done", () =>
  import("./components/pharmacy/TransferPackDoneComponent.vue")
);
Vue.component("devolution-pack-form", () =>
  import("./components/pharmacy/DevolutionPackFormComponent.vue")
);
Vue.component("devolution-view", () =>
  import("./components/pharmacy/DevolutionViewComponent.vue")
);
Vue.component("exam-type-form", () =>
  import("./components/ExamTypeFormComponent.vue")
);
Vue.component("exam-form", () => import("./components/ExamFormComponent.vue"));
Vue.component("exam-agenda", () =>
  import("./components/ExamAgendaComponent.vue")
);
Vue.component("exam-print", () =>
  import("./components/call/ExamPrintComponent.vue")
);
Vue.component("radiology-form", () =>
  import("./components/RadiologyFormComponent.vue")
);
Vue.component("surgery-print", () =>
  import("./components/call/SurgeryPrintComponent.vue")
);
Vue.component("surgery-type-form", () =>
  import("./components/SurgeryTypeFormComponent.vue")
);
Vue.component("surgery-form", () =>
  import("./components/SurgeryFormComponent.vue")
);
Vue.component("surgery-agenda", () =>
  import("./components/SurgeryAgendaComponent.vue")
);
Vue.component("work-shift-form", () =>
  import("./components/WorkShiftFormComponent.vue")
);
Vue.component("work-shift-doctors", () =>
  import("./components/WorkShiftDoctorsComponent.vue")
);
Vue.component("visitation-form", () =>
  import("./components/VisitationFormComponent.vue")
);
Vue.component("same-request", () =>
  import("./components/SameRequestComponent.vue")
);
Vue.component("radiology-done", () =>
  import("./components/RadiologyDoneComponent.vue")
);

Vue.component("order-form", () =>
  import("./components/OrderFormComponent.vue")
);

Vue.filter("formatMoney", FormatMoney);
Vue.filter("formatDate", FormatDate);
Vue.filter("formatDateTime", FormatDateTime);
Vue.filter("formatDateTimeShort", FormatDateTimeShort);
Vue.filter("formatDateLong", FormatDateLong);
Vue.filter("formatMonth", FormatMonth);
Vue.filter("formatPhone", FormatPhone);

//const eventsHub = new Vue();

const APP = new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
