<template>
  <div>
    <!--<header-page />-->
    <v-container grid-list-md px-0>

      <v-toolbar flat color="white" class="elevation-1 py-2">
        <v-toolbar-title>Relátorio</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-menu
          v-model="menuFilter.menu"
          bottom
          left
          :close-on-content-click="false"
          max-width="400"
          min-width="400"
          offset-x
        >
          <v-btn slot="activator" color="primary">OPÇÕES</v-btn>

          <v-card>
            <v-card-title class="px-1 py-1">
              FILTRO
              <v-spacer></v-spacer>
              <v-btn
                outline
                light
                small
                color="primary"
                @click="menuFilter.menu = true; filtro = {};"
              >Limpar</v-btn>
            </v-card-title>

            <v-layout row wrap>
              <v-flex xs12 class="px-1">
                <select-custom
                  url="/specialties/list?sortBy=name"
                  v-model="filtro.specialty_id"
                  label="Especialidade"
                />
              </v-flex>
              <v-flex xs12 class="px-1">
                <v-select
                  v-model="filtro.origin"
                  :items="['SAMU', 'UBS', 'AMBULÂNCIA', 'OUTROS']"
                  label="Origem"
                ></v-select>
              </v-flex>
            </v-layout>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn flat @click="menuFilter.menu = false">Fechar</v-btn>
              <v-btn color="primary" flat @click="getReport()">Filtrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>

        <v-spacer></v-spacer>
        <v-menu
          ref="menuStart"
          :close-on-content-click="false"
          v-model="range.menuStart"
          :nudge-right="40"
          :return-value.sync="range.start"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="computedStart"
            label="Data inicial"
            prepend-icon="event"
            readonly
          ></v-text-field>
          <v-date-picker v-model="range.start" no-title scrollable locale="pt-br">
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="range.menuStart = false">Cancel</v-btn>
            <v-btn flat color="primary" @click="$refs.menuStart.save(range.start)">OK</v-btn>
          </v-date-picker>
        </v-menu>

        <v-menu
          ref="menuEnd"
          :close-on-content-click="false"
          v-model="range.menuEnd"
          :nudge-right="40"
          :return-value.sync="range.end"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
          v-if="type != '/calls/reports/byhorary'"
        >
          <v-text-field
            slot="activator"
            v-model="computedEnd"
            label="Data Final"
            prepend-icon="event"
            readonly
          ></v-text-field>
          <v-date-picker v-model="range.end" no-title scrollable locale="pt-br">
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="range.menuEnd = false">Cancel</v-btn>
            <v-btn flat color="primary" @click="$refs.menuEnd.save(range.end)">OK</v-btn>
          </v-date-picker>
        </v-menu>

        <v-btn fab small color="primary" @click="getReport()">
          <v-icon dark>search</v-icon>
        </v-btn>
      </v-toolbar>

      <v-layout row wrap v-if="reportUrl">
        <v-flex xs12>
          <iframe width="100%" height="550px" :src="reportUrl" frameborder="0" allowfullscreen></iframe>
        </v-flex>
      </v-layout>

      <!--<v-fab-transition>
        <v-btn dark fab fixed bottom right color="primary" @click="openReportsPrint()">
          <v-icon>fas fa-download</v-icon>
        </v-btn>
        <v-btn dark fab fixed bottom right color="primary" @click="openReportsPrint()">
          <v-icon>fa-print</v-icon>
        </v-btn>
      </v-fab-transition>-->

      <v-speed-dial v-model="fab" fixed bottom right open-on-hover>
        <template v-slot:activator>
          <v-btn color="primary" dark fab>
            <v-icon>cloud_download</v-icon>
            <v-icon>close</v-icon>
          </v-btn>
        </template>
        <v-btn fab dark small color="blue" @click="openReportPlan()">
          <v-icon>fas fa-file-excel</v-icon>
        </v-btn>
        <v-btn fab dark small color="indigo" @click="openReportsPrint()">
          <v-icon>fa-print</v-icon>
        </v-btn>
      </v-speed-dial>

      <dialog-loader :active="loading"></dialog-loader>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      fab: false,
      range: {
        start: moment().format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD")
      },
      loading: false,
      reportUrl: "",
      type: "/calls/reports",
      menuFilter: { menu: false },
      filtro: {
        specialty_id: null,
        origin: null
      }
    };
  },
  computed: {
    computedStart() {
      return this.formatDate(this.range.start);
    },
    computedEnd() {
      return this.formatDate(this.range.end);
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getReport();
    },
    getReport() {
      this.menuFilter.menu = false;
      let origin = "";
      let specialty_id = "";
      if (this.filtro.origin) origin = `&origin=${this.filtro.origin}`;
      if (this.filtro.specialty_id)
        specialty_id = `&specialty_id=${this.filtro.specialty_id}`;
      this.reportUrl = `${this.type}?start=${this.range.start}&end=${this.range.end}${specialty_id}${origin}`;
    },
    openReportPlan() {
      this.menuFilter.menu = false;
      let origin = "";
      let specialty_id = "";
      if (this.filtro.origin) origin = `&origin=${this.filtro.origin}`;
      if (this.filtro.specialty_id)
        specialty_id = `&specialty_id=${this.filtro.specialty_id}`;
      let url = `/calls/plan?start=${this.range.start}&end=${this.range.end}${specialty_id}${origin}`;
      window.open(url);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    openReportsPrint() {
      window.open(this.reportUrl);
    }
  }
};
</script>
