<template>
  <v-layout row wrap>
    <v-flex xs12 align-center>
      <v-layout align-center justify-center row fill-height>
        <div class="text-xs-center">
          <h1 class="display-4">SENHA</h1>
          <h1
            class="display-4 font-weight-bold"
            style="font-size: 250px !important;"
          >{{getLetra(item.priority)}}{{item.number}}</h1>
          <h1 class="display-4 font-weight-bold">{{item.place}}</h1>

          <div style="display: none;">
            <audio id="myAudio">
              <source src="/sounds/ATone.mp3" type="audio/mpeg" />Your browser does not support the audio element.
            </audio>
          </div>
        </div>
      </v-layout>
    </v-flex>
    <v-flex xs12 class="pt-3 white lighten-3">
      <v-toolbar dark>
        <v-spacer></v-spacer>
        <v-toolbar-title class="display-1">ÚLTIMAS SENHAS</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-layout row wrap class="pt-3">
        <v-flex xs12 sm4 class="text-xs-center py-2" v-for="(item, i) in lastItems" :key="i">
          <span class="display-1" style="font-size: 50px !important;">
            <b>{{getLetra(item.priority)}}{{item.number}}</b>
            - {{item.place}}
          </span>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import io from "socket.io-client";
export default {
  data() {
    return {
      item: {},
      lastItems: []
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getItem({ id: this.$route.params.id });
      let port = ":4949";
      if (window.location.hostname == "hmpb.hoomweb.com") port = ":4848";
      const socket = io(window.location.hostname + port);
      socket.on("error", data => {
        console.log("error socket", data);
      });

      socket.on("connect_failed", data => {
        console.log("connect_failed", data);
      });

      socket.on("connect", () => {
        console.log("connect");
      });

      socket.on("update", data => {
        console.log(data);
        if (
          this.item.type == data.type ||
          (this.item.type == "Recepção" && data.type == "Triagem") ||
          (this.item.type == "Triagem" && data.type == "Recepção")
        ) {
          this.getItem(data);
        }
      });
    },
    getItem(obj) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$http
          .get(`/passwords/${obj.id}`)
          .then(response => response.data)
          .then(json => {
            if (this.item.number && json.number != this.item.number) {
              this.lastItems.unshift({ ...this.item });
            }
            this.item = json;
            this.voice();
          })
          .catch(() => (this.loading = false));
      });
    },
    getLetra(priority) {
      var p = "";
      switch (priority) {
        case 1:
          p = "PR";
          break;

        case 2:
          p = "PA";
          break;

        case 3:
          p = "AM";
          break;

        case 4:
          p = "RA";
          break;

        case 5:
          p = "RE";
          break;
        case 6:
          p = "AMP";
          break;
        case 7:
          p = "RAP";
          break;
        default:
          break;
      }

      return p;
    },
    getName(priority) {
      var p = "";
      switch (priority) {
        case 1:
          p = "PREFERENCIAL";
          break;

        case 2:
          p = "PRONTO ATENDIMENTO";
          break;

        case 3:
          p = "AMBULATÓRIO";
          break;

        case 4:
          p = "RADIOLOGIA";
          break;

        case 5:
          p = "REABILITAÇÃO";
          break;
        case 6:
          p = "AMBULATÓRIO PREFERENCIAL";
          break;
        case 7:
          p = "RADIOLOGIA PREFERENCIAL";
        default:
          break;
      }

      return p;
    },
    voice() {
      var x = document.getElementById("myAudio");
      if (!x) return;
      x.play();
      setTimeout(() => {
        if ("speechSynthesis" in window) {
          try {
            /*speechSynthesis.onvoiceschanged = function() {
            speechSynthesis.getVoices().forEach(function(voice, index) {
                console.log(voice);
            });
          }*/

            var msg = new SpeechSynthesisUtterance();
            //var voices = window.speechSynthesis.getVoices();
            //msg.voice = voices[5];
            //msg.rate = $('#rate').val() / 10;
            var p = this.getName(this.item.priority);

            msg.text = `SENHA, ${p}${this.item.number}, ${this.item.place}`;
            console.log(`SENHA, ${p}, ${this.item.number}, ${this.item.place}`);
            msg.onend = function(e) {
              console.log("Finished");
            };

            speechSynthesis.speak(msg);
          } catch (error) {
            console.log(error);
          }
        }
      }, 1000);
    }
  }
};
</script>
