<template>
  <div>
    <!--<header-page />-->
    <v-container grid-list-md text-xs-center px-0>
      <notifications position="bottom center" />
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Suporte</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
              class="hidden-md-and-down"
              @click:append="getList()"
              v-on:keyup.enter="getList"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="openSupportForm({})"
              >Novo</v-btn
            >
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.description }}</td>
                <td>{{ formatDate(props.item.created_at) }}</td>
                <td class="text-xs-center px-0">
                  <v-icon
                    v-if="USER.type == 1 || USER.id == props.item.user_id"
                    small
                    @click="openSupportForm(props.item)"
                  >
                    edit
                  </v-icon>
                  <!--<v-icon small @click="del(props.item.id)">
									delete
								</v-icon>-->
                </td>
              </tr>
            </template>

            <template slot="no-data">
              Desculpe, nenhum dado para ser exibido!
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination
              v-model="pagination.page"
              :length="pages"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>

      <div v-if="supportFormActive.open">
        <support-form
          :active="supportFormActive.open"
          :item="supportFormActive.item"
          :finish="onCloseSupportForm"
        ></support-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
export default {
  data() {
    return {
      USER: USER,
      search: "",
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "id",
      },
      items: [],
      columns: [
        { value: "id", text: "ID" },
        { value: "description", text: "Descrição" },
        { value: "created_at", text: "Data" },
        { value: "", text: "", sortable: false },
      ],
      supportFormActive: {
        open: false,
        item: {},
      },
      departmentViewActive: { open: false },
      loading: false,
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          search: this.search,
        };
        this.$http
          .get(`/supports/list`, { params })
          .then((response) => response.data)
          .then((json) => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    del(id) {
      if (confirm("Deseja realmente excluir este suporte?")) {
        this.$http.delete(`/supports/${id}`).then((response) => {
          this.$notify({
            title: `Suporte Excluido!`,
            type: "success",
          });
          this.getList();
        });
      }
    },
    formatDate: (date) => (date ? moment(date).format("DD/MM/YYYY") : ""),
    debounceInput: _.debounce(function () {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
    openSupportForm(item) {
      this.supportFormActive.open = true;
      this.supportFormActive.item = item;
    },
    onCloseSupportForm() {
      this.supportFormActive.open = false;
      this.getList();
    },
  },
};
</script>
