<template>
  <v-container grid-list-md text-xs-center px-0>
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar flat color="white" class="elevation-1">
          <v-toolbar-title>Ligações - Radiologia</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model.lazy="search"
            append-icon="fa-search"
            label="Buscar"
            single-line
            hide-details
            class="hidden-md-and-down"
            @click:append="getList()"
              v-on:keyup.enter="getList"
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-btn color="primary" dark class="mb-2" @click="confirmItemOpen({})">Novo</v-btn>
        </v-toolbar>

        <v-data-table
          :headers="columns"
          :items="items"
          :pagination.sync="pagination"
          :total-items="pagination.totalItems"
          :loading="loading"
          hide-actions
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr
              :class="{
                'blue lighten-3': props.item.status == 'CONFIRMOU',
                'red lighten-3': props.item.status == 'DESISTÊNCIA',
                'yellow lighten-3': (props.item.status != 'CONFIRMOU' && props.item.status != 'DESISTÊNCIA')
              }"
            >
              <td>{{ props.item.user && props.item.user.name}}</td>
              <td>{{ props.item.created_at | formatDateTimeShort }}</td>
              <td>{{ props.item.patient && props.item.patient.name}}</td>
              <td>{{ props.item.patient && props.item.patient.rh}}</td>
              <td>{{ props.item.patient && props.item.patient.phone | formatPhone}}</td>
              <td>{{ props.item.status}}</td>
              <td>{{ props.item.horary | formatDateTimeShort}}</td>
              <td>{{ props.item.contact_name}}</td>
              <td>{{ props.item.obs}}</td>

              <td class="text-xs-center px-0">
                <v-icon small @click="confirmItemOpen(props.item)">edit</v-icon>
              </td>
            </tr>
          </template>

          <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
        </v-data-table>
        <div class="text-xs-center pt-2">
          <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
        </div>
      </v-flex>
    </v-layout>

    <div v-if="confirmItem.open">
      <consultation-confirm
        :active="confirmItem.open"
        :item="confirmItem.item"
        :finish="confirmItemClose"
      />
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import moment from "moment";
export default {
  data() {
    return {
      valid: false,
      search: "",
      user: USER,
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "id"
      },
      items: [],
      columns: [
        { value: "user.name", text: "Usuário", sortable: false },
        { value: "created_at", text: "Data e Hora", sortable: false },
        { value: "patient.name", text: "Paciente", sortable: false },
        { value: "patient.rh", text: "RH", sortable: false },
        { value: "patient.phone", text: "Telefone", sortable: false },
        { value: "status", text: "Status", sortable: false },
        { value: "horary", text: "Agendado", sortable: false },
        { value: "contact_name", text: "Falou com quem?", sortable: false },
        { value: "obs", text: "obs", sortable: false },
        { text: "", value: "", sortable: false }
      ],
      confirmItem: { open: false, item: {} },
      loading: false
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          ...this.filtro,
          totalItems: undefined,
          search: this.search,
          sector: "Radiologia"
        };
        this.$http
          .get(`/call_confirms/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    del(id) {
      if (confirm("Deseja realmente excluir esta Radiologia?")) {
        this.$http.delete(`/call_confirms/${id}`).then(response => {
          this.$notify({
            title: `Radiologia Excluido!`,
            type: "success"
          });
          this.getList();
        });
      }
    },
    confirmItemOpen(item) {
      this.confirmItem.open = true;
      const params = {
        call_confirm: {
          status: null,
          sector: "Radiologia"
        }
      };
      if (item.id) params.call_confirm = item;

      this.confirmItem.item = params;
    },
    confirmItemClose() {
      this.confirmItem.open = false;
      this.getList();
    },
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
    filtrar() {
      this.menuFilter.menu = false;
      this.getList();
    }
  }
};
</script>
