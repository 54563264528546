<template>
  <div>
    <!--<header-page />-->
    <v-container grid-list-md text-xs-center px-0>
      <notifications position="bottom center" />
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Metas</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-icon class="mr-2" @click="year--">fas fa-arrow-left</v-icon>
            {{ year }}
            <v-icon class="ml-2" @click="year++">fas fa-arrow-right</v-icon>
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td style="padding: 0 4px !important;">
                  {{ props.item.code }} - {{ props.item.name }}
                </td>
                <td style="padding: 0 4px !important;">
                  {{ props.item.mark | formatMoney }}
                </td>
                <td
                  style="padding: 0 4px !important; cursor: pointer;"
                  :class="{ 'red lighten-1': props.item.mark < props.item.m01 }"
                  @click="listBillByTypeOpen(props.item, 0)"
                >
                  {{ props.item.m01 | formatMoney }}
                </td>
                <td
                  style="padding: 0 4px !important; cursor: pointer;"
                  :class="{ 'red lighten-1': props.item.mark < props.item.m02 }"
                  @click="listBillByTypeOpen(props.item, 1)"
                >
                  {{ props.item.m02 | formatMoney }}
                </td>
                <td
                  style="padding: 0 4px !important; cursor: pointer;"
                  :class="{ 'red lighten-1': props.item.mark < props.item.m03 }"
                  @click="listBillByTypeOpen(props.item, 2)"
                >
                  {{ props.item.m03 | formatMoney }}
                </td>
                <td
                  style="padding: 0 4px !important; cursor: pointer;"
                  :class="{ 'red lighten-1': props.item.mark < props.item.m04 }"
                  @click="listBillByTypeOpen(props.item, 3)"
                >
                  {{ props.item.m04 | formatMoney }}
                </td>
                <td
                  style="padding: 0 4px !important; cursor: pointer;"
                  :class="{ 'red lighten-1': props.item.mark < props.item.m05 }"
                  @click="listBillByTypeOpen(props.item, 4)"
                >
                  {{ props.item.m05 | formatMoney }}
                </td>
                <td
                  style="padding: 0 4px !important; cursor: pointer;"
                  :class="{ 'red lighten-1': props.item.mark < props.item.m06 }"
                  @click="listBillByTypeOpen(props.item, 5)"
                >
                  {{ props.item.m06 | formatMoney }}
                </td>
                <td
                  style="padding: 0 4px !important; cursor: pointer;"
                  :class="{ 'red lighten-1': props.item.mark < props.item.m07 }"
                  @click="listBillByTypeOpen(props.item, 6)"
                >
                  {{ props.item.m07 | formatMoney }}
                </td>
                <td
                  style="padding: 0 4px !important; cursor: pointer;"
                  :class="{ 'red lighten-1': props.item.mark < props.item.m08 }"
                  @click="listBillByTypeOpen(props.item, 7)"
                >
                  {{ props.item.m08 | formatMoney }}
                </td>
                <td
                  style="padding: 0 4px !important; cursor: pointer;"
                  :class="{ 'red lighten-1': props.item.mark < props.item.m09 }"
                  @click="listBillByTypeOpen(props.item, 8)"
                >
                  {{ props.item.m09 | formatMoney }}
                </td>
                <td
                  style="padding: 0 4px !important; cursor: pointer;"
                  :class="{ 'red lighten-1': props.item.mark < props.item.m10 }"
                  @click="listBillByTypeOpen(props.item, 9)"
                >
                  {{ props.item.m10 | formatMoney }}
                </td>
                <td
                  style="padding: 0 4px !important; cursor: pointer;"
                  :class="{ 'red lighten-1': props.item.mark < props.item.m11 }"
                  @click="listBillByTypeOpen(props.item, 10)"
                >
                  {{ props.item.m11 | formatMoney }}
                </td>
                <td
                  style="padding: 0 4px !important; cursor: pointer;"
                  :class="{ 'red lighten-1': props.item.mark < props.item.m12 }"
                  @click="listBillByTypeOpen(props.item, 11)"
                >
                  {{ props.item.m12 | formatMoney }}
                </td>
              </tr>
            </template>

            <template slot="no-data"
              >Desculpe, nenhum dado para ser exibido!</template
            >

            <template slot="footer">
              <td style="padding: 0 4px !important;">
                <b>TOTAL</b>
              </td>
              <td style="padding: 0 4px !important;">
                <b>{{ totais.mark | formatMoney }}</b>
              </td>
              <td style="padding: 0 4px !important;">
                <b>{{ totais.m01 | formatMoney }}</b>
              </td>
              <td style="padding: 0 4px !important;">
                <b>{{ totais.m02 | formatMoney }}</b>
              </td>
              <td style="padding: 0 4px !important;">
                <b>{{ totais.m03 | formatMoney }}</b>
              </td>
              <td style="padding: 0 4px !important;">
                <b>{{ totais.m04 | formatMoney }}</b>
              </td>
              <td style="padding: 0 4px !important;">
                <b>{{ totais.m05 | formatMoney }}</b>
              </td>
              <td style="padding: 0 4px !important;">
                <b>{{ totais.m06 | formatMoney }}</b>
              </td>
              <td style="padding: 0 4px !important;">
                <b>{{ totais.m07 | formatMoney }}</b>
              </td>
              <td style="padding: 0 4px !important;">
                <b>{{ totais.m08 | formatMoney }}</b>
              </td>
              <td style="padding: 0 4px !important;">
                <b>{{ totais.m09 | formatMoney }}</b>
              </td>
              <td style="padding: 0 4px !important;">
                <b>{{ totais.m10 | formatMoney }}</b>
              </td>
              <td style="padding: 0 4px !important;">
                <b>{{ totais.m11 | formatMoney }}</b>
              </td>
              <td style="padding: 0 4px !important;">
                <b>{{ totais.m12 | formatMoney }}</b>
              </td>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>

    <div v-if="listBillByTypeActive.open">
      <list-bill-by-type
        :active="listBillByTypeActive.open"
        :item="listBillByTypeActive.item"
        :finish="listBillByTypeClose"
        :month="listBillByTypeActive.month"
        :year="listBillByTypeActive.year"
      ></list-bill-by-type>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      year: moment().format("YYYY"),
      items: [],
      totais: {
        mark: 0,
        m01: 0,
        m02: 0,
        m03: 0,
        m04: 0,
        m05: 0,
        m06: 0,
        m07: 0,
        m08: 0,
        m09: 0,
        m010: 0,
        m011: 0,
        m012: 0
      },
      columns: [
        { value: "name", text: "Nome", sortable: false, class: "custom-th" },
        { value: "mark", text: "Meta", sortable: false, class: "custom-th" },
        { value: "m01", text: "Janeiro", sortable: false, class: "custom-th" },
        {
          value: "m02",
          text: "Fevereiro",
          sortable: false,
          class: "custom-th"
        },
        { value: "m03", text: "Março", sortable: false, class: "custom-th" },
        { value: "m04", text: "Abril", sortable: false, class: "custom-th" },
        { value: "m05", text: "Maio", sortable: false, class: "custom-th" },
        { value: "m06", text: "Junho", sortable: false, class: "custom-th" },
        { value: "m07", text: "Julho", sortable: false, class: "custom-th" },
        { value: "m08", text: "Agosto", sortable: false, class: "custom-th" },
        { value: "m09", text: "Setembro", sortable: false, class: "custom-th" },
        { value: "m10", text: "Outubro", sortable: false, class: "custom-th" },
        { value: "m11", text: "Novembro", sortable: false, class: "custom-th" },
        { value: "m12", text: "Dezembro", sortable: false, class: "custom-th" }
      ],
      listBillByTypeActive: { open: false, item: {} },
      loading: false
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    year(val) {
      this.getList();
    }
  },
  methods: {
    initialize() {
      this.getList();
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$http
          .get(`/bill_types/marks?year=${this.year}`)
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;
              this.totais = {
                mark: 0,
                m01: 0,
                m02: 0,
                m03: 0,
                m04: 0,
                m05: 0,
                m06: 0,
                m07: 0,
                m08: 0,
                m09: 0,
                m10: 0,
                m11: 0,
                m12: 0
              };

              this.items = json.items;

              this.items.forEach(o => {
                this.totais.mark += parseFloat(o.mark);
                this.totais.m01 += parseFloat(o.m01);
                this.totais.m02 += parseFloat(o.m02);
                this.totais.m03 += parseFloat(o.m03);
                this.totais.m04 += parseFloat(o.m04);
                this.totais.m05 += parseFloat(o.m05);
                this.totais.m06 += parseFloat(o.m06);
                this.totais.m07 += parseFloat(o.m07);
                this.totais.m08 += parseFloat(o.m08);
                this.totais.m09 += parseFloat(o.m09);
                this.totais.m10 += parseFloat(o.m10);
                this.totais.m11 += parseFloat(o.m11);
                this.totais.m12 += parseFloat(o.m12);
                console.log(this.totais.mark);
              });
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    listBillByTypeOpen(item, month) {
      this.listBillByTypeActive.open = true;
      this.listBillByTypeActive.item = item;
      this.listBillByTypeActive.month = month;
      this.listBillByTypeActive.year = this.year;
    },
    listBillByTypeClose() {
      this.listBillByTypeActive.open = false;
      this.getList();
    }
  }
};
</script>

<style scoped>
table.v-table tbody td,
table.v-table tbody th {
  height: 35px;
}
table.v-table thead tr {
  height: 40px;
}

table td {
  font-size: 11px !important;
}
</style>
