<template>
  <div>
    <!--<header-page />-->
    <v-container grid-list-md text-xs-center px-0>
      <notifications position="bottom center" />
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Produtos</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
              class="hidden-md-and-down"
              @click:append="getList()"
              v-on:keyup.enter="getList"
            ></v-text-field>
            <v-spacer></v-spacer>
            <!--<input-single-product title="Devolver Produto" :finish="returnSingleProduct" />-->
            <v-btn
              v-if="user.type != 9"
              color="grey"
              dark
              class="mb-2"
              @click="devolutionPackFormOpen({ office: $route.params.office })"
              >Devolver Produtos</v-btn
            >

            <v-btn
              v-if="user.type != 9"
              color="primary"
              dark
              class="mb-2"
              @click="openProductAddForm({ office: $route.params.office })"
              >Novo</v-btn
            >
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.code }}</td>
                <td>{{ props.item.category.name }}</td>
                <td>
                  {{ props.item.subcategory && props.item.subcategory.name }}
                </td>
                <td>{{ props.item.single_products_count }}</td>
                <td>{{ props.item.measurement }}</td>
                <td>{{ props.item.qty_min }}</td>
                <td>
                  {{ props.item.status === 1 ? "Ativado" : "Desativado" }}
                </td>
                <td class="text-xs-center px-1">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon small>fas fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-tile @click="openProductView(props.item)">
                        <v-list-tile-avatar>
                          <v-icon>fas fa-eye</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>DETALHES</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile @click="openCustomPrint(props.item)">
                        <v-list-tile-avatar>
                          <v-icon>fas fa-barcode</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title
                          >IMPRIMIR ETIQUETAS</v-list-tile-title
                        >
                      </v-list-tile>
                      <v-list-tile
                        v-if="props.item.fractioned"
                        @click="goPage('/app/single_products/' + props.item.id)"
                      >
                        <v-list-tile-avatar>
                          <v-icon>fas fa-list-alt</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>LISTA FRACIONADOS</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile
                        v-if="user.type != 9"
                        @click="openProductAddForm(props.item)"
                      >
                        <v-list-tile-avatar>
                          <v-icon>fa-edit</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>EDITAR</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile
                        v-if="
                          user.modules.includes(
                            'RELATORIO_MOVIMENTO_ESTOQUE'
                          ) || user.type == 1
                        "
                        @click="
                          goPage(
                            `/report_movement/${props.item.office}/${props.item.id}`
                          )
                        "
                      >
                        <v-list-tile-avatar>
                          <v-icon>fas fa-people-carry</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title
                          >RELATÓRIO DE MOVIMENTAÇÃO</v-list-tile-title
                        >
                      </v-list-tile>
                      <v-list-tile @click="desativarProduct(props.item)">
                        <v-list-tile-avatar>
                          <v-icon>fas fa-ban</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>DESATIVAR PRODUTO</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>

                  <!--<v-icon
                    v-if="user.type != 9"
                    small
                    @click="openProductForm(props.item)"
                  >fas fa-exchange-alt</v-icon>-->
                </td>
              </tr>
            </template>

            <template slot="no-data"
              >Desculpe, nenhum dado para ser exibido!</template
            >
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination
              v-model="pagination.page"
              :length="pages"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>

      <v-dialog v-model="dialogCustomPrint.open" width="450">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title
              >Produto: {{ dialogCustomPrint.item.name }}</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-toolbar-items></v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-container grid-list-md px-0 py-0>
              <v-layout wrap>
                <v-flex xs12 class="text-xs-center">
                  <v-btn
                    @click="
                      goExteral(
                        '/single_products/tags?product_id=' +
                          dialogCustomPrint.item.id
                      )
                    "
                    >IMPRIMIR TUDO</v-btn
                  >
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    type="number"
                    v-model="dialogCustomPrint.stop_print"
                    label="A parti de"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    type="number"
                    v-model="dialogCustomPrint.stop_print_qty"
                    label="Quantidade"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="dialogCustomPrint.sector_id"
                    :items="sectors"
                    label="Setor"
                  ></v-select>
                </v-flex>
                <v-flex xs12 class="pr-2">
                  <v-subheader class="pl-0"
                    >Margem Direita da Impressao</v-subheader
                  >
                  <v-slider
                    v-model="dialogCustomPrint.margin"
                    thumb-label="always"
                    step="0.1"
                    max="10"
                  ></v-slider>
                </v-flex>
                <v-flex xs12 class="text-xs-center">
                  <v-btn @click="goCustom()">IMPRIMIR CUSTOMIZADO</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <div v-if="productFormActive.open">
        <transfer-product-form
          :active="productFormActive.open"
          :item="productFormActive.item"
          :finish="onCloseProductForm"
        ></transfer-product-form>
      </div>

      <div v-if="productAddFormActive.open">
        <product-form
          :active="productAddFormActive.open"
          :item="productAddFormActive.item"
          :finish="onCloseProductAddForm"
        ></product-form>
      </div>

      <div v-if="devolutionPackFormActive.open">
        <devolution-pack-form
          :active="devolutionPackFormActive.open"
          :item="devolutionPackFormActive.item"
          :finish="devolutionPackFormClose"
        />
      </div>

      <div v-if="productViewActive.open">
        <product-view
          :active="productViewActive.open"
          :item="productViewActive.item"
          :finish="onCloseProductView"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import { maskJs } from "mask-js";
export default {
  data() {
    return {
      valid: false,
      search: "",
      user: USER,
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "id"
      },
      items: [],
      estoques: [],
      columns: [
        { value: "id", text: "ID" },
        { value: "name", text: "Nome" },
        { value: "code", text: "Código" },
        { value: "category_id", text: "Categoria" },
        { value: "subcategory_id", text: "Subcategoria" },
        { value: "qty", text: "Quantidade", sortable: false },
        { value: "measurement", text: "Medida" },
        { value: "qty_min", text: "Qtd. Mínima", sortable: false },
        { value: "status", text: "Status", sortable: false },
        { text: "", value: "", sortable: false }
      ],
      devolutionPackFormActive: { open: false, item: {} },
      productFormActive: {
        open: false,
        item: {}
      },
      productAddFormActive: {
        open: false,
        item: {}
      },
      productViewActive: {
        open: false,
        item: {}
      },
      productViewActive: { open: false },
      dialogCustomPrint: {
        open: false,
        item: {},
        stop_print: 0,
        stop_print_qty: 0,
        sector_id: null,
        margin: 2
      },
      loading: false,
      dialogProductTo: false,
      sectors: []
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getListSectors();
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          search: this.search,
          office: this.$route.params.office
        };
        this.$http
          .get(`/products/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    getListSectors() {
      this.$http
        .get(`/sectors/listall?office=${this.$route.params.office}`)
        .then(response => response.data)
        .then(json => {
          if (json && json.items) {
            const arr = [];
            json.items.forEach(obj =>
              arr.push({
                text: obj.name,
                value: obj.id,
                ...obj.name
              })
            );
            this.sectors = arr;
          }
        });
    },
    del(id) {
      if (confirm("Deseja realmente excluir este Produto?")) {
        this.$http.delete(`/products/${id}`).then(response => {
          this.$notify({
            title: `Produto Excluido!`,
            type: "success"
          });
          this.getList();
        });
      }
    },
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
    goPage(url) {
      this.$router.push(url);
    },
    goExternal(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    openProductForm(item) {
      this.productFormActive.open = true;
      this.productFormActive.item = item;
    },
    onCloseProductForm() {
      this.productFormActive.open = false;
      this.getList();
    },
    openProductAddForm(item) {
      this.productAddFormActive.open = true;
      this.productAddFormActive.item = item;
    },
    onCloseProductAddForm() {
      this.productAddFormActive.open = false;
      this.getList();
    },
    devolutionPackFormOpen(item) {
      this.devolutionPackFormActive.open = true;
      this.devolutionPackFormActive.item = item;
    },
    devolutionPackFormClose() {
      this.devolutionPackFormActive.open = false;
      this.getList();
    },
    openProductView(item) {
      this.productViewActive.open = true;
      this.productViewActive.item = item;
    },
    onCloseProductView(category) {
      this.productViewActive.open = false;
      this.getList();
    },
    desativarProduct(item) {
      var confirmacao = confirm(
        "Tem certeza que deseja desativar o produto " + item.name + "?"
      );
      if (confirmacao) this.callback1(item);
      // else this.callback2();
    },
    callback1(item) {
      this.$http
        .delete(`/products/${item.id}`, {})
        .then(response => response.data)
        .then(json => {
          console.log(json);
          this.$notify({
            title: `Produto desativado com sucesso!`,
            type: "success"
          });
          this.getList();
        })
        .catch(err => {

          if (err.response && err.response.data && err.response.data.error) {
            this.$notify({
              title: err.response.data.error,
              type: "error"
            });
          } else {
            this.$notify({
              title: "Erro ao salvar",
              type: "error"
            });
          }
        });
    },
    callback2() {
      alert("Ok.");
    },
    returnSingleProduct(obj) {
      console.log(obj);
      if (!obj.used) {
        this.$notify({
          title: `Este produto já está no estoque`,
          type: "error"
        });
        return;
      }

      this.$http
        .put(`/single_products/${obj.id}/devolution`, {})
        .then(response => response.data)
        .then(json => {
          this.$notify({
            title: `Produto devolvido com sucesso!`,
            type: "success"
          });
          this.getList();
        })
        .catch(err => {
          console.log(err);
          this.$reqError(`Erro ao salvar!`, err, this);
        });
    },
    openCustomPrint(item) {
      this.dialogCustomPrint.open = true;
      this.dialogCustomPrint.item = item;
    },
    goExteral(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    goCustom() {
      this.goExteral(
        `/single_products/tags?product_id=${this.dialogCustomPrint.item.id}&stop_print=${this.dialogCustomPrint.stop_print}
        &stop_print_qty=${this.dialogCustomPrint.stop_print_qty}&sector_id=${this.dialogCustomPrint.sector_id}
        &margin=${this.dialogCustomPrint.margin}`
      );
    }
  }
};
</script>
