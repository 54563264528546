<template>
<div>
  <!--<header-page />-->
  <v-container grid-list-md text-xs-center>
    <v-layout align-center justify-center row fill-height>
      <v-flex>
        <h1 class="display-2">Hospital Municipal Pimentas Bonsucesso</h1>
      </v-flex>
    </v-layout>
  </v-container>
</div>
</template>

<script>
export default {

  data() {
		return {}
  },
}
</script>
