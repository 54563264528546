<template>
  <div>
    <!--<header-page />-->
    <v-container grid-list-md text-xs-center px-0>
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Tipos de Cirurgia</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
              class="hidden-md-and-down"
              @click:append="getList()"
              v-on:keyup.enter="getList"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2" @click="openSurgeryTypeForm({})">Novo</v-btn>
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.resp }}</td>

                <td class="text-xs-center px-0">
                  <v-icon class="mr-1" small @click="openAgendaForm(props.item)">fas fa-calendar-alt</v-icon>
                  <v-icon small @click="openSurgeryTypeForm(props.item)">edit</v-icon>
                </td>
              </tr>
            </template>

            <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
          </div>
        </v-flex>
      </v-layout>

      <div v-if="surgeryTypeFormActive.open">
        <surgery-type-form
          :active="surgeryTypeFormActive.open"
          :item="surgeryTypeFormActive.item"
          :finish="onClosesurgeryTypeForm"
        />
      </div>

      <div v-if="surgeryAgendaActive.open">
        <surgery-agenda
          :active="surgeryAgendaActive.open"
          :item="surgeryAgendaActive.item"
          :finish="onClosesurgeryAgenda"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      valid: false,
      search: "",
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "id"
      },
      items: [],
      columns: [
        { value: "id", text: "ID" },
        { value: "name", text: "Nome" },
        { value: "resp", text: "Responsável" },
        { text: "", value: "", sortable: false }
      ],
      surgeryTypeFormActive: {
        open: false,
        item: {}
      },
      surgeryAgendaActive: {
        open: false,
        item: {}
      },
      loading: false
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          search: this.search,
          radiology: parseInt(this.$route.params.radiology)
        };
        this.$http
          .get(`/surgery_types/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    del(id) {
      if (confirm("Deseja realmente excluir esta Item?")) {
        this.$http.delete(`/surgery_types/${id}`).then(response => {
          this.$notify({
            title: `Item Excluido!`,
            type: "success"
          });
          this.getList();
        });
      }
    },
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),

    openSurgeryTypeForm(item) {
      this.surgeryTypeFormActive.open = true;
      this.surgeryTypeFormActive.item = item;
    },
    onClosesurgeryTypeForm(category) {
      this.surgeryTypeFormActive.open = false;
      this.getList();
    },
    openAgendaForm(item) {
      this.surgeryAgendaActive.open = true;
      this.surgeryAgendaActive.item = item;
    },
    onClosesurgeryAgenda(category) {
      this.surgeryAgendaActive.open = false;
      this.getList();
    }
  }
};
</script>
