var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"grid-list-md":"","text-xs-center":"","px-0":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-toolbar',{staticClass:"elevation-1",attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Radiologia")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-text-field',{staticClass:"mt-2",attrs:{"type":"date","label":"Data Nascimento","clearable":""},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=$$v},expression:"birthday"}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-text-field',{staticClass:"hidden-md-and-down",attrs:{"append-icon":"fa-search","label":"Buscar","single-line":"","hide-details":""},on:{"click:append":function($event){return _vm.getList()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.getList.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-menu',{attrs:{"bottom":"","left":"","close-on-content-click":false,"max-width":"400","min-width":"400","offset-x":""},model:{value:(_vm.menuFilter.menu),callback:function ($$v) {_vm.$set(_vm.menuFilter, "menu", $$v)},expression:"menuFilter.menu"}},[_c('v-btn',{attrs:{"slot":"activator","outline":"","color":"primary"},slot:"activator"},[_vm._v("OPÇÕES")]),_vm._v(" "),_c('v-card',[_c('v-card-title',{staticClass:"px-1 py-1"},[_vm._v("\n              OPÇÕES\n              "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"outline":"","light":"","small":"","color":"primary"},on:{"click":function($event){_vm.menuFilter.menu = true;
                  _vm.filtro = {};}}},[_vm._v("Limpar")])],1),_vm._v(" "),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"px-1",attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"type":"date","label":"De"},model:{value:(_vm.filtro.start),callback:function ($$v) {_vm.$set(_vm.filtro, "start", $$v)},expression:"filtro.start"}})],1),_vm._v(" "),_c('v-flex',{staticClass:"px-1",attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"type":"date","label":"Até"},model:{value:(_vm.filtro.end),callback:function ($$v) {_vm.$set(_vm.filtro, "end", $$v)},expression:"filtro.end"}})],1),_vm._v(" "),_c('v-flex',{staticClass:"px-1",attrs:{"xs12":""}},[_c('v-select',{attrs:{"label":"Origem","items":_vm.origins,"multiple":""},model:{value:(_vm.filtro.origins),callback:function ($$v) {_vm.$set(_vm.filtro, "origins", $$v)},expression:"filtro.origins"}})],1),_vm._v(" "),_c('v-flex',{staticClass:"px-1",attrs:{"xs12":""}},[_c('v-select',{attrs:{"items":_vm.types,"label":"Tipo"},model:{value:(_vm.filtro.radiology_type),callback:function ($$v) {_vm.$set(_vm.filtro, "radiology_type", $$v)},expression:"filtro.radiology_type"}})],1),_vm._v(" "),_c('v-flex',{staticClass:"px-1",attrs:{"xs12":""}},[_c('v-select',{attrs:{"items":['SIMPLES', 'COM CONTRASTE', 'COM SEDAÇÃO'],"label":"TOMOGRAFIA"},model:{value:(_vm.filtro.tomography),callback:function ($$v) {_vm.$set(_vm.filtro, "tomography", $$v)},expression:"filtro.tomography"}})],1),_vm._v(" "),_c('v-flex',{staticClass:"px-1",attrs:{"xs12":""}},[_c('v-select',{attrs:{"items":_vm.subtypes,"label":"Sub-Tipo"},model:{value:(_vm.filtro.subtype),callback:function ($$v) {_vm.$set(_vm.filtro, "subtype", $$v)},expression:"filtro.subtype"}})],1),_vm._v(" "),_c('v-flex',{staticClass:"px-1",attrs:{"xs12":""}},[_c('v-select',{attrs:{"items":[
                    { value: null, text: 'Selecionar' },
                    { value: 1, text: 'SIM' },
                    { value: 0, text: 'NÃO' }
                  ],"label":"Feito procedimento"},model:{value:(_vm.filtro.used),callback:function ($$v) {_vm.$set(_vm.filtro, "used", $$v)},expression:"filtro.used"}})],1),_vm._v(" "),_c('v-flex',{staticClass:"px-1",attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"label":"Médico"},model:{value:(_vm.filtro.doctor_name),callback:function ($$v) {_vm.$set(_vm.filtro, "doctor_name", $$v)},expression:"filtro.doctor_name"}})],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"flat":""},on:{"click":function($event){_vm.menuFilter.menu = false}}},[_vm._v("Fechar")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","flat":""},on:{"click":function($event){return _vm.goReport()}}},[_vm._v("IMPRIMIR")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.filtrar()}}},[_vm._v("FILTRAR")])],1)],1)],1),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.openRadiologyForm({ office: _vm.$route.params.office })}}},[_vm._v("Novo")])],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.columns,"items":_vm.items,"pagination":_vm.pagination,"total-items":_vm.pagination.totalItems,"loading":_vm.loading,"hide-actions":""},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{class:{
              'green lighten-1': props.item.done && props.item.used,
              'red lighten-1': props.item.done && !props.item.used
            }},[_c('td',[_vm._v(_vm._s(props.item.id))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(props.item.user.name))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(props.item.patient && props.item.patient.name))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(props.item.patient && props.item.patient.rh))]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(props.item.origin)+"\n              "+_vm._s(props.item.origin == "AMBULATORIO UNIDADES EXTERNAS" &&
                props.item.ambulator
                  ? `(${props.item.ambulator})`
                  : "")+"\n            ")]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s(props.item.radiology_type)+"\n              "+_vm._s(props.item.radiology_type == "TOMOGRAFIA" &&
                props.item.tomography
                  ? `(${props.item.tomography})`
                  : "")+"\n            ")]),_vm._v(" "),_c('td',_vm._l((props.item.subtypes),function(item,index){return _c('v-chip',{key:index,staticStyle:{"font-size":"9px"},attrs:{"small":""}},[_vm._v(_vm._s(item.name))])}),1),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm._f("formatDateTimeShort")(props.item.horary)))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(props.item.doctor_name))]),_vm._v(" "),_c('td',{staticClass:"text-xs-center px-0"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-ellipsis-v")])],1)]}}],null,true)},[_vm._v(" "),_c('v-list',[_c('v-list-tile',{on:{"click":function($event){return _vm.goExternal(`/exams/${props.item.id}/fa_radiology`)}}},[_c('v-list-tile-avatar',[_c('v-icon',[_vm._v("fa-print")])],1),_vm._v(" "),_c('v-list-tile-title',[_vm._v("Imprimir Ficha")])],1),_vm._v(" "),_c('v-list-tile',{on:{"click":function($event){return _vm.goExternal(`/exams/${props.item.id}/tag_radiology`)}}},[_c('v-list-tile-avatar',[_c('v-icon',[_vm._v("fas fa-tag")])],1),_vm._v(" "),_c('v-list-tile-title',[_vm._v("Imprimir Etiqueta")])],1),_vm._v(" "),_c('v-list-tile',{on:{"click":function($event){return _vm.goExternal(`/exams/${props.item.id}/tag_patient`)}}},[_c('v-list-tile-avatar',[_c('v-icon',[_vm._v("fas fa-tag")])],1),_vm._v(" "),_c('v-list-tile-title',[_vm._v("Imprimir Etiqueta Paciente")])],1),_vm._v(" "),(!props.item.done)?_c('v-list-tile',{on:{"click":function($event){return _vm.openRadiologyDone(props.item)}}},[_c('v-list-tile-avatar',[_c('v-icon',[_vm._v("fas fa-check")])],1),_vm._v(" "),_c('v-list-tile-title',[_vm._v("Finalizar Exame")])],1):_vm._e(),_vm._v(" "),(
                      !props.item.done ||
                        _vm.user.type == 1 ||
                        _vm.user.modules.includes('RADIOLOGIA_REVERTER')
                    )?_c('v-list-tile',{on:{"click":function($event){return _vm.openRadiologyForm(props.item)}}},[_c('v-list-tile-avatar',[_c('v-icon',[_vm._v("fa-edit")])],1),_vm._v(" "),_c('v-list-tile-title',[_vm._v("Editar")])],1):_vm._e(),_vm._v(" "),(
                      props.item.done &&
                        (_vm.user.type == 1 ||
                          _vm.user.modules.includes('RADIOLOGIA_REVERTER'))
                    )?_c('v-list-tile',{on:{"click":function($event){return _vm.revert(props.item)}}},[_c('v-list-tile-avatar',[_c('v-icon',[_vm._v("undo")])],1),_vm._v(" "),_c('v-list-tile-title',[_vm._v("Reverter")])],1):_vm._e()],1)],1)],1)])]}}])},[_vm._v(" "),_c('template',{slot:"no-data"},[_vm._v("Desculpe, nenhum dado para ser exibido!")])],2),_vm._v(" "),_c('div',{staticClass:"mt-2 text-xs-left"},[_vm._v("TOTAL "+_vm._s(_vm.totalExam))]),_vm._v(" "),_c('div',{staticClass:"text-xs-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pages},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)],1),_vm._v(" "),(_vm.radiologyFormActive.open)?_c('div',[_c('radiology-form',{attrs:{"active":_vm.radiologyFormActive.open,"item":_vm.radiologyFormActive.item,"finish":_vm.onCloseRadiologyForm}})],1):_vm._e(),_vm._v(" "),(_vm.radiologyDone.open)?_c('div',[_c('radiology-done',{attrs:{"active":_vm.radiologyDone.open,"item":_vm.radiologyDone.item,"finish":_vm.onCloseRadiologyDone}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }