<template>
  <div>
    <v-container grid-list-md text-xs-center px-0>
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>ESCRITURAÇÃO TÉRREO Prontuário</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-text-field
              v-model.lazy="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
              class="hidden-md-and-down"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-text-field
              class="mt-2"
              small
              dense
              outline
              single-line
              v-model="rh"
              label="RH para entrada"
              append-icon="fas fa-plus"
              @click:append="receivedByRh()"
            ></v-text-field>
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.user.name }}</td>
                <td>{{ props.item.horary | formatDateTimeShort}}</td>
                <td>{{ props.item.patient.name }}</td>
                <td>{{ props.item.patient.rh }}</td>
                <td>{{ props.item.patient.death ? 'SIM' : ''}}</td>
                <td>{{ props.item.patient.same_local }}</td>
                <td>{{ props.item.patient.same }}</td>
                <td>{{ props.item.destiny }}</td>
              </tr>
            </template>

            <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
export default {
  data() {
    return {
      valid: false,
      search: "",
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "id"
      },
      items: [],
      columns: [
        { value: "user", text: "Solicitado", sortable: false },
        { value: "horary", text: "Data e Hora" },
        { value: "patient.name", text: "Paciente", sortable: false },
        { value: "patient.rh", text: "RH", sortable: false },
        { value: "patient.death", text: "Óbito", sortable: false },
        { value: "patient.same_local", text: "Arquivo", sortable: false },
        { value: "patient.same", text: "Atualmente", sortable: false },
        { value: "destiny", text: "Destino" }
      ],
      radiologyFormActive: {
        open: false,
        item: {}
      },
      menu1: false,
      time: moment().format("YYYY-MM-DD"),
      loading: false,
      selected: [],
      rh: ""
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true
    },
    search: function() {
      this.debounceInput();
    }
    //rh: function(n, o) {
    //  this.debounceInputRh();
    //}
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          search: this.search,
          //horary: this.time,
          done: 0,
          active: 0,
          destiny: "ESCRITURAÇÃO TÉRREO"
        };
        this.$http
          .get(`/same/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    async receivedByRh() {
      if (!this.rh) return;
      try {
        const json = await this.$http
          .put(`/same/receivedbyrh/${this.rh}`, {
            destiny: "ESCRITURAÇÃO TÉRREO"
          })
          .then(r => r.data);
        this.$notify({
          title: `${json.name}, salvo com sucesso!`,
          type: "success",
          duration: 15000
        });
        this.rh = null;
        this.getList();
      } catch (err) {
        console.log(err);
        let msg = `Não foi encontrado este RH`;
        if (err.response && err.response.data && err.response.data.message)
          msg = err.response.data.message;
        this.$notify({
          title: msg,
          type: "error"
        });
      }
    },
    debounceInputRh: _.debounce(function() {
      setTimeout(() => {
        if (this.rh.length > 0) {
          this.receivedByRh();
        }
      }, 200);
    }, 500),
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400)
  }
};
</script>
