<template>
  <div style="width: 100%; height: 100%;">
    <div class="paper" v-for="(call, i) in items" :key="i">
      <v-layout row wrap style="padding-top: 120px;">
        <v-flex xs10>
          <!--<v-layout row wrap>
            <v-flex xs6>
              <img src="/img/logo_sus.gif" width="50px" height="50px" />
            </v-flex>
            <v-flex xs6>
              <img src="/img/guarulhos.png" width="80px" height="50px" />
            </v-flex>
          </v-layout>-->

          <v-layout row wrap class="my-2 px-3">
            <h3>Ficha de Atendimento</h3>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout row wrap class="px-3">
        <v-flex xs4>
          <div class="box-print-input">
            <span>RH:</span>
            {{call.patient.rh}}
          </div>
        </v-flex>

        <v-flex xs4>
          <div class="box-print-input">
            <span>DATA:</span>
            {{call.horary | formatDate}}
          </div>
        </v-flex>

        <v-flex xs4>
          <div class="box-print-input">
            <span>Origem:</span>
            {{call.origin}}
          </div>
        </v-flex>

        <!--<v-flex xs4>
					<div class="box-print-input">
						<span>Horário Chegada: </span> {{formatHorary(call.created_at)}}
					</div>
        </v-flex>-->
      </v-layout>

      <v-layout row wrap class="px-3 mt-2">
        <v-flex xs4>
          <div class="box-print-input">
            <span>RG:</span>
            {{call.patient.rg}}
          </div>
        </v-flex>

        <v-flex xs4>
          <div class="box-print-input">
            <span>SUS:</span>
            {{call.patient.sus}}
          </div>
        </v-flex>

        <v-flex xs4>
          <div class="box-print-input">
            <span>Esp.:</span>
            {{call.specialty.name}}
          </div>
        </v-flex>
      </v-layout>

      <v-layout row wrap class="px-3 mt-2">
        <v-flex xs4>
          <div class="box-print-input">
            <span>Operador:</span>
            {{call.user && call.user.name}}
          </div>
        </v-flex>
      </v-layout>

      <v-layout row wrap class="px-3 mt-2" align-center justify-center>
        <h3>IDENTIFICAÇÃO DO PACIENTE</h3>
      </v-layout>

      <v-layout row wrap class="px-3 mt-1">
        <v-flex xs12>
          <b>Nome:</b>
          {{call.patient.name}}
        </v-flex>
      </v-layout>

      <v-layout row wrap class="px-3 mt-1">
        <v-flex xs4>
          <b>Data de Nascimento:</b>
          {{call.patient.birthday | formatDate}}
        </v-flex>
        <v-flex xs4>
          <b>Idade:</b>
          {{age(call.patient.birthday)}}
        </v-flex>
        <v-flex xs4 class="text-xs-right">
          <b>Sexo:</b>
          {{call.patient.gender == 'M' && 'Masculino' || 'Feminino'}}
        </v-flex>
      </v-layout>

      <v-layout row wrap class="px-3 mt-1">
        <v-flex xs4>
          <b>Mãe:</b>
          {{call.patient.mother}}
        </v-flex>
        <v-flex xs4>
          <b>Responsavel:</b>
          {{call.patient.contact_name}}
        </v-flex>
        <v-flex xs4 class="text-xs-right">
          <b>Fone:</b>
          {{formatPhone(call.patient.phone)}}
        </v-flex>
      </v-layout>

      <v-layout row wrap class="px-3 mt-1">
        <v-flex xs2>
          <b>CEP:</b>
          {{call.patient.address && call.patient.address.zipcode}}
        </v-flex>
        <v-flex xs5>
          <b>Endereço:</b>
          {{call.patient.address && call.patient.address.street}}
        </v-flex>
        <v-flex xs2>
          <b>Nº:</b>
          {{call.patient.address && call.patient.address.street_number}}
        </v-flex>
        <v-flex xs3 class="text-xs-right">
          <b>Complemento:</b>
          {{call.patient.address && call.patient.address.complementary}}
        </v-flex>
      </v-layout>

      <v-layout row wrap class="px-3 mt-1">
        <v-flex xs5>
          <b>Bairro:</b>
          {{call.patient.address && call.patient.address.neighborhood}}
        </v-flex>
        <v-flex xs5>
          <b>Município:</b>
          {{call.patient.address && call.patient.address.city}}
        </v-flex>
        <v-flex xs2 class="text-xs-right">
          <b>Estado:</b>
          {{call.patient.address && call.patient.address.state}}
        </v-flex>
      </v-layout>
      <v-layout row wrap class="px-3 mt-1">
        <v-flex xs12>
          <b>OBS:</b>
          {{call.comments}}
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { maskJs } from "mask-js";
export default {
  data() {
    return {
      dialog: this.active,
      items: []
    };
  },
  created() {
    this.initialize();
  },
  beforeDestroy() {
    //document.getElementById("app").style.display = "flex"; //flex
  },
  methods: {
    initialize() {
      //console.log("initialize");
      setTimeout(() => {
        document.getElementsByClassName("v-content")[0].style.padding = 0;
        document.getElementsByClassName("v-toolbar")[0].style.display = 'none';
      }, 500);
      //flex
      this.getList();
    },
    close() {
      this.dialog = false;
    },
    getList() {
      //console.log('getList', this.$route.params.specialty_id);
      if (!this.$route.params.specialty_id || !this.$route.params.time) return;
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          horary: this.$route.params.time,
          specialty_id: this.$route.params.specialty_id,
          return: 0
        };

        this.$http
          .get(`/consultations/list`, { params })
          .then(response => response.data)
          .then(json => {
            this.loading = false;

            this.items = json.items;
          })
          .catch(() => (this.loading = false));
      });
    },
    formatHorary(date) {
      if (date) {
        return moment(date).format("HH:mm");
      } else return "";
    },
    age: date => Math.abs(moment(date).diff(moment(), "years")),
    formatPhone: p => maskJs("(99) 9999?9-9999", p)
  }
};
</script>

<style lang="css" scoped>
body {
  font-size: 12px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
}

.paper{
  width: 100%;
  height: 277mm;
}
</style>
