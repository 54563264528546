<template>
  <div>
    <!--<header-page />-->
    <v-container grid-list-md text-xs-center px-0>
      <notifications position="bottom center" />
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Lotes</v-toolbar-title>

            <v-divider class="mx-2" inset vertical></v-divider>
            de {{ filtro.start | formatDate }} até {{ filtro.end | formatDate }}
            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
              class="hidden-md-and-down"
              @click:append="getList()"
              v-on:keyup.enter="getList"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-menu
              v-model="filtro.menu"
              bottom
              left
              :close-on-content-click="false"
              max-width="400"
              min-width="400"
              offset-x
            >
              <v-btn outline slot="activator" color="primary">OPÇÕES</v-btn>

              <v-card>
                <v-card-title class="px-1 py-1">
                  OPÇÕES
                  <v-spacer></v-spacer>
                  <v-btn
                    outline
                    light
                    small
                    color="primary"
                    @click="
                      filtro.menu = true;
                      filtro = {};
                    "
                    >Limpar</v-btn
                  >
                </v-card-title>

                <v-layout row wrap>
                  <v-flex xs12 class="px-1">
                    <v-text-field
                      v-model="filtro.start"
                      type="date"
                      label="De"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 class="px-1">
                    <v-text-field
                      v-model="filtro.end"
                      type="date"
                      label="Até"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 class="px-1">
                    <select-custom
                      :url="
                        `/products/list_select?sortBy=name&office=${$route.params.office}`
                      "
                      v-model="filtro.product_id"
                      label="Produto"
                    />
                  </v-flex>
                  <v-flex xs12 class="px-1">
                    <v-select
                      :items="['Estoque', 'Farmácia Centro Cirúrgico']"
                      v-model="filtro.setor"
                      label="Setor"
                    />
                  </v-flex>
                </v-layout>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn flat @click="filtro.menu = false">Fechar</v-btn>
                  <v-btn color="primary" flat @click="goReport()"
                    >IMPRIMIR</v-btn
                  >
                  <v-btn color="primary" @click="filtrar()">FILTRAR</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.created_at | formatDateTime }}</td>
                <td>{{ props.item.code }}</td>
                <!-- <td>aqui -> {{ props.item }}</td> -->
                <td>{{ props.item.product && props.item.product.name }}</td>
                <td>{{ props.item.qty }}</td>
                <td>{{ props.item.qtdestoque }}</td>
                <td>{{ props.item.due_date | formatDate }}</td>
                <td class="text-xs-center px-0">
                  <v-icon
                    v-if="props.item.active"
                    small
                    @click="openLote(props.item)"
                    >fas fa-barcode</v-icon
                  >
                  <small v-if="props.item.status == 'processando'"
                    >processando</small
                  >
                </td>
                <td
                  class="text-xs-center px-0"
                  v-if="props.item.product.fractioned == 1"
                >
                  <v-tooltip top class="mr-2">
                    <v-icon
                      slot="activator"
                      @click="openEditVencimento(props.item)"
                      class="mr-2"
                      >edit</v-icon
                    >
                    <span>Alterar data de vencimento</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>

            <template slot="no-data"
              >Desculpe, nenhum dado para ser exibido!</template
            >
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination
              v-model="pagination.page"
              :length="pages"
            ></v-pagination>
          </div>

          <div v-if="editVencimentoForm.open">
            <edit-due-products
              :active="editVencimentoForm.open"
              :item="editVencimentoForm.item"
              :finish="onCloseEditVencimento"
            ></edit-due-products>
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog v-model="dialogCustomPrint" width="300">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Lote: {{ lote.code }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items></v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-container grid-list-md px-0 py-0>
            <v-layout wrap>
              <v-flex xs12 class="text-xs-center">
                <v-btn
                  @click="goExteral('/single_products/tags?lote_id=' + lote.id)"
                  >IMPRIMIR TUDO</v-btn
                >
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  type="number"
                  v-model="lote.stop_print"
                  label="A parti de"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  type="number"
                  v-model="lote.stop_print_qty"
                  label="Quantidade"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 class="pr-2">
                <v-subheader class="pl-0"
                  >Margem Direita da Impressao</v-subheader
                >
                <v-slider
                  v-model="margin"
                  thumb-label="always"
                  step="0.1"
                  max="10"
                ></v-slider>
              </v-flex>
              <v-flex xs12 class="text-xs-center">
                <v-btn
                  @click="
                    goExteral(
                      `/single_products/tags?lote_id=${lote.id}&stop_print=${lote.stop_print}&stop_print_qty=${lote.stop_print_qty}&margin=${margin}`
                    )
                  "
                  >IMPRIMIR CUSTOMIZADO</v-btn
                >
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
export default {
  data() {
    return {
      search: "",
      editVencimentoForm: { open: false, item: {} },
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "id"
      },
      estoque: [],
      items: [],
      columns: [
        { value: "id", text: "ID" },
        { value: "created_at", text: "Data" },
        { value: "code", text: "Código" },
        { value: "product", text: "Produto", sortable: false },
        { value: "qty", text: "QTD" },
        { value: "qtyEstoque", text: "QTD Estoque" },
        { value: "due_date", text: "Vencimento" },
        { value: "", text: "", sortable: false }
      ],
      loading: false,
      filtro: {
        menu: false,
        product_id: null,
        start: moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment()
          .endOf("month")
          .format("YYYY-MM-DD")
      },
      dialogCustomPrint: false,
      margin: 2,
      lote: {}
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          ...this.filtro,
          totalItems: undefined,
          menu: undefined,
          search: this.search,
          office: this.$route.params.office

          //active: 1,
        };
        this.$http
          .get(`/lotes/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
              console.log(this.items);
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
    filtrar() {
      this.filtro.menu = false;
      this.getList();
    },
    openEditVencimento(item) {
      console.log("Debug -> item", item);
      this.editVencimentoForm.open = true;
      this.editVencimentoForm.item = item;
    },
    onCloseEditVencimento(patient) {
      this.editVencimentoForm.open = false;
      this.getList();
    },
    goExteral(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    openLote(lote) {
      this.lote = lote;
      this.dialogCustomPrint = true;
    }
  }
};
</script>
