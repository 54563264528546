<template>
<div>
	<v-container grid-list-md text-xs-center px-0>
		<notifications position="bottom center" />
		<v-layout row wrap>
			<v-flex xs12>

				<v-toolbar flat color="white" class="elevation-1">
					<v-toolbar-title>Orçamentos</v-toolbar-title>
					<v-divider class="mx-2" inset vertical></v-divider>
					<v-spacer></v-spacer>
					<v-text-field
						v-model.lazy="search"
						append-icon="fa-search"
						label="Buscar"
						single-line
						hide-details
						class="hidden-sm-and-down"
					></v-text-field>
					<v-spacer></v-spacer>
					<v-btn v-if="userType != 1 && userType != 8" @click="quotationFormOpen({})" color="primary" dark class="mb-2">Novo</v-btn>
				</v-toolbar>

				<v-layout wrap row class="hidden-md-and-up">
					<v-flex xs12>
						<v-text-field
							v-model.lazy="search"
							append-icon="fa-search"
							label="Buscar"
							single-line
							hide-details
						></v-text-field>
					</v-flex>
				</v-layout>

				<v-data-table
					:headers="columns"
					:items="items"
      		        :pagination.sync="pagination"
					:total-items="pagination.totalItems"
      		        :loading="loading"
					hide-actions
					class="elevation-1"
				>
					<template slot="items" slot-scope="props">
						<td>{{ props.item.id }}</td>
						<td>{{ props.item.title }}</td>
						<td>{{ props.item.status_text }}</td>
						<td class="text-xs-center px-0">
							<v-icon v-if="props.item.status == '0' && userType != 1" small class="mr-2" @click="sendCompras(props.item.id)">
								check
							</v-icon>

							<v-icon v-if="((props.item.status == '0' && userType != 8 && userType != 1) || (props.item.status == 3 && userType == 8)) && userType != 1" small class="mr-2" @click="quotationFormOpen(props.item)" >
								edit
							</v-icon>

							<v-icon v-if="props.item.status == '0' && userType != 1" small @click="del(props.item.id)" >
								delete
							</v-icon>

							<!-- Administrador/diretoria -->
							<v-icon v-if="userType == 1 && props.item.status == 1" small class="mr-2" @click="quotationFormOpen(props.item, '1')">
								check
							</v-icon>
							<v-icon v-if="userType == 1 && props.item.status == 1" small class="mr-2" @click="disapprove(props.item.id)">
								close
							</v-icon>
							<!-- <v-icon v-if="userType == 1" small class="mr-2" @click="quotationFormOpen(props.item)">
								search
							</v-icon> -->

							<!-- Compras finaliza o orçamento e gera o resultado -->
							<v-icon v-if="props.item.status == 3 && userType == 8" small class="mr-2" @click="finish(props.item.id)">
								check
							</v-icon>

							<v-icon v-if="userType == 1 || props.item.status == 4 || [8, 10].includes(userType)" small class="mr-2" @click="quotationFormOpen(props.item)">
								search
							</v-icon>
							
							<v-icon v-if="props.item.status == 4" small @click="download(props.item.id)" >
								fa-download
							</v-icon>
						</td>
					</template>

					<template slot="no-data">
						Desculpe, nenhum dado para ser exibido!
					</template>
				</v-data-table>
				<div class="text-xs-center pt-2">
					<v-pagination v-model="pagination.page" :length="pages"></v-pagination>
				</div>

			</v-flex>
		</v-layout>
  </v-container>

	<div v-if="quotationFormActive.open">
		<order-form
			:active="quotationFormActive.open"
			:item="quotationFormActive.item"
			:finish="quotationFormClose"
		/>
	</div>
</div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';

export default {

  data() {
		return {
			userType: USER.type,
			valid: false,
			dialog: false,
			search: '',
      		pagination: {
				totalItems: 0,
				rowsPerPage: 10,
  				descending: true,
  				page: 1,
  				sortBy: 'id'
			},
			items: [],
			columns: [
				{ value: 'id', text: 'ID' },
				{ value: 'title', text: 'Nome', },
				{ value: 'phase_text', text: 'Estágio' },
				{ text: '', value: '', sortable: false }
			],
			quotationFormActive: { open: false, item: {} },
			loading: false,
		}
	},
	computed: {
		pages () {
			if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) return 0;
			return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
		}
	},
	watch: {
		dialog (val) {
			val || this.close()
		},
		"pagination.page": {
			handler () {
				this.getList();
			},
			deep: true
		},
		search: function () {
      this.debounceInput();
    }
	},
	created () {
		this.initialize();
	},
	methods: {
		initialize () {
			console.log("Nivel de usuario", USER.type);
		},
		getList() {
			this.loading = true
			return new Promise((resolve, reject) => {
				const params = {
					...this.pagination,
					totalItems: undefined,
					search: this.search
				};
				this.$http.get(`/orders/list`, {params})
				.then(response => response.data).then(json => {
					setTimeout(() => {
           				this.loading = false;
						this.items = json.items;
						this.pagination.totalItems = json.total;

						console.log("Items", this.items);
          			}, 1000);
				}).catch(() => this.loading = false);
			});
		},
    	quotationFormOpen (item, approve) {
			if (approve != undefined && approve == '1') {
				item.toApprove = 1;
			}

			this.quotationFormActive.open = true;
			this.quotationFormActive.item = item;
		},
    	quotationFormClose () {
			this.quotationFormActive.open = false;
			this.getList();
		},
		orderFinishedOpen (item) {
			this.orderFinishedActive.open = true;
			this.orderFinishedActive.item = item;
		},
		orderFinishedClose() {
			this.orderFinishedActive.open = false;
		},
		// quotationNextStep (item) {
		// 	console.log('proximo step', item);
		// 	this.$http.post(`/order/${item.id}/next-step`).then((response) => {
		// 		console.log('Resultado: ', response);
		// 		if (response.data['success'] == true) {
		// 			this.getList();
		// 			this.$notify({ title: `Estágio atualizado com sucesso!`, type: 'success' });
		// 			return;
		// 		}
		// 		this.$notify({ title: `Houve um erro ao atualizar o estágio do orçamento`, type: 'error' });
		// 	});
		// },
    	download(id){
            axios({
                url: `/orders/generate-pdf/${id}`,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', `Orcamento_/${id}.pdf`);
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        },
		del(id) {
			if (confirm('Deseja realmente excluir este orçamento?')) {
				this.$http.delete(`/orders/${id}`)
				.then(response => {
					this.$notify({title: `Banco Excluido!`, type: 'success'});
					this.getList();
				});
			}
		},
		sendCompras(id) {
			if ( confirm('Deseja realmente enviar essa cotação para aprovação?') ) {
				this.$http.post(`/orders/${id}/send-compras`)
					.then(respone => {
						this.$notify({ title: `Orçamento enviado para compras!`, type: 'success' });
						this.getList();
					})
					.catch(err => {
						this.$notify({ title: 'Oops. Houve um erro ao enviar para compras.', type: 'error' });
					});
			}
		},

		// Funcoes do administrador
		approve(id) {
			if ( USER.type == 1 && confirm('Confirma a aprovação do orçamento?') ) {
				// faz um post aprovando
			}
		},
		disapprove(id) {
			if ( USER.type == 1 && confirm('Confirma a reprovação do orçamento?') ) {
				this.$http.post(`/orders/${id}/disapprove`)
					.then(response => {
						this.$notify({ title: 'Orçamento reprovado!', type: 'success' });
						this.getList();
					})
					.catch(err => {
						this.$notify({ title: 'Houve um erro ao reprovar o orçamento', type: 'error' })
					});
			}
		},
		finish(id) {
			if (confirm('Deseja realmente finalizar este orçamento?')) {
				this.$http.post(`/orders/${id}/finish`)
					.then(response => {
						this.$notify({
							title: `Orçamento ${id} finalizado com sucesso!`,
							type: 'success',
						});
						this.getList();
					})
					.catch(err => {
						this.$notify({
							duration: 1500,
							title: `Houve um erro ao finalizar o orçamento ${id}.`,
							type: 'error'
						 });
					});					
			}
		},
		debounceInput: _.debounce(function () {
      		setTimeout(() => {this.getList();}, 200);
		}, 400)
	}
}
</script>
