<template>
  <div>
    <v-container grid-list-md text-xs-center px-0>
      <notifications position="bottom center" />
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title v-if="product">{{product.name}} - Estoque</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
              class="hidden-md-and-down"
              @click:append="getList()"
              v-on:keyup.enter="getList"
            ></v-text-field>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.lote && props.item.lote.code }}</td>
                <td>{{ props.item.lote && props.item.lote.due_date | formatDate }}</td>
                <td>{{ props.item.used ? 'SIM' : 'NÃO'}}</td>
                <td>{{ props.item.sector && props.item.sector.name}}</td>
                <td class="text-xs-center px-1">
                  <v-icon small @click="goExternal('/single_products/tag/' + props.item.id)">fas fa-barcode</v-icon>
                </td>
              </tr>
            </template>

            <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      valid: false,
      search: "",
      product: null,
      pagination: {
        totalItems: 0,
        rowsPerPage: 50,
        descending: true,
        page: 1,
        sortBy: "id"
      },
      items: [],
      estoques: [],
      columns: [
        { value: "id", text: "Código" },
        { value: "lote.code", text: "Lote", sortable: false },
        { value: "lote.due_date", text: "Vencimento", sortable: false },
        { value: "used", text: "Retirado", sortable: false },
        { value: "sector", text: "Setor", sortable: false },
        { text: "", value: "", sortable: false }
      ],
      loading: false
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getItem();
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          search: this.search
        };
        this.$http
          .get(`/single_products/list?product_id=${this.$route.params.id}`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    getItem(){
      this.$http
          .get(`/products/show/${this.$route.params.id}`)
          .then(response => response.data)
          .then(json => {
            this.product = json.product;
          })
          .catch(() => (this.loading = false));
    },
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
    goExternal(url) {
      var win = window.open(url, "_blank");
      win.focus();
    }
  }
};
</script>
