<template>
  <div>
    <!--<header-page />-->
    <v-container grid-list-md text-xs-center px-0>
      <notifications position="bottom center" />
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Usuários</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-text-field
              class="mt-2"
              type="date"
              v-model="birthday"
              label="Data Nascimento"
              clearable
              v-on:keyup.enter="getList"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
              class="hidden-sm-and-down"
              @click:append="getList()"
              v-on:keyup.enter="getList"
            ></v-text-field>
            <v-btn
              v-if="!user.modules.includes('VISUALIZAR_PACIENTES')"
              color="primary"
              dark
              class="mb-2"
              @click="openPatientForm({})"
              >Novo</v-btn
            >
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.rh }}</td>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.birthday | formatDate }}</td>
                <td>{{ props.item.mother }}</td>
                <td>{{ props.item.rg }}</td>
                <td>{{ props.item.address && props.item.address.city }}</td>
                <td>{{ formatPhone(props.item.phone) }}</td>
                <td class="text-xs-center px-0">
                  <v-tooltip
                    top
                    class="mr-2"
                    v-if="!user.modules.includes('VISUALIZAR_PACIENTES')"
                  >
                    <v-icon
                      slot="activator"
                      small
                      @click="goExternal('/patients/' + props.item.id + '/tag')"
                      >fas fa-barcode</v-icon
                    >
                    <span>Imprimir Etiqueta</span>
                  </v-tooltip>

                  <v-tooltip top class="mr-2">
                    <v-icon
                      slot="activator"
                      small
                      @click="openPatientView(props.item.id)"
                      >fas fa-user</v-icon
                    >
                    <span>Perfil do Usuário</span>
                  </v-tooltip>

                  <v-tooltip
                    top
                    class="mr-2"
                    v-if="user.modules.includes('UNIR_CADASTROS')"
                  >
                    <v-icon
                      slot="activator"
                      small
                      @click="patientUnionOpen(props.item)"
                      >fas fa-random</v-icon
                    >
                    <span>Unir Cadastro</span>
                  </v-tooltip>

                  <v-icon
                    v-if="!user.modules.includes('VISUALIZAR_PACIENTES')"
                    small
                    @click="openPatientForm(props.item)"
                    class="mr-2"
                    >edit</v-icon
                  >

                  <!--<v-icon v-if="user.type != 14" small @click="del(props.item.id)">delete</v-icon>-->
                </td>
              </tr>
            </template>

            <template slot="no-data"
              >Desculpe, nenhum dado para ser exibido!</template
            >
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination
              v-model="pagination.page"
              :length="pages"
              total-visible="16"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <div v-if="patientFormActive.open">
      <patient-form
        :active="patientFormActive.open"
        :item="patientFormActive.item"
        :finish="onClosePatientForm"
      ></patient-form>
    </div>

    <div v-if="patientViewActive.open">
      <patient-view
        :active="patientViewActive.open"
        :patient_id="patientViewActive.id"
        :finish="() => (patientViewActive.open = false)"
      ></patient-view>
    </div>

    <div v-if="patientUnionActive.open">
      <patient-union
        :active="patientUnionActive.open"
        :item="patientUnionActive.item"
        :finish="patientUnionClose"
      ></patient-union>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import { maskJs } from "mask-js";
export default {
  data() {
    return {
      search: "",
      birthday: null,
      pagination: {
        totalItems: 0,
        rowsPerPage: 5,
        descending: false,
        page: 1,
        sortBy: "rh"
      },
      items: [],
      columns: [
        { value: "rh", text: "RH" },
        { value: "name", text: "Nome" },
        { value: "birthday", text: "Nascimento" },
        { value: "mother", text: "Mãe" },
        { value: "rg", text: "RG" },
        { value: "address", text: "Município", sortable: false },
        { value: "phone", text: "Fone" },
        { text: "", value: "", sortable: false }
      ],
      patientFormActive: { open: false, item: {} },
      patientViewActive: { open: false },
      patientUnionActive: { open: false, item: {} },
      loading: false,
      user: USER
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true
    }
    /*search: function() {
      this.debounceInput();
    },*/
    /*birthday: function() {
      this.debounceInput();
    }*/
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      //console.log('this.notify', this.$notify);
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          search: this.search,
          birthday: this.birthday
        };
        this.$http
          .get(`/patients/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    del(id) {
      if (confirm("Deseja realmente excluir este usuário?")) {
        this.$http.delete(`/patients/${id}`).then(response => {
          this.$notify({
            title: `Usuário Excluido!`,
            type: "success"
          });
          this.getList();
        });
      }
    },
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
    goExternal(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    formatPhone(p) {
      if (!p) return null;
      return maskJs("(99) 9999?9-9999", p);
    },
    openPatientForm(item) {
      this.patientFormActive.open = true;
      this.patientFormActive.item = item;
    },
    onClosePatientForm(patient) {
      this.patientFormActive.open = false;
      this.getList();
    },
    openPatientView(id) {
      this.patientViewActive.id = id;
      this.patientViewActive.open = true;
    },
    patientUnionOpen(item) {
      this.patientUnionActive.open = true;
      this.patientUnionActive.item = item;
    },
    patientUnionClose() {
      this.patientUnionActive.open = false;
      this.getList();
    }
  }
};
</script>
