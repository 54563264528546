<template>
  <div>
    <!--<header-page />-->
    <v-container grid-list-md text-xs-center px-0>
      <v-layout row wrap>
        <v-flex xs12 class="px-0 py-0">
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>
              <v-btn
                icon
                flat
                small
                color="primary"
                dark
                class="mb-2"
                @click="getList()"
              >
                <v-icon small>fas fa-sync</v-icon> </v-btn
              >Atendimentos
            </v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <div v-if="USER.type != 3">
              <!--<v-icon style="vertical-align: middle;" @click="prev()">arrow_back_ios</v-icon>
              <span>{{formatDate(time)}}</span>
              <v-icon
                v-if="formatDate(time) != today()"
                style="vertical-align: middle;"
                @click="next()"
              >arrow_forward_ios</v-icon>-->

              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="time"
                    type="date"
                    readonly
                    prepend-icon="event"
                    v-on="on"
                    label="Data"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="time"
                  no-title
                  locale="pt"
                  :max="today"
                  @input="
                    menu1 = false;
                    getList();
                  "
                ></v-date-picker>
              </v-menu>
            </div>
            <div v-else>Últimas 24 Horas</div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
              class="hidden-sm-and-down"
              @click:append="getList()"
              v-on:keyup.enter="getList"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              v-if="
                USER.type != 3 &&
                !USER.modules.includes('VISUALIZAR_ATENDIMENTOS') &&
                USER.modules.includes('TRIAGEM SENHAS')
              "
              color="primary"
              dark
              class="mb-2"
              @click="openEvaluation({})"
              >Novo</v-btn
            >
            <v-btn
              v-if="
                USER.type != 3 &&
                !USER.modules.includes('VISUALIZAR_ATENDIMENTOS') &&
                !USER.modules.includes('TRIAGEM SENHAS')
              "
              color="primary"
              dark
              class="mb-2"
              @click="openCall({})"
              >Novo</v-btn
            >
            <div
              v-if="
                USER.type == 3 &&
                !USER.modules.includes('VISUALIZAR_ATENDIMENTOS')
              "
            >
              SENHA: {{ password.number }} | {{ place }}
              <v-btn
                flat
                icon
                @click="setPassword(null)"
                style="vertical-align: middle"
              >
                <v-icon>far fa-arrow-alt-circle-up</v-icon>
              </v-btn>
            </div>
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr
                :class="{
                  'blue lighten-1': props.item.rate == 'Azul',
                  'red lighten-1': props.item.rate == 'Vermelho',
                  'green lighten-1': props.item.rate == 'Verde',
                  'yellow lighten-1': props.item.rate == 'Amarelo',
                  'orange lighten-1': props.item.rate == 'Laranja',
                }"
              >
                <td>
                  <v-tooltip top v-if="props.item.doctor_done">
                    <v-icon slot="activator" small>fas fa-check-double</v-icon>
                    <span>Fez Avaliação Médica</span>
                  </v-tooltip>
                  {{ props.item.id }}
                </td>
                <td>{{ props.item.patient && props.item.patient.rh }}</td>
                <td>{{ props.item.password_type }}{{ props.item.password }}</td>
                <td justify-center>
                  <v-icon v-if="props.item.priority">fas fa-check</v-icon>
                </td>
                <td justify-center>
                  <v-icon
                    v-if="props.item.absence"
                    @click="setAbsence(props.item, false)"
                    >check_box</v-icon
                  >
                  <v-icon v-else @click="setAbsence(props.item, true)"
                    >check_box_outline_blank</v-icon
                  >
                </td>
                <td>
                  <span v-if="props.item.patient">{{
                    props.item.patient.name
                  }}</span>
                  <span v-else>{{ props.item.name }}</span>
                </td>
                <td>
                  {{
                    props.item.patient &&
                    props.item.patient.address &&
                    props.item.patient.address.city
                  }}
                </td>
                <td>{{ props.item.specialty && props.item.specialty.name }}</td>
                <td>{{ formatHorary(props.item.created_at) }}</td>
                <td class="text-xs-center px-0">
                  <v-menu
                    offset-y
                    v-if="!USER.modules.includes('VISUALIZAR_ATENDIMENTOS')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon small>fas fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-tile
                        v-if="
                          props.item.password &&
                          props.item.patient_id &&
                          USER.type == 3
                        "
                        @click="setPassword(props.item)"
                      >
                        <v-list-tile-avatar>
                          <v-icon>fas fa-sort-numeric-up</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>Chamar Paciente</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile
                        v-if="USER.type == 3"
                        @click="openPrescription(props.item)"
                      >
                        <v-list-tile-avatar>
                          <v-icon>fas fa-prescription-bottle-alt</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>Avaliação Médica</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile
                        v-if="props.item.call_doctor_id"
                        @click="
                          goExternal(
                            '/calls/' +
                              props.item.id +
                              '/print_call_doctor'
                          )
                        "
                      >
                        <v-list-tile-avatar>
                          <v-icon>fas fa-print</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title
                          >Imprimir Avaliação Médica</v-list-tile-title
                        >
                      </v-list-tile>
                      <v-list-tile
                        v-if="props.item.patient_id"
                        @click="openCallPrint(props.item)"
                      >
                        <v-list-tile-avatar>
                          <v-icon>fas fa-print</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>Imprimir Ficha</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile
                        v-if="props.item.patient_id"
                        @click="
                          goExternal('/calls/' + props.item.id + '/tag/patient')
                        "
                      >
                        <v-list-tile-avatar>
                          <v-icon>fas fa-user-tag</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>Etiqueta Paciente</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile
                        v-if="props.item.patient_id"
                        @click="
                          goExternal('/calls/' + props.item.id + '/tag/resp')
                        "
                      >
                        <v-list-tile-avatar>
                          <v-icon>fas fa-tag</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title
                          >Etiqueta Acompanhante</v-list-tile-title
                        >
                      </v-list-tile>

                      <v-list-tile v-else @click="openEvaluation(props.item)">
                        <v-list-tile-avatar>
                          <v-icon>fas fa-notes-medical</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>Triagem</v-list-tile-title>
                      </v-list-tile>

                      <v-list-tile
                        v-if="props.item.patient_id"
                        @click="openDeclarePrint(props.item)"
                      >
                        <v-list-tile-avatar>
                          <v-icon>fas fa-clock</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title
                          >Declaração de Horas</v-list-tile-title
                        >
                      </v-list-tile>
                      <v-list-tile
                        v-if="props.item.patient_id && props.item.bo"
                        @click="boPrintOpen(props.item)"
                      >
                        <v-list-tile-avatar>
                          <v-icon>fas fa-print</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title
                          >Ocorrência Policial</v-list-tile-title
                        >
                      </v-list-tile>
                      <v-list-tile @click="openCall(props.item)">
                        <v-list-tile-avatar>
                          <v-icon>fa-edit</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>Ficha</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>

            <template slot="no-data"
              >Desculpe, nenhum dado para ser exibido!</template
            >
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination
              v-model="pagination.page"
              :length="pages"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <div v-if="callActive.open">
      <call
        :active="callActive.open"
        :item="callActive.item"
        :finish="onCloseCallActive"
      ></call>
    </div>

    <div v-if="callPrint.open">
      <call-print
        :active="callPrint.open"
        :item="callPrint.item"
        :finish="onCloseCallPrint"
      ></call-print>
    </div>

    <div v-if="declarePrint.open">
      <declare-print
        :active="declarePrint.open"
        :item="declarePrint.item"
        :finish="onCloseDeclarePrint"
      ></declare-print>
    </div>

    <div v-if="boPrint.open">
      <bo-print
        :active="boPrint.open"
        :item="boPrint.item"
        :finish="boPrintClose"
      />
    </div>

    <div v-if="evaluation.open">
      <evaluation
        :active="evaluation.open"
        :item="evaluation.item"
        :finish="onCloseEvaluation"
      ></evaluation>
    </div>

    <div v-if="prescription.open">
      <prescription
        :active="prescription.open"
        :item="prescription.item"
        :finish="onClosePrescription"
      ></prescription>
    </div>

    <v-dialog
      v-model="dialogPlace.open"
      scrollable
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">Digite o número da sua sala</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="dialogPlace.valid" xs12>
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="dialogPlace.place"
                  label="SALA"
                  :rules="[(v) => !!v || 'SALA é obrigatório!']"
                  required
                  v-on:keypress="isNumber"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="savePlace()"
            :disabled="!dialogPlace.valid"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { maskJs } from "mask-js";
import io from "socket.io-client";
export default {
  data() {
    return {
      USER: USER,
      search: "",
      callActive: { open: false },
      callPrint: { open: false },
      declarePrint: { open: false },
      boPrint: { open: false },
      evaluation: { open: false },
      prescription: { open: false },
      time: moment().format("YYYY-MM-DD"),
      menu1: false,
      password: {},
      doctors_absent: [],
      loading: false,
      dialogPlace: { open: false, valid: false, place: null },
      place: localStorage.getItem("place"),
      today: moment().format("YYYY-MM-DD"),
      pagination: {
        totalItems: 0,
        rowsPerPage: 30,
        descending: false,
        page: 1,
        sortBy: "created_at",
      },
      items: [],
      columns: [
        { value: "id", text: "F.A", sortable: false },
        { value: "rh", text: "R.H", sortable: false },
        { value: "password", text: "Senha" },
        { value: "priority", text: "Pri./Ido.", sortable: false },
        { value: "absence", text: "Aus.", sortable: false },
        { value: "patient", text: "Nome", sortable: false },
        { value: "patient.city", text: "Município", sortable: false },
        { value: "specialty", text: "Especialidade", sortable: false },
        { value: "created_at", text: "Horário" },
        { text: "Opções", value: "", sortable: false },
      ],
    };
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      //console.log('this.notify', this.$notify);
      //this.getList();

      this.$root.$on("call_password", (obj) => {
        console.log("call_password", obj);
        const item = this.items.find(
          (o) =>
            o.password_type == obj.password_type && o.password == obj.password
        );
        if (item) {
          this.openCall(item);
        } else if (obj.type == "Triagem") {
          this.openEvaluation({
            password_type: obj.password_type,
            password: obj.password,
          });
        }
      });

      this.getPassword();
      if (USER.type == 3 && window.location.hostname != "localhost") {
        let port = ":4949";
        if (window.location.hostname == "hmpb.hoomweb.com") port = ":4848";
        const socket = io(window.location.hostname + port);

        socket.on("update", (data) => {
          this.getPassword();
        });

        socket.on("doctors_absent", (data) => {
          console.log("doctors_absent", data);
          this.doctors_absent = data.doctors_absent;
        });
      }
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          time: this.time,
          search: this.search,
        };
        this.$http
          .get(`/calls/list`, { params })
          .then((response) => response.data)
          .then((json) => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
            resolve(json.items);
          })
          .catch(() => (this.loading = false));
      });
    },
    prev() {
      this.time.subtract(1, "days");
      this.getList();
    },
    next() {
      this.time.add(1, "days");
      this.getList();
    },
    getPassword() {
      if (USER.type == 3) {
        this.$http
          .get(`/passwords/type/Consultório`)
          .then((response) => response.data)
          .then((json) => {
            this.password = json;
          });
      }
    },
    formatDate: (date) => moment(date).format("DD/MM/YYYY"),
    //today: () => moment().format("DD/MM/YYYY"),
    openCall(item) {
      this.callActive.open = true;
      this.callActive.item = item;
    },
    onCloseCallActive() {
      this.callActive.open = false;
      this.getList();
    },
    openEvaluation(item) {
      this.evaluation.open = true;
      this.evaluation.item = item;
    },
    onCloseEvaluation(item, openPrint) {
      this.evaluation.open = false;
      if (openPrint) {
        this.openCallPrint(item);
      }

      this.getList();
    },
    openPrescription(item) {
      this.prescription.open = true;
      this.prescription.item = item;
    },
    onClosePrescription(item, openPrint) {
      this.prescription.open = false;
      if (openPrint) {
        this.openCallPrint(item);
      }

      this.getList();
    },
    openCallPrint(item) {
      window.scrollTo(0, 0);
      this.callPrint.open = true;
      this.callPrint.item = item;
    },
    onCloseCallPrint() {
      this.callPrint.open = false;
    },
    openDeclarePrint(item) {
      window.scrollTo(0, 0);
      this.declarePrint.open = true;
      this.declarePrint.item = item;
    },
    onCloseDeclarePrint() {
      this.declarePrint.open = false;
    },
    boPrintOpen(item) {
      window.scrollTo(0, 0);
      this.boPrint.open = true;
      this.boPrint.item = item;
    },
    boPrintClose() {
      this.boPrint.open = false;
    },
    formatHorary(date) {
      if (date) {
        return moment(date).format("HH:mm");
      } else return "";
    },
    setAbsence(item, b) {
      item.absence = b;
      this.$http.put(`/calls/${item.id}`, item).then((response) => {
        let title = `Marcado como ausente!`;
        if (!b) {
          title = `Removido a marcação de ausente`;
        }
        this.$notify({ title, type: "success" });
      });
    },

    setPassword(item) {
      if (!this.place) {
        this.dialogPlace.open = true;
        return;
      }

      const passwordError = (err) => {
        this.$notify({
          duration: 15000,
          title: `Erro ao Chamar Senha!`,
          text: err.response,
          type: "error",
        });
      };

      if (item != null) {
        if (
          confirm(
            `Deseja realmente chamar a senha ${item.password_type}${item.password}?`
          )
        ) {
          this.password.place = this.place;
          this.password.number = item.password;
          this.password.password_type = item.password_type;
          this.$http
            .put(`/passwords/${this.password.id}/next_doctor`, this.password)
            .then((response) => {
              this.openPrescription(item);
              this.$notify({
                title: `Senha Chamada com sucesso!`,
                type: "success",
              });
            })
            .catch(passwordError);
        }

        return;
      }

      if (confirm(`Deseja realmente chamar a próxima senha?`)) {
        this.getList()
          .then((arr) => {
            let aux = arr.filter(
              (o) =>
                !o.medication_type &&
                o.rate == "Vermelho" &&
                o.password != this.password.number &&
                !o.absence
            );
            let filter = _.sortBy(aux, (o) => parseInt(o.password));
            //console.log(filter);

            if (filter.length == 0) {
              let aux = arr.filter(
                (o) =>
                  !o.medication_type &&
                  o.rate == "Amarelo" &&
                  o.password != this.password.number &&
                  !o.absence
              );
              filter = _.sortBy(aux, (o) => parseInt(o.password));
            }

            if (filter.length == 0) {
              let aux = arr.filter((o) => {
                const cond1 =
                  o.medication_type == "Medicação Observação" &&
                  moment(moment())
                    .subtract(o.return_minutes, "minutes")
                    .isAfter(o.doctor_horary, "minutes");
                return (
                  !o.doctor_done &&
                  (cond1 ||
                    (o.medication_type == "Medicação Retorno" &&
                      o.nursing_id)) &&
                  o.doctor_id == USER.id &&
                  o.password != this.password.number &&
                  !o.absence
                );
              });

              filter = _.sortBy(aux, (o) => parseInt(o.password));
            }

            if (filter.length == 0 && this.doctors_absent.length > 0) {
              let aux = arr.filter((o) => {
                const cond1 =
                  o.medication_type == "Medicação Observação" &&
                  moment(moment())
                    .subtract(o.return_minutes, "minutes")
                    .isAfter(o.doctor_horary, "minutes");
                return (
                  !o.doctor_done &&
                  (cond1 ||
                    (o.medication_type == "Medicação Retorno" &&
                      o.nursing_id)) &&
                  this.doctors_absent.includes(o.doctor_id) &&
                  o.password != this.password.number &&
                  !o.absence
                );
              });

              filter = _.sortBy(aux, (o) => parseInt(o.password));
            }

            if (filter.length == 0) {
              filter = _.sortBy(
                arr.filter(
                  (o) =>
                    !o.medication_type &&
                    o.password != this.password.number &&
                    !o.absence
                ),
                (o) => parseInt(o.password)
              );
            }

            if (filter.length == 0) {
              this.$notify({
                title: `Não há paciente para ser chamado!`,
                type: "error",
              });
              return;
            }
            this.password.place = this.place;
            this.password.number = filter[0].password;
            this.password.password_type = filter[0].password_type;
            this.$http
              .put(`/passwords/${this.password.id}/next_doctor`, this.password)
              .then((response) => {
                this.openPrescription(filter[0]);
                this.$notify({
                  title: `Senha Chamada com sucesso!`,
                  type: "success",
                });
              })
              .catch(passwordError);
          })
          .catch(passwordError);
      }
    },
    goExternal(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    savePlace() {
      this.dialogPlace.open = false;
      this.place = "SALA " + this.dialogPlace.place;
      localStorage.setItem("place", this.place);
    },
    debounceInput: _.debounce(function () {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
