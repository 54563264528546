<template>
<div>
	<!--<header-page />-->
	<v-container grid-list-md text-xs-center px-0>
		<notifications position="bottom center" />
		<v-layout row wrap>
			<v-flex xs12>

				<v-toolbar flat color="white" class="elevation-1">
					<v-toolbar-title>Fornecedores/Funcionários</v-toolbar-title>
					<v-divider class="mx-2" inset vertical></v-divider>
					<v-spacer></v-spacer>
					<v-text-field
						v-model.lazy="search"
						append-icon="fa-search"
						label="Buscar"
						single-line
						hide-details
						class="hidden-sm-and-down"
            @click:append="getList()"
              v-on:keyup.enter="getList"
					></v-text-field>
					<v-spacer></v-spacer>
					<v-btn @click="openProviderForm({})" color="primary" dark class="mb-2">Novo</v-btn>
				</v-toolbar>

				<v-data-table
					:headers="columns"
					:items="items"
      		:pagination.sync="pagination"
					:total-items="pagination.totalItems"
      		:loading="loading"
					hide-actions
					class="elevation-1"
				>
					<template slot="items" slot-scope="props">
						<td>{{ props.item.id }}</td>
						<td>{{ props.item.name }}</td>
            <td>{{ props.item.employee ? 'SIM' : 'NÃO' }}</td>
						<td class="text-xs-center px-0">
							<v-icon small class="mr-2" @click="openProviderForm(props.item)" >
								edit
							</v-icon>
							<v-icon small @click="del(props.item.id)" >
								delete
							</v-icon>
						</td>
					</template>

					<template slot="no-data">
						Desculpe, nenhum dado para ser exibido!
					</template>
				</v-data-table>
				<div class="text-xs-center pt-2">
					<v-pagination v-model="pagination.page" :length="pages"></v-pagination>
				</div>

			</v-flex>
		</v-layout>
  </v-container>

	<div v-if="providerFormActive.open">
		<provider-form
			:active="providerFormActive.open"
			:item="providerFormActive.item"
			:finish="onCloseProviderForm"
		></provider-form>
	</div>
</div>
</template>

<script>
import _ from 'lodash';
export default {

  data() {
		return {
			dialog: false,
			search: '',
      pagination: {
				totalItems: 0,
				rowsPerPage: 10,
  			descending: true,
  			page: 1,
  			sortBy: 'id'
			},
			items: [],
			columns: [
				{ value: 'id', text: 'ID' },
        { value: 'name', text: 'Nome', },
        { value: 'employee', text: 'Funcionário', },
				{ text: '', value: '', sortable: false }
			],
			providerFormActive: { open: false, item: {} },
			loading: false,
		}
	},
	computed: {
		pages () {
			if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) return 0

			return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
		}
	},
	watch: {
		dialog (val) {
			val || this.close()
		},
		"pagination.page": {
			handler () {
				this.getList();
			},
			deep: true
		},
	},
	created () {
		this.initialize();
	},
	methods: {
		initialize () {
		},
		getList() {
			this.loading = true
			return new Promise((resolve, reject) => {
				const params = {
					...this.pagination,
					totalItems: undefined,
					search: this.search
				};
				this.$http.get(`/providers/list`, {params})
				.then(response => response.data).then(json => {
					setTimeout(() => {
            this.loading = false;

						this.items = json.items;
						this.pagination.totalItems = json.total;
          }, 1000);
				}).catch(() => this.loading = false);
			});
		},
		openProviderForm (item) {
			this.providerFormActive.open = true;
			this.providerFormActive.item = item;
		},
		onCloseProviderForm () {
			this.providerFormActive.open = false;
			this.getList();
		},
		del(id) {
			if (confirm('Deseja realmente excluir este Fornecedor/Funcionário?')) {
				this.$http.delete(`/providers/${id}`)
				.then(response => {
					this.$notify({
						title: `Fornecedor/Funcionário Excluido!`,
						type: 'success',
					});
					this.getList();
				});
			}
		},
		debounceInput: _.debounce(function () {
      setTimeout(() => {
        this.getList();
      }, 200);
		}, 400)
	}
}
</script>
