<template>
  <div>
    <v-container grid-list-md text-xs-center px-0>
      <v-layout row wrap v-if="$route.params.specialty_id">
        <v-flex>
          <v-card>
            <v-toolbar flat color="white" class="elevation-1">
              <v-toolbar-title>Consultas</v-toolbar-title>
              <v-divider class="mx-2" inset vertical></v-divider>
              {{ specialty && specialty.name }}
              <v-divider class="mx-2" inset vertical></v-divider>
              <div>
                <!--<v-icon style="vertical-align: middle;" @click="prev()">arrow_back_ios</v-icon>
                <span>{{formatDate(time)}}</span>
                <v-icon style="vertical-align: middle;" @click="next()">arrow_forward_ios</v-icon>-->
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="time"
                      label="Data"
                      type="date"
                      readonly
                      prepend-icon="event"
                      v-on="on"
                      :loading="loading"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="time"
                    no-title
                    locale="pt"
                    @input="
                      menu1 = false;
                      getList();
                    "
                  ></v-date-picker>
                </v-menu>
              </div>
              <v-spacer></v-spacer>
              <v-menu
                v-if="!user.modules.includes('CONSULTAS_CONFIRMACAO')"
                v-model="menuFilter.menu"
                bottom
                left
                :close-on-content-click="false"
                max-width="400"
                min-width="400"
                offset-x
              >
                <v-btn outline slot="activator" color="primary">IMPRIMIR</v-btn>

                <v-card>
                  <v-card-title class="px-1 py-1">
                    IMPRIMIR
                    <v-spacer></v-spacer>
                    <v-btn
                      outline
                      light
                      small
                      color="primary"
                      @click="
                        menuFilter.menu = true;
                        filtro = {};
                      "
                      >Limpar</v-btn
                    >
                  </v-card-title>

                  <v-layout row wrap>
                    <v-flex xs12 class="px-1">
                      <v-text-field
                        v-model="filtro.start"
                        type="date"
                        label="De"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 class="px-1">
                      <v-text-field
                        v-model="filtro.end"
                        type="date"
                        label="Até"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 class="px-1">
                      <select-custom
                        :url="
                          '/users/list?type=3sortBy=name&specialty_id=' +
                            $route.params.specialty_id
                        "
                        v-model="filtro.doctor_id"
                        label="Médico"
                      />
                    </v-flex>
                    <v-flex xs12 class="px-1">
                      <v-select
                        v-model="filtro.origin"
                        :items="[
                          'RESIDÊNCIA',
                          'SAMU',
                          'UBS',
                          'AMBULÂNCIA',
                          'OUTROS'
                        ]"
                        label="Origem"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 class="px-1">
                      <v-select
                        v-model="filtro.arrival"
                        :items="[
                          { value: null, text: 'Selecionar' },
                          { value: 1, text: 'SIM' },
                          { value: 0, text: 'NÃO' }
                        ]"
                        label="Presença"
                      ></v-select>
                    </v-flex>
                  </v-layout>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn flat @click="menuFilter.menu = false">Fechar</v-btn>
                    <v-btn color="primary" flat @click="goReport()"
                      >IMPRIMIR</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-menu>
              <v-btn
                v-if="!user.modules.includes('CONSULTAS_CONFIRMACAO')"
                @click="
                  doctorChangeDateOpen({
                    specialty_id: parseInt($route.params.specialty_id),
                    start: time
                  })
                "
                color="primary"
                class="mb-2 mr-1"
                outline
                >Alterar Agenda</v-btn
              >
              <v-btn
                v-if="!user.modules.includes('CONSULTAS_CONFIRMACAO')"
                @click="
                  consultationFormOpen({
                    specialty_id: parseInt($route.params.specialty_id)
                  })
                "
                color="primary"
                dark
                class="mb-2"
                >Novo</v-btn
              >
            </v-toolbar>
            <v-card-text>
              <table class="table-custom">
                <thead>
                  <tr>
                    <th style="width: 10%;">Horário</th>
                    <th style="width: 52px;">Chegada</th>
                    <th>Nome do Paciente</th>
                    <th style="width: 200px;">Médico</th>
                    <th style="width: 300px;">Status Ligação</th>
                    <th style="text-align: right; width: 20%">
                      <v-btn
                        v-if="!user.modules.includes('CONSULTAS_CONFIRMACAO')"
                        @click="
                          goExternal(
                            `/consultations_fa/${$route.params.specialty_id}/${time}`
                          )
                        "
                        color="primary"
                        outline
                        small
                        >Imprimir fichas</v-btn
                      >
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!--:class="{'d-none': !items[horary]}"-->
                  <tr v-for="(horary, i) in times" :key="i">
                    <th
                      style="vertical-align: middle; text-align: center; padding: 5px;"
                    >
                      {{ horary }}
                    </th>
                    <td colspan="5" style="padding: 0px;">
                      <table class="table-custom" style="margin: 0px;">
                        <tbody>
                          <tr
                            v-for="(item, i) in items[horary]"
                            :key="i"
                            :class="{
                              'green lighten-3': item.arrival,
                              'blue lighten-3':
                                !item.arrival &&
                                item.call_confirm &&
                                item.call_confirm.status == 'CONFIRMOU',
                              'red lighten-3':
                                !item.arrival &&
                                item.call_confirm &&
                                item.call_confirm.status == 'DESISTÊNCIA',
                              'yellow lighten-3':
                                !item.arrival &&
                                item.call_confirm &&
                                item.call_confirm.status != 'CONFIRMOU' &&
                                item.call_confirm.status != 'DESISTÊNCIA' &&
                                item.call_confirm.status != 'Visualizar'
                            }"
                          >
                            <td
                              style="padding: 5px; width: 52px;  vertical-align: middle; text-align: center;"
                            >
                              <v-icon
                                v-if="
                                  !item.arrival &&
                                    !user.modules.includes(
                                      'CONSULTAS_CONFIRMACAO'
                                    )
                                "
                                small
                                @click="arrival(item)"
                                >fas fa-check</v-icon
                              >

                              <v-tooltip
                                top
                                v-if="item.arrival && item.arrival_user"
                              >
                                <span slot="activator">{{ item.arrival }}</span>
                                <span
                                  >Marcado por:
                                  {{ item.arrival_user.name }}</span
                                >
                              </v-tooltip>
                              <span v-if="item.arrival && !item.arrival_user">{{
                                item.arrival
                              }}</span>

                              <v-icon
                                dark
                                @click="arrival_clean(item)"
                                small
                                v-if="
                                  item.arrival &&
                                    isToday &&
                                    !user.modules.includes(
                                      'CONSULTAS_CONFIRMACAO'
                                    )
                                "
                                >fas fa-trash-alt</v-icon
                              >
                            </td>
                            <td style="padding: 5px; vertical-align: middle;">
                              {{ item.patient.name }}
                              <v-icon
                                v-if="
                                  !user.modules.includes(
                                    'CONSULTAS_CONFIRMACAO'
                                  )
                                "
                                :color="
                                  !item.patient.rg || !item.patient.address_id
                                    ? 'red'
                                    : 'grey'
                                "
                                small
                                class="mr-2"
                                @click="patientFormOpen(item.patient)"
                                >edit</v-icon
                              >
                            </td>
                            <td
                              style="width: 200px;padding: 5px; vertical-align: middle;"
                            >
                              {{ item.doctor ? item.doctor.name : "" }}
                            </td>
                            <td
                              style="width: 300px;padding: 5px; vertical-align: middle;"
                            >
                              <div
                                v-if="
                                  item.call_confirm &&
                                    item.call_confirm.status != 'Visualizar'
                                "
                              >
                                {{ item.call_confirm.status }} /
                                {{
                                  item.call_confirm.created_at
                                    | formatDateTimeShort
                                }}
                                / {{ item.call_confirm.contact_name }}
                              </div>
                            </td>
                            <td
                              style="text-align: right; width: 22.5%;padding: 5px;"
                            >
                              <v-tooltip top>
                                <v-icon
                                  v-if="
                                    !user.modules.includes(
                                      'CONSULTAS_CONFIRMACAO'
                                    )
                                  "
                                  slot="activator"
                                  small
                                  class="mr-2"
                                  @click="consultationPrintOpen(item)"
                                  >print</v-icon
                                >
                                <span>Ficha</span>
                              </v-tooltip>

                              <v-icon
                                v-if="
                                  !user.modules.includes(
                                    'CONSULTAS_CONFIRMACAO'
                                  )
                                "
                                small
                                class="mr-2"
                                @click="consultationFormOpen(item)"
                                >edit</v-icon
                              >

                              <v-icon
                                v-if="
                                  !user.modules.includes(
                                    'CONSULTAS_CONFIRMACAO'
                                  ) &&
                                    ((!item.arrival &&
                                      (isToday || isThreeDays)) ||
                                      user.modules.includes('EXCLUIR CONSULTA'))
                                "
                                small
                                @click="del(item.id)"
                                >delete</v-icon
                              >

                              <v-icon
                                v-if="
                                  user.modules.includes('CONSULTAS_CONFIRMACAO')
                                "
                                small
                                class="mr-2"
                                @click="confirmItemOpen(item)"
                                >call</v-icon
                              >
                              <v-icon
                                v-if="
                                  !user.modules.includes(
                                    'CONSULTAS_CONFIRMACAO'
                                  )
                                "
                                small
                                class="mr-2"
                                @click="confirmViewItemOpen(item)"
                                >fa-eye</v-icon
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap v-else>
        <v-flex xs12 sm10>
          <v-card>
            <v-toolbar flat color="white" class="elevation-1">
              <v-toolbar-title>Especialidade</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 sm6 v-for="(item, i) in specialties" :key="i">
                  <v-list-tile exact :to="`/consultations/${item.id}`">
                    <v-list-tile-content>
                      <v-list-tile-title v-text="item.name"></v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <div v-if="consultationFormActive.open">
      <consultation-form
        :active="consultationFormActive.open"
        :item="consultationFormActive.item"
        :finish="consultationFormClose"
      ></consultation-form>
    </div>

    <div v-if="patientFormActive.open">
      <patient-form
        :active="patientFormActive.open"
        :item="patientFormActive.item"
        :finish="patientFormClose"
      />
    </div>

    <div v-if="consultationPrint.open">
      <consultation-print
        :active="consultationPrint.open"
        :item="consultationPrint.item"
        :finish="consultationPrintClose"
      />
    </div>

    <div v-if="doctorChangeDate.open">
      <doctor-change-date
        :active="doctorChangeDate.open"
        :item="doctorChangeDate.item"
        :finish="doctorChangeDateClose"
      />
    </div>
    <div v-if="confirmItem.open">
      <consultation-confirm
        :active="confirmItem.open"
        :item="confirmItem.item"
        :finish="confirmItemClose"
      />
    </div>
    <div v-if="confirmViewItem.open">
      <consultationview-confirm
        :active="confirmViewItem.open"
        :item="confirmViewItem.item"
        :finish="confirmViewItemClose"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { TIMES } from "../../constants";
export default {
  data() {
    return {
      user: USER,
      valid: false,
      dialog: false,
      time: moment().format("YYYY-MM-DD"),
      isToday: true,
      isThreeDays: false,
      times: TIMES,
      menu1: false,
      specialty: null,
      consultationPrint: { open: false, item: {} },
      confirmViewItem: { open: false, item: {} },
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: true,
        page: 1,
        sortBy: "id"
      },
      items: [],
      consultationFormActive: { open: false, item: {} },
      patientFormActive: { open: false, item: {} },
      doctorChangeDate: { open: false, item: {} },
      confirmItem: { open: false, item: {} },
      menuFilter: { menu: false },
      filtro: {
        origin: null,
        start: moment().format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
        doctor_id: null,
        arrival: null
      },
      loading: false,
      specialties: []
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    "pagination.page": {
      handler() {
        this.getList();
        this.getSpecialty();
      },
      deep: true
    },
    "$route.params.specialty_id": {
      handler() {
        this.getList();
        this.getSpecialty();
      },
      deep: true
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getList();
      this.getSpecialty();
      this.getSpecialties();
    },
    getList() {
      if (!this.$route.params.specialty_id) return;
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          horary: this.time,
          specialty_id: this.$route.params.specialty_id,
          return: 0
        };

        this.isToday = this.time == moment().format("YYYY-MM-DD");
        const diff = moment().diff(this.time, "days");
        console.log("diff", diff);
        this.isThreeDays = USER.type == 13 && diff > -3 && diff < 1;
        this.$http
          .get(`/consultations/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = _.groupBy(json.items, obj => {
                return moment(obj.horary).format("HH:mm");
              });
            }, 300);
          })
          .catch(() => (this.loading = false));
      });
    },
    getSpecialty() {
      if (!this.$route.params.specialty_id) return;
      this.$http
        .get(`/specialties/${this.$route.params.specialty_id}/show`)
        .then(response => response.data)
        .then(json => {
          this.specialty = json;
        })
        .catch(() => (this.loading = false));
    },
    getSpecialties() {
      this.$http
        .get(`/specialties/list`)
        .then(response => response.data)
        .then(json => {
          this.specialties = json.items;
        })
        .catch(() => (this.loading = false));
    },
    prev() {
      this.time.subtract(1, "days");
      this.getList();
    },
    next() {
      this.time.add(1, "days");
      this.getList();
    },
    consultationFormOpen(item) {
      this.consultationFormActive.open = true;
      this.consultationFormActive.item = item;
    },
    consultationFormClose() {
      this.consultationFormActive.open = false;
      this.getList();
    },
    patientFormOpen(item) {
      this.patientFormActive.open = true;
      this.patientFormActive.item = item;
    },
    patientFormClose() {
      this.patientFormActive.open = false;
      this.getList();
    },
    doctorChangeDateOpen(item) {
      this.doctorChangeDate.open = true;
      this.doctorChangeDate.item = item;
    },
    doctorChangeDateClose() {
      this.doctorChangeDate.open = false;
      this.getList();
    },
    confirmViewItemOpen(item) {
      this.confirmViewItem.open = true;
      if (!item.call_confirm)
        item.call_confirm = {
          consultation_id: item.id,
          status: "Visualizar",
          patient_id: item.patient_id
        };
      this.confirmViewItem.item = item;
    },
    confirmViewItemClose() {
      this.confirmViewItem.open = false;
      this.getList();
    },
    confirmItemOpen(item) {
      this.confirmItem.open = true;
      if (!item.call_confirm)
        item.call_confirm = {
          consultation_id: item.id,
          status: null,
          patient_id: item.patient_id
        };
      this.confirmItem.item = item;
    },
    confirmItemClose() {
      this.confirmItem.open = false;
      this.getList();
    },
    consultationPrintOpen(item) {
      window.scrollTo(0, 0);
      this.consultationPrint.open = true;
      this.consultationPrint.item = item;
    },
    consultationPrintClose() {
      this.consultationPrint.open = false;
    },
    arrival(item) {
      if (confirm("Deseja realmente marca a chegada?")) {
        this.$http
          .put(`/consultations/${item.id}/arrival`, {})
          .then(response => {
            this.$notify({
              title: `salvo com sucesso!`,
              type: "success"
            });
            this.getList();
          })
          .catch(err => {
            this.$reqError(`Erro ao salvar!`, err, this);
          });
      }
    },
    arrival_clean(item) {
      if (confirm("Deseja realmente desmarca a chegada?")) {
        this.$http
          .put(`/consultations/${item.id}/arrival_clean`, {})
          .then(response => {
            this.$notify({
              title: `salvo com sucesso!`,
              type: "success"
            });
            this.getList();
          })
          .catch(err => {
            this.$reqError(`Erro ao salvar!`, err, this);
          });
      }
    },
    formatDate: date => moment(date).format("DD/MM/YYYY"),
    today: () => moment().format("DD/MM/YYYY"),
    del(id) {
      if (confirm("Deseja realmente excluir este Consulta?")) {
        this.$http.delete(`/consultations/${id}`).then(response => {
          this.$notify({ title: `Consulta Excluido!`, type: "success" });
          this.getList();
        });
      }
    },
    goExternal(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    goReport() {
      this.menuFilter.menu = false;
      let origin = "";
      let doctor_id = "";
      let arrival = "";
      if (this.filtro.arrival != null)
        arrival = `&arrival=${this.filtro.arrival}`;
      if (this.filtro.origin) origin = `&origin=${this.filtro.origin}`;
      if (this.filtro.doctor_id)
        doctor_id = `&doctor_id=${this.filtro.doctor_id}`;

      let specialty_id = `&specialty_id=${this.$route.params.specialty_id}`;
      let url = `/consultations/reports/?return=0&start=${this.filtro.start}&end=${this.filtro.end}${specialty_id}${origin}${doctor_id}${arrival}`;
      window.open(url, "_blank");
    },
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400)
  }
};
</script>

<style scoped>
table {
  font-size: 13px;
}
</style>
