<template>
  <div>
    <!--<header-page />-->
    <v-container grid-list-md text-xs-center px-0>
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Produtos - {{sector && sector.name}}</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
              class="hidden-md-and-down"
              @click:append="getList()"
              v-on:keyup.enter="getList"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-menu
              v-model="filtro.menu"
              bottom
              left
              :close-on-content-click="false"
              max-width="400"
              min-width="400"
              offset-x
            >
              <v-btn slot="activator" color="primary" outline>OPÇÕES</v-btn>

              <v-card>
                <v-card-title class="px-1 py-1">
                  FILTRO
                  <v-spacer></v-spacer>
                  <v-btn
                    outline
                    light
                    small
                    color="primary"
                    @click="filtro.menu = true; filtro = {};"
                  >Limpar</v-btn>
                </v-card-title>

                <v-layout row wrap>
                  <v-flex xs12 class="px-1" v-if="sector">
                    <select-custom
                      :url="'/categories/list?sortBy=name&office=' + sector.office"
                      v-model="filtro.category_id"
                      label="Categoria"
                    />
                  </v-flex>
                </v-layout>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn flat @click="filtro.menu = false">Fechar</v-btn>
                  <v-btn color="primary" flat @click="filtrar()">Filtrar</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
            <v-btn v-if="sector" @click="goReport()" color="primary">Relatório</v-btn>
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.code }}</td>
                <td>{{ props.item.single_products_count }}</td>
                <td>{{ props.item.sector_product_config ? props.item.sector_product_config.min : 0 }}</td>
                <td>{{ props.item.sector_product_config ? props.item.sector_product_config.max : 0 }}</td>
                <td class="text-xs-center px-0">
                  <v-icon small @click="openSectorProductConfigForm(props.item)">edit</v-icon>
                </td>
              </tr>
            </template>

            <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
          </div>
        </v-flex>
      </v-layout>

      <div v-if="sectorProductConfigFormActive.open">
        <sector-product-config
          :active="sectorProductConfigFormActive.open"
          :item="sectorProductConfigFormActive.item"
          :finish="onCloseSectorProductConfigForm"
        ></sector-product-config>
      </div>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      valid: false,
      search: "",
      sector: null,
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "name",
      },
      items: [],
      columns: [
        { value: "id", text: "ID" },
        { value: "name", text: "Nome" },
        { value: "code", text: "Código" },
        { text: "qty", value: "QTD", sortable: false },
        { text: "min", value: "MIN.", sortable: false },
        { text: "max", value: "MAX.", sortable: false },
        { text: "", value: "", sortable: false },
      ],
      sectorProductConfigFormActive: {
        open: false,
        item: {},
      },
      setoreViewActive: { open: false },
      loading: false,
      filtro: {
        menu: false,
        category_id: null,
      },
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getItem();
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          ...this.filtro,
          menu: undefined,
          totalItems: undefined,
          search: this.search,
          sector_id: this.$route.params.id,
        };
        this.$http
          .get(`/sector_products_config/list?sector_id`, { params })
          .then((response) => response.data)
          .then((json) => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    async getItem() {
      try {
        const json = await this.$http
          .get(`/sectors/${this.$route.params.id}/show`)
          .then((r) => r.data);
        console.log(json);
        this.sector = json;
      } catch (error) {
        console.log(error);
        this.$notify({
          title: `Não foi encontrado este setor`,
          type: "error",
        });
      }
    },
    del(id) {
      if (confirm("Deseja realmente excluir este setor?")) {
        this.$http.delete(`/sector_products_config/${id}`).then((response) => {
          this.$notify({
            title: `Setor Excluido!`,
            type: "success",
          });
          this.getList();
        });
      }
    },
    debounceInput: _.debounce(function () {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
    openSectorProductConfigForm(product) {
      let item = { product_id: product.id, sector_id: this.$route.params.id };
      if (product.sector_product_config) item = product.sector_product_config;
      this.sectorProductConfigFormActive.open = true;
      this.sectorProductConfigFormActive.item = item;
    },
    onCloseSectorProductConfigForm() {
      this.sectorProductConfigFormActive.open = false;
      this.getList();
    },
    filtrar() {
      this.filtro.menu = false;
      this.getList();
    },
    goReport() {
      this.filtro.menu = false;
      let category = "";
      if (this.filtro.category_id)
        category = `&category_id=${this.filtro.category_id}`;

      //&sortBy=${this.pagination.sortBy}&descending=${this.pagination.descending}
      let url = `/sector_products_config/report_adjust/
        ${this.$route.params.id}?q=1${category}`;
      window.open(url, "_blank");
    },
  },
};
</script>
