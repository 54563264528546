import Vue from "vue";
import Router from "vue-router";

const requireAuth = async (to, from, next) => {
  try {
    await axios.get("/users/me");
    next();
  } catch (err) {
    console.log(err);
    window.location.reload();
  }
};

import Home from "./pages/Home.vue";
import ListPatients from "./pages/ListPatients.vue";
import Reception from "./pages/Reception.vue";
import Reports from "./pages/Reports.vue";
import Operators from "./pages/Operators.vue";
import Hospitalizations from "./pages/Hospitalizations.vue";
import HospitalizationsRead from "./pages/HospitalizationsRead.vue";
import Settings from "./pages/Settings.vue";

import Category from "./pages/pharmacy/Category.vue";
import Subcategory from "./pages/pharmacy/Subcategory.vue";
import Destiny from "./pages/pharmacy/Destiny.vue";
import Products from "./pages/pharmacy/Products.vue";
import Sectors from "./pages/pharmacy/Sectors.vue";
import RetiradasPage from "./pages/pharmacy/Retiradas.vue";
import Devolutions from "./pages/pharmacy/Devolutions.vue";
import Partner from "./pages/pharmacy/Partner.vue";

import Supports from "./pages/Supports.vue";

import ReportProducts from "./pages/pharmacy/ReportProducts.vue";
import Spreadsheets from "./pages/Spreadsheets.vue";

import ReportProductsMinimum from "./pages/pharmacy/ReportProductsMinimum.vue";
import ReportRetiradas from "./pages/pharmacy/ReportRetiradas.vue";
import ReportConsumo from "./pages/pharmacy/ReportConsumo.vue";
import NewReportConsumo from "./pages/pharmacy/NewReportConsumo.vue";
import Requirements from "./pages/Requirements.vue";
import ReportLoan from "./pages/pharmacy/ReportLoan.vue";

import Departments from "./pages/Departments.vue";
import ListPasswords from "./pages/ListPasswords.vue";
import PasswordPanel from "./pages/PasswordPanel.vue";

import ListBanks from "./pages/financial/ListBanks.vue";
import ListBillTypes from "./pages/financial/ListBillTypes.vue";
import ListBills from "./pages/financial/ListBills.vue";
import ListProviders from "./pages/financial/ListProviders.vue";
import ReportFinancial from "./pages/financial/ReportFinancial.vue";
import ReportBank from "./pages/financial/ReportBank.vue";
import ReportBills from "./pages/financial/ReportBills.vue";
import ListMarks from "./pages/ListMarks.vue";
import ListContracts from "./pages/financial/ListContracts.vue";

import ListServices from "./pages/ListServices.vue";
import Consultations from "./pages/consultations/Consultations.vue";
import ConsultationsReturn from "./pages/consultations/ConsultationsReturn.vue";
import ConsultationsFa from "./pages/consultations/ConsultationsFa.vue";
import Purchases from "./pages/pharmacy/Purchases.vue";
import SingleProducts from "./pages/pharmacy/SingleProducts.vue";
import SectorProducts from "./pages/pharmacy/SectorProducts.vue";
import TransferPack from "./pages/pharmacy/TransferPack.vue";
import Quotations from "./pages/pharmacy/Quotations.vue";
import ReportLoteExpiration from "./pages/pharmacy/ReportLoteExpiration.vue";
import Doctors from "./pages/Doctors.vue";
import SurgeryTypes from "./pages/SurgeryTypes.vue";
import ExamTypes from "./pages/ExamTypes.vue";
import Exams from "./pages/Exams.vue";
import Surgeries from "./pages/Surgeries.vue";
import Radiologies from "./pages/Radiologies.vue";
import Same from "./pages/same/Same.vue";
import SameOut from "./pages/same/SameOut.vue";
import SameReport from "./pages/same/SameReport.vue";
import Newspace from "./pages/same/Newspace.vue";
import ListPatientsSame from "./pages/same/ListPatientsSame.vue";
import WorkShifts from "./pages/WorkShifts.vue";

import PreBilling from "./pages/same/PreBilling.vue";
import Billing from "./pages/same/Billing.vue";
import BillingReport from "./pages/same/BillingReport.vue";
import Bookkeeping from "./pages/same/Bookkeeping.vue";
import Visitation from "./pages/Visitation.vue";
import VisitationReport from "./pages/VisitationReport.vue";
import BookkeepingDownstairs from "./pages/same/BookkeepingDownstairs.vue";
import Scih from "./pages/same/Scih.vue";
import SameHospitalizations from "./pages/same/Hospitalizations.vue";
import ElectiveSurgery from "./pages/same/ElectiveSurgery.vue";
import CallConfirmReport from "./pages/consultations/CallConfirmReport.vue";
import CallConfirm from "./pages/consultations/CallConfirm.vue";
import ReceptionReport from "./pages/ReceptionReport.vue";
import RadiologiesReport from "./pages/RadiologiesReport.vue";
import BedsPanel from "./pages/BedsPanel.vue";
import Lotes from "./pages/pharmacy/Lotes.vue";
import ReportPurchasesPrice from "./pages/pharmacy/ReportPurchasesPrice.vue";
import ReportMoviment from "./pages/pharmacy/ReportMovement.vue";
import ReportsMonthly from "./pages/pharmacy/ReportsMonthly.vue";

import ListOrders from "./pages/ListOrders.vue";

import ReportRp08 from "./pages/financial/ReportRp08.vue";
import ReportExcel from "./pages/ReportExcel.vue";

let ReceptionPage = Reception;
Vue.use(Router);

const routes = [];

routes.push({
  path: "/users",
  component: ListPatients,
  beforeEnter: requireAuth
});
routes.push({ path: "/home", component: Home, beforeEnter: requireAuth });

routes.push({
  path: "/orders",
  component: ListOrders,
  beforeEnter: requireAuth
});

if (USER.type < 4 || USER.type == 11 || USER.type == 13) {
  routes.push({
    path: "/reports",
    component: Reports,
    beforeEnter: requireAuth
  });
  routes.push({
    path: "/reception",
    component: ReceptionPage,
    beforeEnter: requireAuth
  });
}

if (
  USER.type == 4 ||
  USER.type == 3 ||
  USER.type == 2 ||
  USER.type == 1 ||
  USER.type == 11 ||
  USER.type == 13
) {
  routes.push({
    path: "/hospitalizations",
    component: Hospitalizations,
    beforeEnter: requireAuth
  });
  routes.push({
    path: "/consultations",
    component: Consultations,
    beforeEnter: requireAuth
  });
  routes.push({
    path: "/consultations_fa/:specialty_id/:time",
    component: ConsultationsFa,
    beforeEnter: requireAuth
  });
  routes.push({
    path: "/consultations/:specialty_id",
    component: Consultations,
    beforeEnter: requireAuth
  });
  routes.push({
    path: "/consultations_return",
    component: ConsultationsReturn,
    beforeEnter: requireAuth
  });
  routes.push({
    path: "/consultations_return/:specialty_id",
    component: ConsultationsReturn,
    beforeEnter: requireAuth
  });
  if (USER.type != 13) {
    routes.push({ path: "/exams", component: Exams, beforeEnter: requireAuth });
    routes.push({
      path: "/exams/:exam_type_id",
      component: Exams,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/surgeries",
      component: Surgeries,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/surgeries/:surgery_type_id",
      component: Surgeries,
      beforeEnter: requireAuth
    });
  }
} else if (USER.modules) {
  if (USER.modules.includes("CONSULTAS")) {
    routes.push({
      path: "/consultations",
      component: Consultations,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/consultations_fa/:specialty_id/:time",
      component: ConsultationsFa,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/consultations/:specialty_id",
      component: Consultations,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("RETORNOS")) {
    routes.push({
      path: "/consultations_return",
      component: ConsultationsReturn,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/consultations_return/:specialty_id",
      component: ConsultationsReturn,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("EXAMES")) {
    routes.push({ path: "/exams", component: Exams, beforeEnter: requireAuth });
    routes.push({
      path: "/exams/:exam_type_id",
      component: Exams,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("CIRURGIAS")) {
    routes.push({
      path: "/surgeries",
      component: Surgeries,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/surgeries/:surgery_type_id",
      component: Surgeries,
      beforeEnter: requireAuth
    });
  }
}

if (USER.modules) {
  if (USER.modules.includes("CONSULTAS_CONFIRMACAO")) {
    routes.push({
      path: "/consultations",
      component: Consultations,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/consultations/:specialty_id",
      component: Consultations,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/consultations_return",
      component: ConsultationsReturn,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/consultations_return/:specialty_id",
      component: ConsultationsReturn,
      beforeEnter: requireAuth
    });
    routes.push({ path: "/exams", component: Exams, beforeEnter: requireAuth });
    routes.push({
      path: "/exams/:exam_type_id",
      component: Exams,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/call_confirm",
      component: CallConfirm,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("CONSULTAS_CONFIRMACAO") || USER.type == 1) {
    routes.push({
      path: "/call_confirm_report",
      component: CallConfirmReport,
      beforeEnter: requireAuth
    });
  }

  if (USER.type == 1 || USER.type == 11 || USER.type == 4) {
    routes.push({
      path: "/doctors",
      component: Doctors,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/exam_types/:radiology",
      component: ExamTypes,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/surgery_types",
      component: SurgeryTypes,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("RADIOLOGIA_AGENDA")) {
    routes.push({
      path: "/exam_types/:radiology",
      component: ExamTypes,
      beforeEnter: requireAuth
    });
  }
  if (USER.modules.includes("RADIOLOGIA_AGENDAMENTO")) {
    routes.push({ path: "/exams", component: Exams, beforeEnter: requireAuth });
    routes.push({
      path: "/exams/:exam_type_id",
      component: Exams,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("MEDICOS")) {
    routes.push({
      path: "/doctors",
      component: Doctors,
      beforeEnter: requireAuth
    });
  }

  if (USER.type == 1 || USER.type == 11 || USER.type == 12) {
    routes.push({
      path: "/radiologies",
      component: Radiologies,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/call_confirm_report",
      component: CallConfirmReport,
      beforeEnter: requireAuth
    });
  }

  if (USER.type == 1 || USER.type == 11 || USER.type == 13) {
    routes.push({ path: "/same", component: Same, beforeEnter: requireAuth });
    routes.push({
      path: "/same_out",
      component: SameOut,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/same_report",
      component: SameReport,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/newspace",
      component: Newspace,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("ESCRITURACAO") || USER.type == 1) {
    routes.push({
      path: "/bookkeeping",
      component: Bookkeeping,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/billing_report/:destiny",
      component: BillingReport,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("ESCRITURACAO_TERREO") || USER.type == 1) {
    routes.push({
      path: "/bookkeeping_downstairs",
      component: BookkeepingDownstairs,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/billing_report/:destiny",
      component: BillingReport,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("SCIH") || USER.type == 1) {
    routes.push({ path: "/Scih", component: Scih, beforeEnter: requireAuth });
    routes.push({
      path: "/billing_report/:destiny",
      component: BillingReport,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("CIRURGIA_ELETIVA") || USER.type == 1) {
    routes.push({
      path: "/elective_surgery",
      component: ElectiveSurgery,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/billing_report/:destiny",
      component: BillingReport,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("SAME_INTERNACAO") || USER.type == 1) {
    routes.push({
      path: "/same_hospitalizations",
      component: SameHospitalizations,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/billing_report/:destiny",
      component: BillingReport,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("RECEPÇÃO DE VISITAS") || USER.type == 1) {
    routes.push({
      path: "/visitation",
      component: Visitation,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/visitation_report",
      component: VisitationReport,
      beforeEnter: requireAuth
    });
  }

  if (USER.type == 13) {
    routes.push({
      path: "/users_same",
      component: ListPatientsSame,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("PLANTAO") || USER.type == 1) {
    routes.push({
      path: "/work_shifts",
      component: WorkShifts,
      beforeEnter: requireAuth
    });
  }

  if (USER.type == 1) {
    routes.push({
      path: "/operators",
      component: Operators,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/departments",
      component: Departments,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/requirements",
      component: Requirements,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/services",
      component: ListServices,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/spreadsheets",
      component: Spreadsheets,
      beforeEnter: requireAuth
    });
  }

  if (USER.type == 4) {
    routes.push({
      path: "/reception",
      component: Products,
      beforeEnter: requireAuth
    });
  }

  //new
  if (USER.type == 1 || USER.type == 6) {
    routes.push({
      path: "/app/banks",
      component: ListBanks,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/app/bill_types",
      component: ListBillTypes,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/app/bills",
      component: ListBills,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/app/reportfinancial/:type",
      component: ReportFinancial,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/app/reportbank",
      component: ReportBank,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/app/report_bills",
      component: ReportBills,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/marks",
      component: ListMarks,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/spreadsheets",
      component: Spreadsheets,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/app/contracts",
      component: ListContracts,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/report_rp08",
      component: ReportRp08,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/report/excel",
      component: ReportExcel,
      beforeEnter: requireAuth
    });
  }

  if (USER.type == 8) {
    routes.push({
      path: "/services",
      component: ListServices,
      beforeEnter: requireAuth
    });

    routes.push({
      path: "/marks",
      component: ListMarks,
      beforeEnter: requireAuth
    });
  }

  if (USER.type == 1 || USER.type == 7) {
    routes.push({
      path: "/supports",
      component: Supports,
      beforeEnter: requireAuth
    });
  }

  if (
    USER.modules.includes("FORNECEDORES") ||
    USER.type == 1 ||
    USER.type == 6 ||
    USER.type == 8
  ) {
    routes.push({
      path: "/app/providers",
      component: ListProviders,
      beforeEnter: requireAuth
    });
  }

  if (
    USER.modules.includes("FARMACIA_PARCEIROS") ||
    USER.modules.includes("ALMOXARIFADO_PARCEIROS") ||
    USER.modules.includes("NUTRICAO_PARCEIROS") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/partners",
      component: Partner,
      beforeEnter: requireAuth
    });
  }
  if (
    USER.modules.includes("FARMACIA_CATEGORIA") ||
    USER.modules.includes("ALMOXARIFADO_CATEGORIA") ||
    USER.modules.includes("NUTRICAO_CATEGORIA") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/category/:office",
      component: Category,
      beforeEnter: requireAuth
    });
  }
  if (
    USER.modules.includes("FARMACIA_SUBCATEGORIA") ||
    USER.modules.includes("ALMOXARIFADO_SUBCATEGORIA") ||
    USER.modules.includes("NUTRICAO_SUBCATEGORIA") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/subcategory/:office",
      component: Subcategory,
      beforeEnter: requireAuth
    });
  }
  if (
    USER.modules.includes("FARMACIA_ESTOQUE") ||
    USER.modules.includes("ALMOXARIFADO_ESTOQUE") ||
    USER.modules.includes("NUTRICAO_ESTOQUE") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/products/:office",
      component: Products,
      beforeEnter: requireAuth
    });
  }
  if (
    USER.modules.includes("FARMACIA_ENTRADA") ||
    USER.modules.includes("ALMOXARIFADO_ENTRADA") ||
    USER.modules.includes("NUTRICAO_ENTRADA") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/purchases/:office",
      component: Purchases,
      beforeEnter: requireAuth
    });
  }
  if (
    USER.modules.includes("FARMACIA_SETORES") ||
    USER.modules.includes("ALMOXARIFADO_SETORES") ||
    USER.modules.includes("NUTRICAO_SETORES") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/sectors/:office",
      component: Sectors,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/sectors_products/:id",
      component: SectorProducts,
      beforeEnter: requireAuth
    });
  }
  if (
    USER.modules.includes("FARMACIA_RELATORIO_PRODUTOS") ||
    USER.modules.includes("ALMOXARIFADO_RELATORIO_PRODUTOS") ||
    USER.modules.includes("NUTRICAO_RELATORIO_PRODUTOS") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/report_products/:type",
      component: ReportProducts,
      beforeEnter: requireAuth
    });
  }
  if (
    USER.modules.includes("FARMACIA_RELATORIO_MINIMO") ||
    USER.modules.includes("ALMOXARIFADO_RELATORIO_MINIMO") ||
    USER.modules.includes("NUTRICAO_RELATORIO_MINIMO") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/report_products_minimum/:type",
      component: ReportProductsMinimum,
      beforeEnter: requireAuth
    });
  }
  if (
    USER.modules.includes("FARMACIA_RELATORIO_SAIDA") ||
    USER.modules.includes("ALMOXARIFADO_RELATORIO_SAIDA") ||
    USER.modules.includes("NUTRICAO_RELATORIO_SAIDA") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/report_retiradas/:office",
      component: ReportRetiradas,
      beforeEnter: requireAuth
    });
  }
  if (
    USER.modules.includes("FARMACIA_RELATORIO_CONSUMO") ||
    USER.modules.includes("ALMOXARIFADO_RELATORIO_CONSUMO") ||
    USER.modules.includes("NUTRICAO_RELATORIO_CONSUMO") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/report_consumo/:type",
      component: ReportConsumo,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/new_report_consumo/:type",
      component: NewReportConsumo,
      beforeEnter: requireAuth
    });
  }
  if (
    USER.modules.includes("FARMACIA_SAIDA") ||
    USER.modules.includes("ALMOXARIFADO_SAIDA") ||
    USER.modules.includes("NUTRICAO_SAIDA") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/retiradas/:office",
      component: RetiradasPage,
      beforeEnter: requireAuth
    });
  }
  if (
    USER.modules.includes("FARMACIA_DEVOLUCAO") ||
    USER.modules.includes("ALMOXARIFADO_DEVOLUCAO") ||
    USER.modules.includes("NUTRICAO_DEVOLUCAO") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/devolutions/:office",
      component: Devolutions,
      beforeEnter: requireAuth
    });
  }
  if (
    USER.modules.includes("FARMACIA_TRANSFERENCIA") ||
    USER.modules.includes("ALMOXARIFADO_TRANSFERENCIA") ||
    USER.modules.includes("NUTRICAO_TRANSFERENCIA") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/transfers/:office",
      component: TransferPack,
      beforeEnter: requireAuth
    });
  }
  if (
    USER.modules.includes("FARMACIA_COTACAO") ||
    USER.modules.includes("ALMOXARIFADO_COTACAO") ||
    USER.modules.includes("NUTRICAO_COTACAO") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/quotations/:office",
      component: Quotations,
      beforeEnter: requireAuth
    });
  }
  // if (USER.modules.includes('FARMACIA_COTACAO') || USER.modules.includes('ALMOXARIFADO_COTACAO') || USER.modules.includes('NUTRICAO_COTACAO') || USER.type == 1) {
  //   routes.push({ path: '/orders/', component: Orders, beforeEnter: requireAuth });
  // }
  if (
    USER.modules.includes("FARMACIA_VENCIMENTO") ||
    USER.modules.includes("ALMOXARIFADO_VENCIMENTO") ||
    USER.modules.includes("NUTRICAO_VENCIMENTO") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/report_lote_expiration/:office",
      component: ReportLoteExpiration,
      beforeEnter: requireAuth
    });
  }
  if (
    USER.modules.includes("FARMACIA_RELATORIO_EMPRESTIMO") ||
    USER.modules.includes("ALMOXARIFADO_RELATORIO_EMPRESTIMO") ||
    USER.modules.includes("NUTRICAO_RELATORIO_EMPRESTIMO") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/report_loan/:office",
      component: ReportLoan,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("NOTA_SERVICOS") || USER.type == 1) {
    routes.push({
      path: "/services",
      component: ListServices,
      beforeEnter: requireAuth
    });
  }

  if (
    USER.modules.includes("FARMACIA_LOTES") ||
    USER.modules.includes("ALMOXARIFADO_LOTES") ||
    USER.modules.includes("NUTRICAO_LOTES") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/lotes/:office",
      component: Lotes,
      beforeEnter: requireAuth
    });
  }

  if (
    USER.modules.includes("FARMACIA_VALOR_ENTRADA") ||
    USER.modules.includes("ALMOXARIFADO_VALOR_ENTRADA") ||
    USER.modules.includes("NUTRICAO_VALOR_ENTRADA") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/report_purchases_price/:office",
      component: ReportPurchasesPrice,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("RELATORIO_MOVIMENTO_ESTOQUE") || USER.type == 1) {
    routes.push({
      path: "/report_movement/:office/:product_id",
      component: ReportMoviment,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("FATURAMENTO") || USER.type == 1) {
    routes.push({
      path: "/billing",
      component: Billing,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/billing_report/:destiny",
      component: BillingReport,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("PRE_FATURAMENTO") || USER.type == 1) {
    routes.push({
      path: "/pre_billing",
      component: PreBilling,
      beforeEnter: requireAuth
    });
    routes.push({
      path: "/billing_report/:destiny",
      component: BillingReport,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("VISUALIZAR_INTERNACAO")) {
    routes.push({
      path: "/hospitalizations_read",
      component: HospitalizationsRead,
      beforeEnter: requireAuth
    });
  }

  if (
    USER.modules.includes("MEDICO_INTERNACAO") ||
    USER.modules.includes("INTERNACAO")
  ) {
    routes.push({
      path: "/hospitalizations",
      component: Hospitalizations,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("VISUALIZAR_ATENDIMENTOS")) {
    routes.push({
      path: "/reception",
      component: ReceptionPage,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("TRIAGEM SENHAS")) {
    routes.push({
      path: "/reception",
      component: ReceptionPage,
      beforeEnter: requireAuth
    });
  }

  if (
    USER.modules.includes("RELATORIO_ATENDIMENTO_OPERADOR") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/reception_report",
      component: ReceptionReport,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("RELATORIO_RADIOLOGIA_TIPOS") || USER.type == 1) {
    routes.push({
      path: "/radiologies_report_types",
      component: RadiologiesReport,
      beforeEnter: requireAuth
    });
  }

  if (USER.modules.includes("PAINEL_LEITOS") || USER.type == 1) {
    routes.push({
      path: "/beds_panel",
      component: BedsPanel,
      beforeEnter: requireAuth
    });
  }

  if (
    USER.modules.includes("FARMACIA_ALMOXARIFADO_CENTRO_CUSTO") ||
    USER.modules.includes("NUTRICAO_CENTRO_CUSTO") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/destiny",
      component: Destiny,
      beforeEnter: requireAuth
    });
  }

  if (
    USER.modules.includes("FARMACIA_ALMOXARIFADO_RELATORIO_MENSAL") ||
    USER.modules.includes("NUTRICAO_RELATORIO_MENSAL") ||
    USER.type == 1
  ) {
    routes.push({
      path: "/repors_monthly/:office",
      component: ReportsMonthly,
      beforeEnter: requireAuth
    });
  }
}

if (USER.modules.includes("RELATORIO")) {
  routes.push({
    path: "/reports",
    component: Reports,
    beforeEnter: requireAuth
  });
}

routes.push({
  path: "/passwords",
  component: ListPasswords,
  beforeEnter: requireAuth
});
routes.push({ path: "/app/passwords/:id", component: PasswordPanel });
routes.push({ path: "/", redirect: "home", beforeEnter: requireAuth });
routes.push({
  path: "/settings",
  component: Settings,
  beforeEnter: requireAuth
});
routes.push({
  path: "/app/single_products/:id",
  component: SingleProducts,
  beforeEnter: requireAuth
});

export default new Router({
  mode: "history",
  routes
});
