<template>
  <div>
    <!--<header-page />-->
    <v-container grid-list-md text-xs-center fluid>
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1 mb-4">
            <v-toolbar-title>Relatório de Emprestimos</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <div>
              {{formatDate(range.start)}} - {{formatDate(range.end)}}
              <v-icon class="mr-2" @click="range.modal = true">edit</v-icon>
            </div>

            <v-menu
              v-model="menuFilter.menu"
              bottom
              left
              :close-on-content-click="false"
              max-width="400"
              min-width="400"
              offset-x
            >
              <v-btn slot="activator" color="primary">OPÇÕES</v-btn>

              <v-card>
                <v-card-title class="px-1 py-1">
                  FILTRO
                  <v-spacer></v-spacer>
                  <v-btn
                    outline
                    light
                    small
                    color="primary"
                    @click="menuFilter.menu = true; filtro = {};"
                  >Limpar</v-btn>
                </v-card-title>

                <v-layout row wrap>
                  <v-flex xs12 class="px-1">
                    <select-custom
                      url="/partners/list?sortBy=name"
                      v-model="filtro.partner_id"
                      label="Parceiro"
                    />
                  </v-flex>
                  <v-flex xs12 class="px-1">
                    <select-custom
                      url="/products/list?sortBy=name"
                      v-model="filtro.product_id"
                      label="Produto"
                    />
                  </v-flex>
                </v-layout>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn flat @click="menuFilter.menu = false">Fechar</v-btn>
                  <v-btn color="primary" flat @click="filtrar()">Filtrar</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-toolbar>
        </v-flex>

        <v-flex xs12 sm6 class="px-1">
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Entradas</v-toolbar-title>
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="itemsEntrada"
            :loading="loading"
            hide-actions
            disable-initial-sort
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.user && props.item.user.name}}</td>
                <td>{{ props.item.created_at | formatDateTimeShort}}</td>
                <td>{{ props.item.partner && props.item.partner.name}}</td>
                <td>
                  <span
                    v-for="(item, i) in props.item.lotes"
                    :key="i"
                  >{{item.product.name}} ({{item.qty}});</span>
                </td>
              </tr>
            </template>

            <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
          </v-data-table>
        </v-flex>

        <v-flex xs12 sm6 class="px-1">
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Saídas</v-toolbar-title>
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :loading="loading"
            hide-actions
            disable-initial-sort
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.user && props.item.user.name}}</td>
                <td>{{ props.item.created_at | formatDateTimeShort}}</td>
                <td>{{ props.item.partner && props.item.partner.name}}</td>
                <td>
                  <span v-for="(value, i) in props.item.products" :key="i">{{i}}({{value}});</span>
                </td>
              </tr>
            </template>

            <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
          </v-data-table>
        </v-flex>
      </v-layout>

      <v-dialog v-model="range.modal" scrollable max-width="350px">
        <v-card>
          <v-card-title>
            <span class="headline">Período</span>
          </v-card-title>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12>
                <v-menu
                  ref="menuStart"
                  :close-on-content-click="false"
                  v-model="range.menuStart"
                  :nudge-right="40"
                  :return-value.sync="range.start"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="computedStart"
                    label="Data inicial"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker v-model="range.start" no-title scrollable locale="pt-br">
                    <v-spacer></v-spacer>
                    <v-btn flat color="grey" @click="range.menuStart = false">Fechar</v-btn>
                    <v-btn color="primary" @click="$refs.menuStart.save(range.start)">OK</v-btn>
                  </v-date-picker>
                </v-menu>

                <v-menu
                  ref="menuEnd"
                  :close-on-content-click="false"
                  v-model="range.menuEnd"
                  :nudge-right="40"
                  :return-value.sync="range.end"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="computedEnd"
                    label="Data Final"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker v-model="range.end" no-title scrollable locale="pt-br">
                    <v-spacer></v-spacer>
                    <v-btn flat color="grey" @click="range.menuEnd = false">Fechar</v-btn>
                    <v-btn color="primary" @click="$refs.menuEnd.save(range.end)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" flat @click="range.modal = false">Fechar</v-btn>
            <v-btn color="primary" @click="range.modal = false; getList()">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
export default {
  data() {
    return {
      range: {
        start: moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        end: moment()
          .endOf("month")
          .format("YYYY-MM-DD"),
        modal: false
      },
      items: [],
      itemsEntrada: [],
      columns: [
        { value: "user.name", text: "Usuário" },
        { value: "created_at", text: "Data" },
        { value: "partner", text: "Parceiro", sortable: false },
        { value: "", text: "Remédios", sortable: false }
      ],
      loading: false,
      menuFilter: { menu: false },
      filtro: {
        partner_id: null,
        product_id: null
      }
    };
  },
  computed: {
    computedStart() {
      return this.formatDate(this.range.start);
    },
    computedEnd() {
      return this.formatDate(this.range.end);
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getList();
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.range,
          modal: undefined,
          ...this.filtro,
          office: this.$route.params.office
        };
        this.$http
          .get(`/retiradas/reports_loan`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              //this.items = json.items;
              //this.items = json.retirada_items;
              this.items = json.retirada_items.map(o => {
                var counts = o.retiradas.reduce((p, c) => {
                  var name = c.product.name;
                  if (!p.hasOwnProperty(name)) {
                    p[name] = 0;
                  }
                  p[name]++;
                  return p;
                }, {});

                return {
                  ...o,
                  products: counts
                };
              });

              this.itemsEntrada = json.entrada_items;
              console.log(this.items);
            }, 1000);
          })
          .catch(() => (this.loading = false));

          /*
          this.$http
          .get(`/purchases/reports_loan`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.itemsEntrada = json.entrada_items;
            }, 1000);
          })
          .catch(() => (this.loading = false));*/
      });
    },
    filtrar() {
      this.menuFilter.menu = false;
      this.getList();
    },
    formatDate: date => (date ? moment(date).format("DD/MM/YYYY") : ""),
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400)
  }
};
</script>
