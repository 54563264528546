<template>
  <div>
    <v-container grid-list-md text-xs-center px-0>
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Operadores</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
              class="hidden-md-and-down"
              @click:append="getList()"
              v-on:keyup.enter="getList"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-menu
              v-model="filtro.menu"
              bottom
              left
              :close-on-content-click="false"
              max-width="400"
              min-width="400"
              offset-x
            >
              <v-btn outline slot="activator" color="primary">Filtros</v-btn>

              <v-card>
                <v-card-title class="px-1 py-1">
                  <v-spacer></v-spacer>
                  <v-btn
                    outline
                    light
                    small
                    color="primary"
                    @click="filtro.menu = true; filtro = {menu: true};"
                  >Limpar</v-btn>
                </v-card-title>

                <v-layout row wrap>
                  <v-flex xs12 class="px-1">
                    <v-select v-model="filtro.type" :items="types" label="Nível"></v-select>
                  </v-flex>
                  <v-flex xs12 class="px-1">
                    <select-custom
                      :url="'/departments/list?sortBy=name'"
                      v-model="filtro.department_id"
                      label="Departamento"
                    />
                  </v-flex>
                </v-layout>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn flat @click="filtro.menu = false">Fechar</v-btn>
                  <v-btn color="primary" flat @click="getList()">Filtrar</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
            <v-btn color="primary" dark class="mb-2" @click="openOperatorForm({})">Novo</v-btn>
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.email }}</td>
                <td>{{ getType(props.item.type) }}</td>
                <td>{{ props.item.department && props.item.department.name }}</td>
                <td>
                  <v-chip
                    style="font-size: 9px;"
                    small
                    v-for="(item, index) in props.item.modules"
                    :key="index"
                  >{{item.name}}</v-chip>
                </td>
                <td class="text-xs-center px-0">
                  <v-icon small @click="openOperatorForm(props.item)">edit</v-icon>
                  <v-icon small @click="del(props.item.id)">delete</v-icon>
                </td>
              </tr>
            </template>

            <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
          </div>
        </v-flex>
      </v-layout>

      <div v-if="operatorFormActive.open">
        <operator-form
          :active="operatorFormActive.open"
          :item="operatorFormActive.item"
          :finish="onCloseOperatorForm"
        ></operator-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
import { maskJs } from "mask-js";
export default {
  data() {
    return {
      valid: false,
      search: "",
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "id"
      },
      items: [],
      columns: [
        { value: "id", text: "ID" },
        { value: "name", text: "Nome" },
        { value: "email", text: "Email" },
        { value: "type", text: "Nivel" },
        { value: "department", text: "Depart.", sortable: false },
        { value: "modules", text: "Módulos", sortable: false },
        { value: "", text: "", sortable: false }
      ],
      operatorFormActive: {
        open: false,
        item: {}
      },
      operatorViewActive: { open: false },
      loading: false,
      dialogOperatorTo: false,
      types: [
        { text: "Administrador", value: 1 },
        { text: "Atendimento", value: 2 },
        { text: "Equipe Médica", value: 3 },
        { text: "Equipe Internação", value: 4 },
        { text: "Almoxarifado", value: 5 },
        { text: "Financeiro", value: 6 },
        { text: "Suporte", value: 7 },
        { text: "Compras", value: 8 },
        { text: "Farmácia Setor", value: 9 },
        { text: "Farmácia Responsável", value: 10 },
        { text: "Supervisor de atendimento", value: 11 },
        { text: "Equipe Radiologia", value: 12 },
        { text: "Equipe Same", value: 13 },
        { text: "Same Escrituração", value: 14 },
        { text: "Básico", value: 15 }
      ],
      filtro: {
        menu: false,
        type: null,
        department_id: null
      }
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          ...this.filtro,
          totalItems: undefined,
          search: this.search
        };
        this.$http
          .get(`/users/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;
              this.filtro.menu = false;
              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    del(id) {
      if (confirm("Deseja realmente excluir este usuário?")) {
        this.$http.put(`/users/del/${id}`).then(response => {
          this.$notify({
            title: `Usuário Excluido!`,
            type: "success"
          });
          this.getList();
        });
      }
    },
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    formatPhone(p) {
      if (!p) return null;
      return maskJs("(99) 9999?9-9999", p);
    },
    openOperatorForm(item) {
      this.operatorFormActive.open = true;
      this.operatorFormActive.item = item;
    },
    onCloseOperatorForm(operator) {
      this.operatorFormActive.open = false;
      this.getList();
    },
    getType(i) {
      const type = this.types.find( o => o.value == i);
      console.log("TYPE", type);
      if (type != undefined && type != "") return type.text;
    }
  }
};
</script>
