<template>
  <div>
    <v-container grid-list-md px-0>
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Internados</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
              class="hidden-sm-and-down no-print"
              @click:append="getList()"
              v-on:keyup.enter="getList"
            ></v-text-field>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.patient && props.item.patient.name}}</td>
                <td>{{ props.item.patient && props.item.patient.rh}}</td>
                <td>
                  <span v-if="props.item.oneself">O mesmo</span>
                  <span v-else-if="props.item.resp">{{ props.item.resp && props.item.resp.name}}</span>
                  <v-alert
                    class="px-1 py-1"
                    small
                    v-else
                    outline
                    :value="true"
                    type="error"
                  >PENDENTE</v-alert>
                </td>
                <td>{{ props.item.clinic && props.item.clinic.name }}</td>
                <td>{{ props.item.bed && props.item.bed.code }}</td>
                <td>{{ props.item.same ? 'SIM' : '' }}</td>
                <td class="text-xs-center">
                  <v-icon @click="openVisitationForm(props.item)">fas fa-bed</v-icon>
                </td>
              </tr>
            </template>

            <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
          </div>
        </v-flex>
      </v-layout>

      <div v-if="visitation.open">
        <visitation-form
          :active="visitation.open"
          :item="visitation.item"
          :finish="onCloseVisitationForm"
        />
      </div>
    </v-container>
  </div>
</template>
<script lang="js">
import dayjs from 'dayjs';
export default {
  data() {
    return {
      visitation: { open: false },
      loading: false,
      search: "",
      user: USER,
      pagination: {
        totalItems: 0,
        rowsPerPage: 50,
        descending: false,
        page: 1,
        sortBy: "id"
      },
      items: [],
      columns: [
        { value: "id", text: "ID", sortable: false },
        { value: "name", text: "Nome", sortable: false },
        { value: "rh", text: "RH", sortable: false },
        { value: "resp", text: "Responsável", sortable: false },
        { value: "clinic", text: "Clinica", sortable: false },
        { value: "bed", text: "Leito", sortable: false },
        { value: "same", text: "Pron. Solicitado", sortable: false },
        { text: "Opções", value: "", sortable: false }
      ],
      filtro: {
        done: false
      },
      menuReport: { menu: false },
    };
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true
    },
    "filtro.done":  {
      handler(n, o) {
        this.getList();
      },
      deep: true
    },
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      //this.getList();
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          ...this.filtro,
          search: this.search,
        };
        this.$http
          .get(`/hospitalizations/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    goExternal(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    openVisitationForm(item) {
      this.visitation.open = true;
      this.visitation.item = {
          patient_id: item.patient_id,
          hospitalization_id: item.id,
          clinic_id: item.clinic_id,
          bed_id: item.bed_id,
          patient: item.patient,
          clinic: item.clinic,
          bed: item.bed,
          resp: item.resp,
          resp_id: item.resp_id
      };
    },
    onCloseVisitationForm() {
      this.visitation.open = false;
      this.getList();
    },
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
  }
};
</script>
<style scoped>
table.v-table tbody td,
table.v-table tbody th {
  height: 30px;
}
table.v-table thead tr {
  height: 35px;
}

table td {
  font-size: 12px !important;
}
</style>

