<template>
  <v-container grid-list-md text-xs-center px-0>
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar flat color="white" class="elevation-1">
          <v-toolbar-title>Radiologia</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-text-field
            class="mt-2"
            type="date"
            v-model.lazy="birthday"
            label="Data Nascimento"
            clearable
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-text-field
            v-model.lazy="search"
            append-icon="fa-search"
            label="Buscar"
            single-line
            hide-details
            class="hidden-md-and-down"
            @click:append="getList()"
            v-on:keyup.enter="getList"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-menu
            v-model="menuFilter.menu"
            bottom
            left
            :close-on-content-click="false"
            max-width="400"
            min-width="400"
            offset-x
          >
            <v-btn outline slot="activator" color="primary">OPÇÕES</v-btn>

            <v-card>
              <v-card-title class="px-1 py-1">
                OPÇÕES
                <v-spacer></v-spacer>
                <v-btn
                  outline
                  light
                  small
                  color="primary"
                  @click="
                    menuFilter.menu = true;
                    filtro = {};
                  "
                  >Limpar</v-btn
                >
              </v-card-title>

              <v-layout row wrap>
                <v-flex xs12 class="px-1">
                  <v-text-field
                    v-model="filtro.start"
                    type="date"
                    label="De"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-text-field
                    v-model="filtro.end"
                    type="date"
                    label="Até"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-select
                    v-model="filtro.origins"
                    label="Origem"
                    :items="origins"
                    multiple
                  />
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-select
                    v-model="filtro.radiology_type"
                    :items="types"
                    label="Tipo"
                  ></v-select>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-select
                    v-model="filtro.tomography"
                    :items="['SIMPLES', 'COM CONTRASTE', 'COM SEDAÇÃO']"
                    label="TOMOGRAFIA"
                  ></v-select>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-select
                    :items="subtypes"
                    v-model="filtro.subtype"
                    label="Sub-Tipo"
                  ></v-select>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-select
                    v-model="filtro.used"
                    :items="[
                      { value: null, text: 'Selecionar' },
                      { value: 1, text: 'SIM' },
                      { value: 0, text: 'NÃO' }
                    ]"
                    label="Feito procedimento"
                  ></v-select>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-text-field
                    v-model="filtro.doctor_name"
                    label="Médico"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn flat @click="menuFilter.menu = false">Fechar</v-btn>
                <v-btn color="primary" flat @click="goReport()">IMPRIMIR</v-btn>
                <v-btn color="primary" @click="filtrar()">FILTRAR</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>

          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="openRadiologyForm({ office: $route.params.office })"
            >Novo</v-btn
          >
        </v-toolbar>

        <v-data-table
          :headers="columns"
          :items="items"
          :pagination.sync="pagination"
          :total-items="pagination.totalItems"
          :loading="loading"
          hide-actions
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr
              :class="{
                'green lighten-1': props.item.done && props.item.used,
                'red lighten-1': props.item.done && !props.item.used
              }"
            >
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.user.name }}</td>
              <td>{{ props.item.patient && props.item.patient.name }}</td>
              <td>{{ props.item.patient && props.item.patient.rh }}</td>
              <td>
                {{ props.item.origin }}
                {{
                  props.item.origin == "AMBULATORIO UNIDADES EXTERNAS" &&
                  props.item.ambulator
                    ? `(${props.item.ambulator})`
                    : ""
                }}
              </td>
              <td>
                {{ props.item.radiology_type }}
                {{
                  props.item.radiology_type == "TOMOGRAFIA" &&
                  props.item.tomography
                    ? `(${props.item.tomography})`
                    : ""
                }}
              </td>
              <td>
                <v-chip
                  style="font-size: 9px;"
                  small
                  v-for="(item, index) in props.item.subtypes"
                  :key="index"
                  >{{ item.name }}</v-chip
                >
              </td>
              <td>{{ props.item.horary | formatDateTimeShort }}</td>
              <td>{{ props.item.doctor_name }}</td>
              <td class="text-xs-center px-0">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon small>fas fa-ellipsis-v</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-tile
                      @click="
                        goExternal(`/exams/${props.item.id}/fa_radiology`)
                      "
                    >
                      <v-list-tile-avatar>
                        <v-icon>fa-print</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-title>Imprimir Ficha</v-list-tile-title>
                    </v-list-tile>

                    <v-list-tile
                      @click="
                        goExternal(`/exams/${props.item.id}/tag_radiology`)
                      "
                    >
                      <v-list-tile-avatar>
                        <v-icon>fas fa-tag</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-title>Imprimir Etiqueta</v-list-tile-title>
                    </v-list-tile>

                    <v-list-tile
                      @click="goExternal(`/exams/${props.item.id}/tag_patient`)"
                    >
                      <v-list-tile-avatar>
                        <v-icon>fas fa-tag</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-title
                        >Imprimir Etiqueta Paciente</v-list-tile-title
                      >
                    </v-list-tile>

                    <v-list-tile
                      v-if="!props.item.done"
                      @click="openRadiologyDone(props.item)"
                    >
                      <v-list-tile-avatar>
                        <v-icon>fas fa-check</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-title>Finalizar Exame</v-list-tile-title>
                    </v-list-tile>

                    <v-list-tile
                      v-if="
                        !props.item.done ||
                          user.type == 1 ||
                          user.modules.includes('RADIOLOGIA_REVERTER')
                      "
                      @click="openRadiologyForm(props.item)"
                    >
                      <v-list-tile-avatar>
                        <v-icon>fa-edit</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-title>Editar</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile
                      v-if="
                        props.item.done &&
                          (user.type == 1 ||
                            user.modules.includes('RADIOLOGIA_REVERTER'))
                      "
                      @click="revert(props.item)"
                    >
                      <v-list-tile-avatar>
                        <v-icon>undo</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-title>Reverter</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>

          <template slot="no-data"
            >Desculpe, nenhum dado para ser exibido!</template
          >
        </v-data-table>
        <div class="mt-2 text-xs-left">TOTAL {{ totalExam }}</div>
        <div class="text-xs-center pt-2">
          <v-pagination
            v-model="pagination.page"
            :length="pages"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>

    <div v-if="radiologyFormActive.open">
      <radiology-form
        :active="radiologyFormActive.open"
        :item="radiologyFormActive.item"
        :finish="onCloseRadiologyForm"
      />
    </div>

    <div v-if="radiologyDone.open">
      <radiology-done
        :active="radiologyDone.open"
        :item="radiologyDone.item"
        :finish="onCloseRadiologyDone"
      ></radiology-done>
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import moment from "moment";
export default {
  data() {
    return {
      valid: false,
      search: "",
      birthday: null,
      user: USER,
      pagination: {
        totalItems: 0,
        rowsPerPage: 10,
        descending: false,
        page: 1,
        sortBy: "id"
      },
      items: [],
      totalExam: 0,
      columns: [
        { value: "id", text: "ID" },
        { value: "user", text: "Usuário", sortable: false },
        { value: "patient", text: "Paciente", sortable: false },
        { value: "patient.rh", text: "RH", sortable: false },
        { value: "origin", text: "Origem" },
        { value: "radiology_type", text: "Tipo" },
        { value: "subtype", text: "Sub-Tipos" },
        { value: "horary", text: "Data e Hora" },
        { value: "doctor_name", text: "Médico" },
        { text: "", value: "", sortable: false }
      ],
      radiologyFormActive: {
        open: false,
        item: {}
      },
      loading: false,
      radiologyDone: { open: false },
      menuFilter: { menu: false },
      origins: [
        "AMBULATORIO",
        "AMBULATORIO UNIDADES EXTERNAS",
        "CLINICA MÉDICA",
        "CLINICA CIRURGICA",
        "UTI-ADULTO",
        "NEONATAL",
        "CENTRO CIRURGICO",
        "MATERNIDADE",
        "PSIQUIATRIA",
        "PEDIATRIA",
        "CENTRO OBSTÉTRICO",
        "EMERGENCIA",
        "P.S. GERAL",
        "PS SUTURA"
      ],
      types: [
        "RAIO-X",
        "MAMOGRAFIA",
        "ULTRASSOM",
        "TOMOGRAFIA",
        "ECOCARDIOGRAMA",
        "DOPPLER"
      ],
      subtypes: [],
      filtro: {
        origins: null,
        radiology_type: null,
        start: moment().format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
        used: null,
        subtype: null,
        tomography: null,
        doctor_name: null
      }
    };
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true
    },
    birthday: function() {
      this.debounceInput();
    },
    "filtro.radiology_type": function() {
      this.getSubtypes();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      //this.getSubtypes();
    },
    /*getSubtypes() {
      this.$http
        .get(`/exams/subtypes`)
        .then((response) => response.data)
        .then((json) => {
          this.subtypes = json.items;
        });
    },*/
    getSubtypes() {
      //console.log("getSubtypes", this.filtro.radiology_type);
      this.subtypes = [];
      switch (this.filtro.radiology_type) {
        case "RAIO-X":
          this.subtypes = [
            "ABDOMEN AGUDO",
            "ABDÔMEN SIMPLES",
            "ABDÔMEN NO LEITO",
            "ANTEBRACO",
            "ARCOS COSTAIS (COSTELAS POR HEMITORAX)",
            "BACIA",
            "BRACO/UMERO",
            "CLAVÍCULA (ACROMIO)",
            "CALCANEO",
            "COLUNA CERVICAL",
            "COLUNA DORSAL",
            "COLUNA LOMBO-SACRA",
            "COLUNA LOMBAR",
            "COLUNA SACRAL (COCCIX)",
            "COLUNA TORACICA",
            "COTOVELO",
            "COXA/FEMUR",
            "CRANIO",
            "ESCAFÓIDE",
            "ESCAPULO-UMERAL",
            "FACE",
            "HÁLUX",
            "HIRTS",
            "JOELHO",
            "MAO",
            "MANDÍBULA",
            "NARIZ",
            "OMBRO/OMOPLATA",
            "OSSOS DA FACE",
            "OSSOS LONGOS",
            "PATELA",
            "PELVE/BACIA",
            "PERNA/TIBIA",
            "PÉ",
            "PODODACTILOS/DEDOS DO PÉ",
            "POLEGAR",
            "PESCOÇO",
            "PUNHO",
            "QUADRIL",
            "QUIRODACTILOS/ DEDOS DA MÃO",
            "SEIO DA FACE",
            "SELA TURCICA",
            "SESAMÓIDES",
            "TORACO ABDOMINAL",
            "TÓRAX PA",
            "TÓRAX PA E PERFIL",
            "TÓRAX AP NO LEITO",
            "TORNOZELO",
            "WATERS"
          ];
          break;

        case "MAMOGRAFIA":
          this.subtypes = ["Rastreamento"];
          break;

        case "ULTRASSOM":
          this.subtypes = [
            "ABDOMEN SUPERIOR",
            "ANTEBRAÇO",
            "BOLSA ESCROTAL",
            "BOLSA ESCROTAL C/ DOPPLER",
            "BRAÇO",
            "CERVICAL(PESCOÇO)",
            "COTOVELO",
            "COXA",
            "JOELHO",
            "MAMAS BILATERAL",
            "MÃO",
            "MORFOLÓGICO",
            "OBSTÉTRICO",
            "OBSTÉTRICO C/ DOPPLER",
            "OMBRO",
            "PANTURILHA",
            "PAREDE ABDOMINAL",
            "PARÓTIDAS",
            "PARTES MOLES",
            "PÉ",
            "PELVE",
            "PELVICA VIA ABDOMINAL",
            "PROSTATA",
            "PUNHO",
            "QUADRIL",
            "REGIÃO FACIAL",
            "REGIÃO INGUINAL",
            "RINS E VIAS URINÁRIAS",
            "TIRÓIDE",
            "TÓRAX",
            "TORNOZELO",
            "TRANSFONTANELA",
            "TRANSVAGINAL",
            "TRANSVAGINAL COM DOPPLER"
          ];
          break;

        case "TOMOGRAFIA":
          this.subtypes = [
            "ABDOMEN SUPERIOR",
            "ANGIO AORTA ABD",
            "ANGIO CEREBRAL",
            "ANGIO MMII",
            "ANGIO TORACICA",
            "ANTEBRACO",
            "ARTIC. COXO-FEMURAIS",
            "ARTIC. EXTERNO-CLAVICULAR",
            "ARTIC. JOELHOS",
            "ARTIC. PUNHO",
            "ARTIC. SACRO-ILIACA",
            "ARTIC. TEMP. MAND.",
            "BRACO",
            "CALCANEO",
            "COLUNA CERVICAL",
            "COLUNA DORSAL",
            "COLUNA LOMBAR",
            "COLUNA SACRAL (COCCIX)",
            "COLUNA LOMBO-SACRA ",
            "COTOVELOS",
            "COXA",
            "CRANIO",
            "ESCANOMETRIA",
            "FACE",
            "MAO",
            "MASTOIDES",
            "OMBROS",
            "ÓRBITAS",
            "OUVIDOS/OSSOS TEMPORAIS",
            "PÉ",
            "PELVE/BACIA",
            "PERNA",
            "PESCOÇO",
            "QUADRIL",
            "SEIOS DE FACE",
            "SELA TURCICA",
            "TÓRAX",
            "TORNOZELO"
          ];
          break;

        case "ECOCARDIOGRAMA":
          this.subtypes = ["ADULTO", "INFANTIL"];
          break;
        case "DOPPLER":
          this.subtypes = [
            "DOPPLER CAROTIDAS",
            "DOPPLER VERTEBRAIS",
            "DOPPLER MID (ARTERIAL)",
            "DOPPLER MID (VENOSO)",
            "DOPPLER MIE (ARTERIAL)",
            "DOPPLER MIE (VENOSO)",
            // "DOPPLER MMII (VENOSO)",
            // "DOPPLER MMII (ARTERIAL)",
            "DOPPLER MSD (ARTERIAL)",
            "DOPPLER MSD (VENOSO)",
            "DOPPLER MSE (ARTERIAL)",
            "DOPPLER MSE (VENOSO)"
            // "DOPPLER MMSS (ARTERIAL)",
            // "DOPPLER MMSS (VENOSO)"
          ];
          break;

        default:
          break;
      }
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          ...this.filtro,
          totalItems: undefined,
          search: this.search,
          radiology: 1,
          birthday: this.birthday
        };
        this.$http
          .get(`/exams/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
              this.totalExam = json.totalExam;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    del(id) {
      if (confirm("Deseja realmente excluir esta Radiologia?")) {
        this.$http.delete(`/exams/${id}`).then(response => {
          this.$notify({
            title: `Radiologia Excluido!`,
            type: "success"
          });
          this.getList();
        });
      }
    },
    revert(item) {
      if (confirm("Deseja realmente reverter este Exame?")) {
        item.done = false;
        item.done_horary = null;
        this.$http
          .put(`/exams/${item.id}`, item)
          .then(response => {
            this.$notify({
              title: `Exame salvo com sucesso!`,
              type: "success"
            });
            this.getList();
          })
          .catch(err => {
            this.$reqError(`Erro ao salvar!`, err, this);
          });
      }
    },
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),

    openRadiologyForm(item) {
      this.radiologyFormActive.open = true;
      this.radiologyFormActive.item = item;
    },
    onCloseRadiologyForm(category) {
      this.radiologyFormActive.open = false;
      this.getList();
    },
    openRadiologyDone(item) {
      this.radiologyDone.open = true;
      this.radiologyDone.item = item;
    },
    onCloseRadiologyDone() {
      this.radiologyDone.open = false;
      this.getList();
    },
    goExternal(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    filtrar() {
      this.menuFilter.menu = false;
      this.getList();
    },
    goReport() {
      if (!this.filtro.start || !this.filtro.end) {
        this.$notify({
          title: `Escolha as datas!`,
          type: "error"
        });
        return;
      }
      this.menuFilter.menu = false;
      let origin = "";
      let radiology_type = "";
      let used = "";
      let subtype = "";
      let tomography = "";
      let doctor_name = "";
      if (this.filtro.used != null) used = `&used=${this.filtro.used}`;
      if (this.filtro.origins) {
        this.filtro.origins.forEach(o => (origin += `&origins[]=${o}`));
      }
      if (this.filtro.radiology_type)
        radiology_type = `&radiology_type=${this.filtro.radiology_type}`;
      if (this.filtro.subtype) subtype = `&subtype=${this.filtro.subtype}`;
      if (this.filtro.tomography)
        tomography = `&tomography=${this.filtro.tomography}`;
      if (this.filtro.doctor_name)
        doctor_name = `&doctor_name=${this.filtro.doctor_name}`;

      let url = `/exams/reports_radiology/?start=${this.filtro.start}&end=${this.filtro.end}
        ${origin}${radiology_type}${used}${subtype}${tomography}${doctor_name}`;
      window.open(url, "_blank");
    }
  }
};
</script>
