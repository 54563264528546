<template>
  <div>
    <v-container grid-list-md px-0>
      <v-layout row wrap class="no-print">
        <v-flex xs12 sm4>
          <v-menu
            v-if="!user.modules.includes('MEDICO_INTERNACAO')"
            v-model="menuReport.menu"
            bottom
            right
            :close-on-content-click="false"
            max-width="400"
            min-width="400"
            offset-x
          >
            <v-btn outline slot="activator" color="primary">Relatórios</v-btn>

            <v-card>
              <v-card-title class="px-1 py-1">RELATÓRIO</v-card-title>

              <v-layout row wrap>
                <v-flex xs12 class="px-1">
                  <v-text-field v-model="menuReport.start" type="date" label="De"></v-text-field>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-text-field v-model="menuReport.end" type="date" label="Até"></v-text-field>
                </v-flex>
                <v-flex xs12 class="px-1">
                  <v-select
                    v-model="menuReport.type"
                    :items="['TODOS','ALTAS','OBITOS']"
                    label="Tipo"
                  ></v-select>
                </v-flex>
              </v-layout>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn class="mr-1" flat @click="menuReport.menu = false">Fechar</v-btn>
                <v-btn class="mr-1" color="blue" flat @click="goReport('plan')">PLANILHA</v-btn>
                <v-btn color="primary" @click="goReport('reports')">IMPRIMIR</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <v-toolbar flat color="white" class="elevation-1">
            <v-toolbar-title>Internados</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-switch
              v-if="!user.modules.includes('MEDICO_INTERNACAO')"
              class="no-print"
              v-model="filtro.done"
              :label="`${filtro.done ? 'De Alta' : 'Internados'}`"
            ></v-switch>
            <v-spacer></v-spacer>
            <v-text-field
              v-model.lazy="search"
              append-icon="fa-search"
              label="Buscar"
              single-line
              hide-details
              class="hidden-sm-and-down no-print"
              @click:append="getList()"
              v-on:keyup.enter="getList"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!user.modules.includes('MEDICO_INTERNACAO')"
              color="primary"
              dark
              class="mb-2 no-print"
              @click="openHospitalization({})"
            >Novo</v-btn>
          </v-toolbar>

          <v-data-table
            :headers="columns"
            :items="items"
            :pagination.sync="pagination"
            :total-items="pagination.totalItems"
            :loading="loading"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.patient && props.item.patient.name}}</td>
                <td>{{ props.item.patient && props.item.patient.rh}}</td>
                <td>
                  <span v-if="props.item.oneself">O mesmo</span>
                  <span v-else-if="props.item.resp">{{ props.item.resp && props.item.resp.name}}</span>
                  <v-alert
                    class="px-1 py-1"
                    small
                    v-else
                    outline
                    :value="true"
                    type="error"
                  >PENDENTE</v-alert>
                </td>
                <td>{{ props.item.clinic && props.item.clinic.name }}</td>
                <td>{{ props.item.bed && props.item.bed.code }}</td>
                <td>{{ props.item.same ? 'SIM' : '' }}</td>
                <td class="text-xs-center">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon small>fas fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-tile
                        v-if="!user.modules.includes('MEDICO_INTERNACAO')"
                        @click="goExternal(`/hospitalizations/${props.item.id}/combined`)"
                      >
                        <v-list-tile-avatar>
                          <v-icon>fa-print</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>Imprimir Tudo</v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile
                        v-if="!user.modules.includes('MEDICO_INTERNACAO')"
                        @click="goExternal(`/hospitalizations/${props.item.id}/terms`)"
                      >
                        <v-list-tile-avatar>
                          <v-icon>fas fa-file-signature</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>Imprimir termo de responsabilidade</v-list-tile-title>
                      </v-list-tile>

                      <v-list-tile
                        v-if="!user.modules.includes('MEDICO_INTERNACAO')"
                        @click="goExternal(`/hospitalizations/${props.item.id}/control`)"
                      >
                        <v-list-tile-avatar>
                          <v-icon>fas fa-signature</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>Imprimir controle acompanhante</v-list-tile-title>
                      </v-list-tile>

                      <v-list-tile
                        v-if="!user.modules.includes('MEDICO_INTERNACAO')"
                        @click="goExternal(`/hospitalizations/${props.item.id}/fa`)"
                      >
                        <v-list-tile-avatar>
                          <v-icon>fa-print</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>Imprimir Ficha</v-list-tile-title>
                      </v-list-tile>

                      <v-list-tile
                        v-if="!props.item.medical_release || user.type == 1"
                        @click="openHospitalizationDetails(props.item)"
                      >
                        <v-list-tile-avatar>
                          <v-icon>fas fa-notes-medical</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>Prescrição, Hipótese, Evolução</v-list-tile-title>
                      </v-list-tile>

                      <v-list-tile
                        v-if="!props.item.done && props.item.medical_release && !user.modules.includes('MEDICO_INTERNACAO')"
                        @click="openHospitalizationDone(props.item)"
                      >
                        <v-list-tile-avatar>
                          <v-icon>fas fa-check</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>Finalizar Internação</v-list-tile-title>
                      </v-list-tile>

                      <v-list-tile
                        v-else
                        @click="goExternal(`/hospitalizations/${props.item.id}/print`)"
                      >
                        <v-list-tile-avatar>
                          <v-icon>fas fa-eye</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>Resumo</v-list-tile-title>
                      </v-list-tile>

                      <v-list-tile
                        v-if="!props.item.same && !props.item.done && !user.modules.includes('MEDICO_INTERNACAO')"
                        @click="openSame(props.item)"
                      >
                        <v-list-tile-avatar>
                          <v-icon>fas fa-folder-open</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>Solicitar prontuário</v-list-tile-title>
                      </v-list-tile>

                      <v-list-tile
                        v-if="!props.item.done && !user.modules.includes('MEDICO_INTERNACAO')"
                        @click="openVisitationForm(props.item)"
                      >
                        <v-list-tile-avatar>
                          <v-icon>fas fa-bed</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>Nova Visita</v-list-tile-title>
                      </v-list-tile>

                      <v-list-tile
                        v-if="(!props.item.done || user.type == 1) && !user.modules.includes('MEDICO_INTERNACAO')"
                        @click="openHospitalization(props.item)"
                      >
                        <v-list-tile-avatar>
                          <v-icon>fa-edit</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-title>Editar</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>

            <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
          </v-data-table>
          <div class="text-xs-center pt-2">
            <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
          </div>
        </v-flex>
      </v-layout>

      <div v-if="declarePrint.open">
        <declare-print
          :active="declarePrint.open"
          :item="declarePrint.item"
          :finish="onCloseDeclarePrint"
        ></declare-print>
      </div>

      <div v-if="hospitalization.open">
        <hospitalization
          :active="hospitalization.open"
          :item="hospitalization.item"
          :finish="onCloseHospitalization"
        ></hospitalization>
      </div>

      <div v-if="hospitalizationDetails.open">
        <hospitalization-details
          :active="hospitalizationDetails.open"
          :item_id="hospitalizationDetails.item.id"
          :finish="onCloseHospitalizationDetails"
        ></hospitalization-details>
      </div>

      <div v-if="hospitalizationDone.open">
        <hospitalization-done
          :active="hospitalizationDone.open"
          :item="hospitalizationDone.item"
          :finish="onCloseHospitalizationDone"
        ></hospitalization-done>
      </div>

      <div v-if="visitation.open">
        <visitation-form
          :active="visitation.open"
          :item="visitation.item"
          :finish="onCloseVisitationForm"
        />
      </div>
    </v-container>
  </div>
</template>
<script lang="js">
import dayjs from 'dayjs';
export default {
  data() {
    return {
      declarePrint: { open: false },
      hospitalization: { open: false },
      hospitalizationDetails: { open: false },
      hospitalizationDone: { open: false },
      visitation: { open: false },
      loading: false,
      search: "",
      user: USER,
      pagination: {
        totalItems: 0,
        rowsPerPage: 50,
        descending: false,
        page: 1,
        sortBy: "id"
      },
      items: [],
      columns: [
        { value: "id", text: "ID", sortable: false },
        { value: "name", text: "Nome", sortable: false },
        { value: "rh", text: "RH", sortable: false },
        { value: "resp", text: "Responsável", sortable: false },
        { value: "clinic", text: "Clinica", sortable: false },
        { value: "bed", text: "Leito", sortable: false },
        { value: "same", text: "Pron. Solicitado", sortable: false },
        { text: "Opções", value: "", sortable: false }
      ],
      filtro: {
        done: false
      },
      menuReport: { menu: false },
    };
  },
  watch: {
    "pagination.page": {
      handler() {
        this.getList();
      },
      deep: true
    },
    "filtro.done":  {
      handler(n, o) {
        this.getList();
      },
      deep: true
    },
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      //this.getList();
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          ...this.pagination,
          totalItems: undefined,
          ...this.filtro,
          search: this.search,
        };
        this.$http
          .get(`/hospitalizations/list`, { params })
          .then(response => response.data)
          .then(json => {
            setTimeout(() => {
              this.loading = false;

              this.items = json.items;
              this.pagination.totalItems = json.total;
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    openDeclarePrint(item) {
      window.scrollTo(0, 0);
      this.declarePrint.open = true;
      this.declarePrint.item = item;
    },
    onCloseDeclarePrint() {
      this.declarePrint.open = false;
    },
    openHospitalization(item) {
      this.hospitalization.open = true;
      this.hospitalization.item = item;
    },
    onCloseHospitalization() {
      this.hospitalization.open = false;
      this.getList();
    },
    openHospitalizationDetails(item) {
      this.hospitalizationDetails.open = true;
      this.hospitalizationDetails.item = item;
    },
    onCloseHospitalizationDetails() {
      this.hospitalizationDetails.open = false;
      this.getList();
    },
    openHospitalizationDone(item) {
      this.hospitalizationDone.open = true;
      this.hospitalizationDone.item = item;
    },
    onCloseHospitalizationDone() {
      this.hospitalizationDone.open = false;
      this.getList();
    },
    goExternal(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    openVisitationForm(item) {
      this.visitation.open = true;
      this.visitation.item = {
          patient_id: item.patient_id,
          hospitalization_id: item.id,
          clinic_id: item.clinic_id,
          bed_id: item.bed_id,
          patient: item.patient,
          clinic: item.clinic,
          bed: item.bed,
          resp: item.resp,
          resp_id: item.resp_id
      };
    },
    onCloseVisitationForm() {
      this.visitation.open = false;
      this.getList();
    },
    openSame(item){
      if (confirm("Deseja realmente solicitar prontuário?")) {
        const params = {
          patient_id: item.patient_id,
          destiny: 'INTERNAÇÃO',
          active: 1,
          hospitalization_id: item.id,
          specialty_id: item.specialty_id,
          doctor_id: item.doctor_id
        };

        this.$http.post(`/same/store`, params).then(response => {
          this.$notify({
            title: `Prontuário solicitado com sucesso!`,
            type: "success"
          });
          this.getList();
        })
        .catch(err => {
          this.$reqError(`Erro ao salvar!`, err, this);
        });
      }
    },
    goReport(action) {
      if(!this.menuReport.start || !this.menuReport.end || !this.menuReport.type) return;
      this.menuReport.menu = false;
      let url = `/hospitalizations/${action}/?start=${this.menuReport.start}&end=${this.menuReport.end}&type=${this.menuReport.type}`;
      window.open(url, "_blank");
    },
    debounceInput: _.debounce(function() {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
  }
};
</script>
<style scoped>
table.v-table tbody td,
table.v-table tbody th {
  height: 30px;
}
table.v-table thead tr {
  height: 35px;
}

table td {
  font-size: 12px !important;
}
</style>

