<template>
  <v-container grid-list-md text-xs-center px-0>
    <notifications position="bottom center" />
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar flat color="white" class="elevation-1 mb-4">
          <v-toolbar-title>Relatório de Saídas</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model.lazy="search"
            append-icon="fa-search"
            label="Buscar"
            single-line
            hide-details
            class="hidden-md-and-down"
            @click:append="getList()"
              v-on:keyup.enter="getList"
          ></v-text-field>
          <v-spacer></v-spacer>
          <div>
            {{formatDate(range.start)}} - {{formatDate(range.end)}}
            <v-icon class="mr-2" @click="range.modal = true">edit</v-icon>
          </div>
        </v-toolbar>
      </v-flex>

      <v-flex xs12 v-for="(tb, i) in items" :key="i">
        <v-toolbar flat color="white" class="elevation-1">
          <v-toolbar-title>{{formatDate(tb[0].created_at)}}</v-toolbar-title>
        </v-toolbar>

        <v-data-table
          :headers="columns"
          :items="tb"
          :loading="loading"
          hide-actions
          disable-initial-sort
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{ props.item.user && props.item.user.name}}</td>
              <td>{{ props.item.setore && props.item.setore.name}}</td>
              <td>{{ props.item.type | TypeRetirada()}}</td>
              <td>
                <span
                  v-for="(item, i) in props.item.products"
                  :key="i"
                >{{item.product.name}} ({{item.qty}});</span>
              </td>
              <td>{{ props.item.obs }}</td>
            </tr>
          </template>

          <template slot="no-data">Desculpe, nenhum dado para ser exibido!</template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-dialog v-model="range.modal" scrollable max-width="350px">
      <v-card>
        <v-card-title>
          <span class="headline">Período</span>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12>
              <v-menu
                ref="menuStart"
                :close-on-content-click="false"
                v-model="range.menuStart"
                :nudge-right="40"
                :return-value.sync="range.start"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="computedStart"
                  label="Data inicial"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker v-model="range.start" no-title scrollable locale="pt-br">
                  <v-spacer></v-spacer>
                  <v-btn flat color="grey" @click="range.menuStart = false">Fechar</v-btn>
                  <v-btn color="primary" @click="$refs.menuStart.save(range.start)">OK</v-btn>
                </v-date-picker>
              </v-menu>

              <v-menu
                ref="menuEnd"
                :close-on-content-click="false"
                v-model="range.menuEnd"
                :nudge-right="40"
                :return-value.sync="range.end"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="computedEnd"
                  label="Data Final"
                  prepend-icon="event"
                  readonly
                ></v-text-field>
                <v-date-picker v-model="range.end" no-title scrollable locale="pt-br">
                  <v-spacer></v-spacer>
                  <v-btn flat color="grey" @click="range.menuEnd = false">Fechar</v-btn>
                  <v-btn color="primary" @click="$refs.menuEnd.save(range.end)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" flat @click="range.modal = false">Fechar</v-btn>
          <v-btn color="primary" @click="range.modal = false; getList()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import _ from "lodash";
export default {
  data() {
    return {
      range: {
        start: moment().startOf("month").format("YYYY-MM-DD"),
        end: moment().endOf("month").format("YYYY-MM-DD"),
        modal: false,
      },
      search: "",
      items: [],
      columns: [
        { value: "user.name", text: "Usuário" },
        { value: "setore.name", text: "Setor" },
        { value: "type", text: "Tipo" },
        { value: "", text: "Remédios", sortable: false },
        { value: "obs", text: "Obs" },
      ],
      loading: false,
    };
  },
  computed: {
    computedStart() {
      return this.formatDate(this.range.start);
    },
    computedEnd() {
      return this.formatDate(this.range.end);
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getList();
    },
    getList() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const params = {
          search: this.search,
          ...this.range,
          modal: undefined,
        };
        this.$http
          .get(`/retiradas/reports/${this.$route.params.office}`, { params })
          .then((response) => response.data)
          .then((json) => {
            setTimeout(() => {
              this.loading = false;

              //this.items = json.items;
              this.items = _.toArray(
                _.groupBy(json.items, (obj) => this.formatDate(obj.created_at))
              );
              console.log(this.items);
            }, 1000);
          })
          .catch(() => (this.loading = false));
      });
    },
    formatDate: (date) => (date ? moment(date).format("DD/MM/YYYY") : ""),
    debounceInput: _.debounce(function () {
      setTimeout(() => {
        this.getList();
      }, 200);
    }, 400),
  },
  filters: {
    TypeRetirada: function (value) {
      if (value === 1) {
        return "Atendimento";
      } else if (value === 2) {
        return "Internação";
      } else if (value === 4) {
        return "Emprestimo";
      } else {
        return "Outros";
      }
    },
  },
};
</script>
